import React from 'react'
import { TrashIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

import { TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'

export const PersonaGroup = (props: {
  persona: string
  expandedPersona: Set<string> | null
  isPersonaGroupSelectAllIndeterminate: (persona: string, activations: Array<TargetedActivationListType>) => boolean
  activations: TargetedActivationListType[]
  selectedActivations: Map<string, Set<number>>
  handleSelectAllPersonaGroup: (
    checked: boolean,
    persona: string,
    activations: Array<TargetedActivationListType>
  ) => void
  togglePersona: (persona: string) => void
  setPersonaToBeDeleted: React.Dispatch<React.SetStateAction<string | null>>
}) => {
  const {
    activations,
    expandedPersona,
    handleSelectAllPersonaGroup,
    togglePersona,
    isPersonaGroupSelectAllIndeterminate,
    persona,
    selectedActivations,
    setPersonaToBeDeleted
  } = props
  return (
    <tr
      key={persona}
      data-testid="act-mgr-persona-group-row"
      id="act-mgr-persona-group-row"
      className="border-b border-gray-200 cursor-pointer hover:bg-gray-50"
    >
      <td
        headers="activations-checkbox"
        className="relative w-12 "
      >
        <div className="flex gap-1 pl-3">
          <div onClick={() => togglePersona(persona)}>
            {expandedPersona?.has(persona) ? (
              <ChevronDownIcon
                height={20}
                width={20}
              />
            ) : (
              <ChevronRightIcon
                height={20}
                width={20}
              />
            )}
          </div>
          <SightlyCheckbox
            id="activationSelector"
            dataTestId="act-mgr-persona-group-checkbox"
            indeterminate={isPersonaGroupSelectAllIndeterminate(persona, activations)}
            checked={activations.every(({ activationItemId }) =>
              selectedActivations.get(persona)?.has(activationItemId)
            )}
            handleChange={(checked: boolean) => {
              handleSelectAllPersonaGroup(checked, persona, activations)
            }}
          />
        </div>
      </td>
      <td
        colSpan={5}
        className="w-auto"
        onClick={() => togglePersona(persona)}
      >
        <div
          className="flex justify-between pl-3"
          data-testid="act-mgr-persona-group"
          id="act-mgr-persona-group"
        >
          <p className="px-2 py-1 m-1 text-xs font-semibold bg-blue-100 rounded-lg">{persona}</p>
          <div className="flex justify-end p-1">
            <TrashIcon
              data-testid="act-mgr-action-delete-persona-btn"
              id="act-mgr-action-delete-persona-btn"
              onClick={() => {
                setPersonaToBeDeleted(() => persona)
              }}
              className="w-6 h-6 rounded hover:cursor-pointer hover:bg-slate-100"
            />
          </div>
        </div>
      </td>
    </tr>
  )
}
