import {useMutation, useQuery} from '@tanstack/react-query';
import { NewBoardNlpPromptFormType, SocialConversationRequest } from '@/views/Discover/Boards/types';
import { api } from '@/views/Discover/Moments/v2/api';
import dayjs from 'dayjs';

async function fetchSocialConversationPreviews(data:NewBoardNlpPromptFormType) {
  const { prompt, ideasToAvoid, ideasToLeanInto } = data;
  const currentDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
  const sevenDays = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
  const thirtyDays = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
  const sixtyDays = dayjs().subtract(60, 'day').format('YYYY-MM-DD');
  const queryData:SocialConversationRequest = {
    boardIdea: [prompt],
    maxPublishedDate: currentDate,
    minPublishedDate: sevenDays,
    maxPublishedDateMediumRange: currentDate,
    minPublishedDateMediumRange: thirtyDays,
    maxPublishedDateLongRange: currentDate,
    minPublishedDateLongRange: sixtyDays,
    resultCount: 10000
  };
  if (ideasToAvoid) queryData.moveAwayFrom = [ideasToAvoid];
  if (ideasToLeanInto) queryData.moveTo = [ideasToLeanInto];
  return api.moments.getSocialConversationPreviews(queryData);
}

export default function useSocialConversationPreviews(data:NewBoardNlpPromptFormType) {
  const mutateObj = useMutation(fetchSocialConversationPreviews, {})

  return {
    ...mutateObj
  }
}
