import React from 'react'
import { useSearch } from '@tanstack/react-location'
import { Loader } from 'rsuite'
import { BoltIcon } from '@heroicons/react/24/outline'

import { MyLocationGenerics } from '@/classes/utils'
import SightlyAvatar from '@/components/SightlyAvatar'
import { SmallBadge } from '@/components/SmallClosableBadge'
import { ReactComponent as KeywordsIcon } from '@/assets/img/keywordsKey.svg'
import { ReactComponent as BlackYoutube } from '@/assets/img/blackYoutube.svg'
import { useInProcessActivations } from './hooks/activations-hooks'
import { InfoPage } from '@/components/InfoPage'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle'

export const InProcess = () => {
  const { brandProfileId } = useSearch<MyLocationGenerics>()
  const activationsQuery = useInProcessActivations({
    brandProfileId: Number(brandProfileId)
  })

  if (activationsQuery.isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2">
        <Loader
          content="Fetching Activations..."
          className="z-10"
          speed="slow"
        />
      </div>
    )
  }
  if (activationsQuery?.data && activationsQuery?.data.length === 0 && activationsQuery.isFetched) {
    return (
        <div className="w-full h-80">
        <InfoPage
          message={
            <div>
              <span>No items found.</span>
            </div>
          }
        />
      </div>
    )
  }

  return (
    <div className="flex flex-col mt-8 fade-in animate-in" data-testid='activations-in-process' id='pendo-activations-in-process'>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
          <div className="relative my-3 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className=" py-3.5 pr-3 text-left text-sm font-semibold text-gray-600"
                  ></th>
                  <th
                    scope="col"
                    className="min-w-[12rem] py-3.5 pr-3 pl-4 text-left text-sm font-semibold text-gray-600"
                  >
                    MOMENT
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                  >
                    PERSONAS
                  </th>
                  
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                  >
                    ASSIGNEES
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                  >
                    SUBMITTED AT
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {activationsQuery?.data?.map((activationItem) => (
                  <tr
                    key={activationItem.activationItemId}
                    className={'bg-gray-50 fade-in animate-in'}
                  >
                    <td className={'whitespace-nowrap py-4 pr-3 pl-4 text-sm font-medium max-w-min'}>
                      <BoltIcon className="h-4 text-sightly-blue" />
                    </td>
                    <td className={'whitespace-nowrap py-4 pr-3 pl-4 text-sm font-medium truncate max-w-lg'}>
                      <div className="truncate">{activationItem.clusterName}</div>
                      <div className="mt-2 statsContainer">
                        <div className='flex items-center'>
                          <KeywordsIcon />
                          <div className="pl-1 text-xs text-gray-500">
                            {activationItem.keywordCount === 1
                              ? '1 keyword'
                              : activationItem.keywordCount + ' keywords'}
                          </div>
                        </div>

                        <div className='flex items-center'>
                          <BlackYoutube />
                          <div className="pl-1 text-xs text-gray-500">
                            {activationItem.videoCount === 1 ? '1 video' : activationItem.videoCount + ' videos'}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="flex items-center gap-2">
                        {activationItem.personas.slice(0, 2).map((persona, index) => {
                          return (
                            <SmallBadge
                              key={`${activationItem.activationItemId}_${persona}_${index}`}
                              text={persona}
                              onClose={() => undefined}
                              disabled={true}
                            />
                          )
                        })}
                        {activationItem.personas.length > 2 && (
                          <div className="text-xs">+ {activationItem.personas.length - 2} others</div>
                        )}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="flex items-center flex-shrink-0 gap-2 mt-4 sm:mt-0">
                        <div className="flex -space-x-1 ">
                          {activationItem.assignees.slice(0, 2).map((assignee) => {
                            return (
                              <div
                                key={assignee}
                                className="inline-block rounded-full ring-2 ring-white"
                              >
                                <SightlyAvatar
                                  text={assignee}
                                  size="xs"
                                  type={null}
                                />
                              </div>
                            )
                          })}
                        </div>
                        {activationItem.assignees.length > 2 && (
                          <div className="text-xs">+ {activationItem.assignees.length - 2} others</div>
                        )}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {activationItem?.submittedDate || ' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <style>
        {`
                  .statsContainer {
                      display: grid;
                      grid-template-columns: 130px 130px 130px;
                      grid-auto-flow: row;	
                  }
                `}
      </style>
    </div>
  )
}
