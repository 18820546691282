import React from 'react'
import _ from 'lodash'

import BulkAssignModal from '@/views/Discover/Moments/v2/TargetMoment/BulkAssignModal'
import CheckTreeDataPicker from '@/components/CheckTreeDataPicker'

const Header = () => (
  <header
    data-testid="act-mgr-target-moment-modal-header"
    className="text-lg border-b-2 border-gray-300"
  >
    <p className="px-5 pb-5 font-medium text-gray-800">Assign Insertion Orders and Personas</p>
  </header>
)

type InsertionOrderOptions = {
  id: number
  name: string
  type: string
  personas?: Array<{
    id: string
    name: string
    type: string
  }>
}

/**
 * Bulk Assign Insertion Orders and Personas component.
 * @param {Object} props - Component props.
 * @param {boolean} props.show - Flag to control the visibility of the modal.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setShow - Function to set the visibility of the modal.
 * @param {(ioToPersonas: Map<number, Set<string>>) => void} props.handleApply - Function called when applying the assignment.
 * @param {InsertionOrderOptions[]} props.options - Array of InsertionOrderOptions for the options.
 * @param {number} props.selectedMomentsCount - Number of selected moments.
 * @returns {JSX.Element} The rendered component.
 */
const BulkAssignIOPersona = (props: {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  handleApply: (ioToPersonas: Map<number, Set<string>>) => void
  options: InsertionOrderOptions[]
}) => {
  const { show, handleApply, options } = props
  const [selectedPersonas, setSelectedPersonas] = React.useState<Map<string, string>>()
  const [selectedIoIds, setSelectedIoIds] = React.useState<Array<number>>([])
  const [ioSearchKeyword, setIoSearchKeyword] = React.useState<string>()
  const [personaSearchKeyword, setPersonaSearchKeyword] = React.useState<string>()
  const keyRef = React.useRef(0)

  const insertionOrderOptions = React.useMemo(() => {
    if (!ioSearchKeyword) return options
    return options.filter(({ name }) => name.toLowerCase().includes(ioSearchKeyword.toLowerCase()))
  }, [ioSearchKeyword])

  const ioOptionsMap = React.useMemo(
    () => new Map(insertionOrderOptions.map((io) => [io.id, io])),
    [insertionOrderOptions]
  )


  const personaOptions = React.useMemo(() => {
    const defaultOptions = _.cloneDeep(insertionOrderOptions).filter(({ id }) => selectedIoIds.includes(id))
    if (!personaSearchKeyword) return defaultOptions
    const filteredPersonaOptions = defaultOptions
      .map((option) => {
        const { personas } = option
        option.personas = personas?.filter(({ name }) =>
          name.toLowerCase().includes(personaSearchKeyword.toLowerCase())
        )
        return option
      })
      ?.filter((option) => option.personas && option.personas?.length > 0)
    return filteredPersonaOptions ?? []
  }, [selectedIoIds, personaSearchKeyword, insertionOrderOptions])

  /**
   * Handles the selection of all personas for a given node.
   * @param {boolean} selectAll - Flag to determine whether to select all personas.
   */
  const handlePersonaSelectAll = (selectAll: boolean) => {
    setSelectedPersonas((prev) => {
      const next = prev ? new Map(prev) : new Map()
      if (selectAll) {
        personaOptions.forEach((option) => {
          option.personas?.forEach((persona) => {
            next.set(persona.id, persona.name)
          })
        })
      } else {
        personaOptions.forEach((option) => {
          option.personas?.forEach((persona) => {
            next.delete(persona.id)
          })
        })
      }
      return next
    })
  }

  /**
   * Handles the selection of a persona node.
   * @param {any} activeNode - The active node being selected.
   * @param {any} value - The value of the selection.
   */
  const handlePersonaNodeSelect = (activeNode: any, value: any) => {
    const selectedKeys = Array.from(selectedPersonas?.keys() || [])
    setSelectedPersonas((prev) => {
      const next = prev ? new Map(prev) : new Map()
      if (activeNode.type === 'insertionOrder') {
        const existingPersonaValues = selectedKeys.filter((key) => key.startsWith(activeNode.id.toString()))
        if (existingPersonaValues.length > 0) {
          existingPersonaValues.forEach((personaId) => next.delete(personaId))
        } else {
          activeNode.personas?.forEach((persona: { id: string; name: string; type: string }) => {
            next.set(persona.id, persona.name)
          })
        }
      }
      if (activeNode.type === 'persona') {
        if (selectedPersonas && next.has(activeNode.id)) {
          next.delete(activeNode.id)
        } else {
          next.set(activeNode.id, activeNode.name)
        }
      }
      return next
    })
  }

  /**
   * Handles the change in the insertion order picker.
   * @param {number[]} value - The selected insertion order ids.
   */
  const handleIOPickerChange = (value: Array<number>) => {
    if (value.length <= 0) {
      setSelectedPersonas((prev) => {
        const next = prev ? new Map(prev) : new Map()
        const selectedPersonaIds = Array.from(selectedPersonas?.keys() || [])
        let personaIdsToBeDeleted: any[] = []

        ioOptionsMap.forEach((value, ioId) => {
          const personaIdsToBeDeletedList = selectedPersonaIds.filter((personaId) =>
            personaId.startsWith(ioId.toString())
          )
          personaIdsToBeDeleted = personaIdsToBeDeleted.concat(personaIdsToBeDeletedList)
        })
        personaIdsToBeDeleted.forEach((id) => next.delete(id))
        return next
      })
      setSelectedIoIds((prev) => {
        const next = new Set(prev)
        for (let ioId in ioOptionsMap) {
          next.delete(parseInt(ioId))
        }
        return Array.from(next)
      })
    }
    setSelectedIoIds((prev) => {
      const next = new Set(value)
      prev.forEach((ioId) => {
        if (!ioOptionsMap.has(ioId)) next.add(ioId)
      })
      return Array.from(next.values())
    })
    keyRef.current += 1
  }
  
  /**
   * Applies the selected insertion orders and personas.
   */
  const applyIoIdToPersonas = () => {
    const ioIdToPersonasMap =  new Map<number, Set<string>>()
    selectedPersonas?.forEach((persona, personaId)=>{
      const ioId = Number(personaId.split('_')[0])
      if(ioIdToPersonasMap.has(ioId)){
        const personas = new Set(ioIdToPersonasMap.get(ioId)||[])
        personas.add(persona)
        ioIdToPersonasMap.set(ioId, personas)
        return
      }
      ioIdToPersonasMap.set(ioId,new Set([persona]))
    })

    ioIdToPersonasMap && handleApply(ioIdToPersonasMap)
    props.setShow(false)
  }

  /**
   * Resets the state of the component.
   */
  const reset = () => {
    setSelectedIoIds(() => [])
    setSelectedPersonas(() => new Map())
    setIoSearchKeyword(() => undefined)
    setPersonaSearchKeyword(() => undefined)
    props.setShow(false)
  }

  return (
    <BulkAssignModal
      dataTestId="act-mgr-target-moment-assign-persona-modal"
      size="lg"
      handleApply={applyIoIdToPersonas}
      handleCancel={reset}
      show={show}
      header={<Header />}
    >
      <div className="flex gap-1 -mx-4 bg-gray-50">
        <CheckTreeDataPicker
          title="Insertion Orders"
          key="IOCheckTreeDataPicker"
          options={Array.from(ioOptionsMap.values())}
          labelKey="name"
          valueKey="id"
          value={selectedIoIds.filter((id) => ioOptionsMap.has(id))}
          handleChange={handleIOPickerChange}
          handleSearch={(searchKey: string) => {
            setIoSearchKeyword(() => searchKey)
          }}
          placeholder="No Result Found"
          suffixForCount={insertionOrderOptions.length <= 1 ? 'Insertion Order' : 'Insertion Orders'}
        />
        <CheckTreeDataPicker
          title="Personas"
          key={`PersonaCheckTreeDataPicker_v${keyRef.current}`}
          componentKey={`PersonaCheckTreeDataPicker_v${keyRef.current}`}
          options={personaOptions}
          labelKey="name"
          valueKey="id"
          value={Array.from(selectedPersonas?.keys() ?? [])}
          childrenKey="personas"
          handleSelectNode={handlePersonaNodeSelect}
          handleSelectAll={handlePersonaSelectAll}
          handleSearch={(searchKey: string) => {
            setPersonaSearchKeyword(() => searchKey)
          }}
          placeholder="Please select insertion orders for personas to appear"
          virtualized
          suffixForCount={personaOptions.length <= 1 ? 'Persona' : 'Personas'}
        />
      </div>
    </BulkAssignModal>
  )
}

export default BulkAssignIOPersona