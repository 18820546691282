import { combineReducers } from 'redux'
import {
    brandProfileLoading,
    brandProfileSaving,
    brandProfileIabCategoriesLoading,
    brandProfileSaved,
    brandProfileUnderEdit,
    isDownloadingBrandProfile,
    downloadingBrandProfileId,
    brandProfileTopicsLoading,
    outcomeMetricOptions
} from './brandProfiles'

import admin from './admin/index'
import engage from './engage/index'
import layoutReducer from './layout'

export default combineReducers({
    brandProfileUnderEdit,
    brandProfileIabCategoriesLoading,
    brandProfileTopicsLoading,
    isDownloadingBrandProfile,
    downloadingBrandProfileId,
    brandProfileSaving,
    brandProfileSaved,
    admin,
    engage,
    outcomeMetricOptions,
    layoutReducer
})
