import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Table from 'rsuite/lib/Table'
import SightlyButton from '@/components/Sightly/SightlyButton'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import { accentColor } from '@/assets/jss/colorConstants'
import { CustomWhisper } from '@/components/CustomWhisper'
import Dropdown from 'rsuite/lib/Dropdown'
import toast from 'react-hot-toast'
import BrandModal from './BrandModal'
import Panel from 'rsuite/lib/Panel'
import { Brand } from './brand-types'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import useUser from '@/hooks/useUser'
import { TableColumnJs } from '@/utils'
import { rqKeys } from './brand-rqKeys'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import { deleteBrand, getBrands } from './brand-queries-api'
import { products, userActions } from '@/staticData/products'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'
import config from '@/config'
import AccordionSection from '../components/AccordionSection'
import BrandAIGenerationForm, { BrandAIGenerationType } from './BrandAIGenerationForm'
import AiIcon from '@/views/BrandProfiles/BrandProfile/components/aiIcon.svg'
import useBrandQueries from './useBrandQueries'
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic'
import { Popover } from 'rsuite'

export const tableHeaderStyle = {
  backgroundColor: '#F9FAFB',
  color: '#6B7280'
}

export const AIGeneratedCell = ({ rowData, dataKey, ...props }: any) => (
  <Table.Cell {...props}>
    {rowData[dataKey] && (
      <Whisper
        delayShow={300}
        enterable={true}
        placement="auto"
        trigger="hover"
        speaker={
          <Popover style={{ borderRadius: '10px' }}>
            <div className="flex flex-col items-start p-2 -mx-2 text-gray-700 w-72">
              <p className="text-base font-bold">{rowData['name']}</p>
              {rowData['brandType'] && (
                <p className="p-1 text-xs font-semibold rounded ring-1 ring-gray-700">{rowData['brandType']}</p>
              )}
              <p className="text-left">{rowData['reason']}</p>
            </div>
          </Popover>
        }
      >
        <div className="w-5 h-5 -m-1 rounded-md flex items-center justify-center bg-gradient-to-b from-[#0047FF] via-[#5B32EF] to-[#333D47]">
          <img
            src={AiIcon}
            className="w-3 h-3"
          />
        </div>
      </Whisper>
    )}
  </Table.Cell>
)

const BrandSection = (props: { isActive?: boolean; handleAccordionClick?: () => void }) => {
  const queryClient = useQueryClient()
  const [editing, setEditing] = React.useState(false)
  const [editingId, setEditingId] = React.useState<number | null>(null)
  const [adding, setAdding] = React.useState(false)
  const [isAIGenerate, setIsAIGenerate] = React.useState(false)
  const { perms, userCanService, currentAccount } = useUser()
  const {
    params: { brandProfileId }
  } = useMatch<MyLocationGenerics>()
  const { brandQueryMutation, isMutatingBrandQuery, handleError } = useBrandQueries(Number(brandProfileId))

  const { queryResult: BrandProfileInfo } = useBrandProfileBasic(Number(brandProfileId))
  const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
  const viewOnly = viewOnlyParam || false
  const QUERY_KEY = rqKeys.brandProfileQuery('competitors', Number(brandProfileId))

  const {
    data: items,
    isLoading,
    isFetched
  } = useQuery(QUERY_KEY, () => getBrands(Number(brandProfileId)), {
    enabled: !!brandProfileId,
    refetchOnWindowFocus: false
  })

  const editingBrand = React.useMemo(() => {
    if (!editingId || !isFetched || !items || !editing) return null

    return items.find((brand: Brand) => brand.id === editingId)
  }, [editingId, editing, items])

  const brandNames = React.useMemo(() => {
    return (items && items.map((competitor: any) => competitor.name)) || []
  }, [items])

  const handleDelete = async (id: number) => {
    try {
      await deleteBrand(Number(brandProfileId), id)
      // to fetch the latest product quota
      await queryClient.fetchQuery(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.QUERIES))
      queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileBasic(Number(brandProfileId)) })
      toast.success('Deleted')
    } catch (err) {
      toast.error('Error')
    }

    await queryClient.invalidateQueries(QUERY_KEY)
  }

  type BrandAIGenerationFormType = {
    competitorsSuggestionCount: number
    aspirationalSuggestionCount: number
  }

  const handleAIGenerationFormSubmit = async (data: BrandAIGenerationFormType) => {
    try {
      const brandProfile = BrandProfileInfo.data
      if (!brandProfile) return
      let doNotSuggest = brandNames
      let payload: BrandAIGenerationType = {
        type: 'Competitor',
        brandName: brandProfile.brandProfileName,
        suggestionCount: 0,
        companySummary: brandProfile.companySummary,
        companyMentality: brandProfile.companyMentality,
        existingBrands: []
      }
      setIsAIGenerate(false)
      if (data.competitorsSuggestionCount) {
        payload.suggestionCount = data.competitorsSuggestionCount
        payload.existingBrands = doNotSuggest
        const resp = await brandQueryMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload })
        doNotSuggest = resp.data.doNotSuggest
      }
      if (data.aspirationalSuggestionCount) {
        payload.type = 'Aspirational Brand'
        payload.suggestionCount = data.aspirationalSuggestionCount
        payload.existingBrands = doNotSuggest
        const resp = await brandQueryMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload })
        doNotSuggest = resp.data.doNotSuggest
      }
    } catch (error) {
      handleError(error)
    }
  }

  const descriptionText = `Please enter your brand's competitors and aspirational brands.
			Aspirational brands need not be competitors, or even in the same
			industry as your brand.`
  if (config.featureFlag.aiProfiles) {
    return (
      <div data-testid="accordion-brand-section-panel">
        <AccordionSection
          sectionCaption="Brands"
          isActive={props.isActive ?? false}
          description={descriptionText}
          canCreate={!viewOnly}
          handleAddClick={() => setAdding(true)}
          handleAIGenerate={() => {
            setIsAIGenerate(true)
          }}
          handleAccordionClick={props.handleAccordionClick}
          isAIGenerating={!!isMutatingBrandQuery}
        >
          <BrandAIGenerationForm
            isAIGenerate={isAIGenerate}
            setIsAIGenerate={setIsAIGenerate}
            handleSubmitForm={handleAIGenerationFormSubmit}
          />
          {!!editingBrand && (
            <BrandModal
              key="edit"
              type="edit"
              open={editing}
              handleClose={() => {
                setEditing(false)
                setEditingId(null)
              }}
              item={editingBrand}
            />
          )}

          <BrandModal
            key="add"
            type="add"
            open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
            handleClose={() => setAdding(false)}
            item={{
              id: 0,
              name: '',
              wikiUrl: '',
              twitterProfileUrl: '',
              websiteUrl: '',
              relationshipId: 2,
              relationshipName: '',
              brandType: 'Competitor'
            }}
          />

          {isFetched && items.length > 0 && (
            <Table
              loading={isLoading}
              autoHeight
              data={items}
              bordered
              style={{
                borderRadius: '10px',
                boxShadow: '0px 1px 2px 0px #0000000F'
              }}
            >
              <TableColumnJs width={30}>
                <Table.HeaderCell style={tableHeaderStyle} />
                <AIGeneratedCell dataKey="isAIGenerated" />
              </TableColumnJs>
              <TableColumnJs flexGrow={2}>
                <Table.HeaderCell style={tableHeaderStyle}>TYPE</Table.HeaderCell>
                <Table.Cell dataKey="relationshipName" />
              </TableColumnJs>

              <TableColumnJs flexGrow={1}>
                <Table.HeaderCell style={tableHeaderStyle}>NAME</Table.HeaderCell>
                <Table.Cell dataKey="name"></Table.Cell>
              </TableColumnJs>

              <TableColumnJs flexGrow={1}>
                <Table.HeaderCell style={tableHeaderStyle}>WIKI</Table.HeaderCell>
                <Table.Cell dataKey="wikiUrl" />
              </TableColumnJs>

              <TableColumnJs flexGrow={1}>
                <Table.HeaderCell style={tableHeaderStyle}>TWITTER</Table.HeaderCell>
                <Table.Cell dataKey="twitterProfileUrl" />
              </TableColumnJs>

              <TableColumnJs flexGrow={1}>
                <Table.HeaderCell style={tableHeaderStyle}>WEBSITE</Table.HeaderCell>
                <Table.Cell dataKey="websiteUrl" />
              </TableColumnJs>

              <TableColumnJs flexGrow={1}>
                <Table.HeaderCell style={tableHeaderStyle}>RELATIONSHIP</Table.HeaderCell>
                <Table.Cell dataKey="brandType" />
              </TableColumnJs>

              {!viewOnly && (
                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: '#F9FAFB',
                      color: '#6B7280'
                    }}
                  ></Table.HeaderCell>
                  <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                    {(item: Brand) => {
                      return (
                        <CustomWhisper
                          dropdownItems={[
                            <Dropdown.Item
                              key={1}
                              onClick={() => {
                                setEditingId(item.id)
                                setEditing(true)
                              }}
                            >
                              Edit
                            </Dropdown.Item>,
                            <Dropdown.Item
                              key={2}
                              onClick={() => handleDelete(item.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          ]}
                        >
                          <IconButton
                            size="lg"
                            appearance="subtle"
                            icon={
                              <Icon
                                icon="more"
                                color={accentColor}
                              />
                            }
                          />
                        </CustomWhisper>
                      )
                    }}
                  </Table.Cell>
                </TableColumnJs>
              )}
            </Table>
          )}
        </AccordionSection>
      </div>
    )
  }
  return (
    <Panel header={<div className="sightlyPanelHeader">Brands</div>}>
      <div className="descriptionText">{descriptionText}</div>
      {!!editingBrand && (
        <BrandModal
          key="edit"
          type="edit"
          open={editing}
          handleClose={() => {
            setEditing(false)
            setEditingId(null)
          }}
          item={editingBrand}
        />
      )}

      <BrandModal
        key="add"
        type="add"
        open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
        handleClose={() => setAdding(false)}
        item={{
          id: 0,
          name: '',
          wikiUrl: '',
          twitterProfileUrl: '',
          websiteUrl: '',
          relationshipId: 2,
          relationshipName: '',
          brandType: 'Competitor'
        }}
      />

      {!viewOnly && items?.length > 0 && (
        <Whisper
          delayShow={300}
          enterable={true}
          placement="auto"
          trigger="hover"
          speaker={
            !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
              <Tooltip>
                <p>
                  This account has reached it’s limit.{' '}
                  <a
                    className="font-semibold"
                    target="_blank"
                    rel="noopener"
                    href="https://www.sightly.com/contact-us/"
                  >
                    Upgrade for more queries
                  </a>
                </p>
              </Tooltip>
            ) : (
              <Tooltip>Adds a new Query</Tooltip>
            )
          }
        >
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <SightlyButton
              disabled={!userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
              handleClick={() => setAdding(true)}
              id="addMorebutton"
              datatestid="queriesCreateNewbutton"
              text="Create New"
            />
          </div>
        </Whisper>
      )}

      {isFetched && items && items.length < 1 ? (
        <Whisper
          delayShow={300}
          enterable={true}
          placement="autoHorizontalStart"
          trigger="hover"
          speaker={
            !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
              <Tooltip>
                <p>
                  This account has reached it’s limit.{' '}
                  <a
                    className="font-semibold"
                    target="_blank"
                    rel="noopener"
                    href="https://www.sightly.com/contact-us/"
                  >
                    Upgrade for more queries
                  </a>
                </p>
              </Tooltip>
            ) : (
              <Tooltip>Adds a new Query</Tooltip>
            )
          }
        >
          <div>
            <SightlyButton
              disabled={!userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) || viewOnly}
              handleClick={() => setAdding(true)}
              id="addMorebutton"
              datatestid="queriesAddMorebutton"
              type="secondary"
              text="Add +"
            />
          </div>
        </Whisper>
      ) : (
        isFetched && (
          <Table
            loading={isLoading}
            autoHeight
            data={items}
          >
            <TableColumnJs flexGrow={2}>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.Cell dataKey="relationshipName" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell dataKey="name"></Table.Cell>
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Wiki</Table.HeaderCell>
              <Table.Cell dataKey="wikiUrl" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Twitter</Table.HeaderCell>
              <Table.Cell dataKey="twitterProfileUrl" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Website</Table.HeaderCell>
              <Table.Cell dataKey="websiteUrl" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Relationship</Table.HeaderCell>
              <Table.Cell dataKey="brandType" />
            </TableColumnJs>

            {!viewOnly && (
              <TableColumnJs flexGrow={1}>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                  {(item: Brand) => {
                    return (
                      <CustomWhisper
                        dropdownItems={[
                          <Dropdown.Item
                            key={1}
                            onClick={() => {
                              setEditingId(item.id)
                              setEditing(true)
                            }}
                          >
                            Edit
                          </Dropdown.Item>,
                          <Dropdown.Item
                            key={2}
                            onClick={() => handleDelete(item.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        ]}
                      >
                        <IconButton
                          size="lg"
                          appearance="subtle"
                          icon={
                            <Icon
                              icon="more"
                              color={accentColor}
                            />
                          }
                        />
                      </CustomWhisper>
                    )
                  }}
                </Table.Cell>
              </TableColumnJs>
            )}
          </Table>
        )
      )}
      <style>{`
				.descriptionText {
					color: #a3a3a3;
					font-size: 14px;
					margin-top: 24px;
					margin-bottom: 24px;
					font-weight: 500;									
				}		
			`}</style>
    </Panel>
  )
}

export default BrandSection
