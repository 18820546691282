import * as Yup from 'yup';
import { z } from 'zod';

export const rolesAndPermissionsObjValidation = Yup.array()
    .min(1, 'No roles associated with account')
    .of(
        Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            description: Yup.string().required(),
            userType: Yup.string().required(),
            permissions: Yup.array()
                .of(
                    Yup.object().shape({
                        id: Yup.number().defined(),
                        name: Yup.string().defined(),
                        description: Yup.string().defined(),
                    })
                )
                .defined()
        })
    )

export const userLoginValidation = Yup.object().shape({
    userId: Yup.number().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    company: Yup.string().required(),
    userType: Yup.string().required(),
    roles: Yup.array(),
    accounts: Yup.array(),
    permissions: Yup.array().of(
        Yup.object().shape({
            permissionId: Yup.number().required(),
            permissionName: Yup.string().required()
        })
    )
})

export const usersObjVal = Yup.array().of(
    Yup.object().shape({
        company: Yup.string().defined(),
        email: Yup.string().defined(),
        firstName: Yup.string().defined(),
        lastName: Yup.string().defined(),
        phoneNumber: Yup.string().defined(),
        roleId: Yup.number().defined(),
        userId: Yup.number().defined(),
        userName: Yup.string().defined(),
        userType: Yup.string().defined()
    })
)

export const brandScenarioObjValidation = Yup.array().of(
    Yup.object().shape({
        archived: Yup.bool().required(),
        scenarioId: Yup.number().required(),
        scenarioName: Yup.string().required()
    })
)

export const adminScenarioValidation = Yup.object().shape({
    archived: Yup.bool().required(),
    scenarioId: Yup.number().required(),
    scenarioName: Yup.string().required(),
    dataSegmentIds: Yup.array().of(Yup.number()).defined(),
    scenarioTypeIds: Yup.array().of(Yup.number()).defined(),
    labelId: Yup.number().defined()
})

export const brandOpinionObjValidation = Yup.array().of(
    Yup.object().shape({
        archived: Yup.bool().required(),
        opinionId: Yup.number().required(),
        question: Yup.string().required(),
        //opinionType: Yup.string().required(),
        opinionTypeIds: Yup.array().of(Yup.number()),
        opinionResponseId: Yup.string()
    })
)

export const brandTopicObjValidation = Yup.array().of(
    Yup.object().shape({
        children: Yup.array().of(
            Yup.object().shape({
                parentIds: Yup.array().required(),
                topicId: Yup.number().required(),
                topicName: Yup.string().required(),
                topicResponseId: Yup.number().required()
            })
        ),
        parentIds: Yup.array().required(),
        topicId: Yup.number().required(),
        topicName: Yup.string().required(),
        topicResponseId: Yup.number().required()
    })
)

export const aylienAdminVal = Yup.array().of(
    Yup.object().shape({
        id: Yup.number().required(),
        taxonomyCode: Yup.string().required(),
        taxonomyLevel: Yup.number().required(),
        taxonomyName: Yup.string().required()
    })
)

export const aylienIndustriesOptions = Yup.array().of(
    Yup.object().shape({
        id: Yup.number().required(),
        taxonomyCode: Yup.string().required(),
        taxonomyPath: Yup.string().required()
    })
)

export const adminIabCategoriesObj = Yup.array()
    .min(1, 'should have at least one iab cat')
    .of(
        Yup.object().shape({
            disregardLeafNode: Yup.bool().required(),
            iabCategoryId: Yup.string().required(),
            iabCategoryName: Yup.string().required(),
            keywords: Yup.array()
                .of(
                    Yup.object().shape({
                        keyword: Yup.string().required(),
                        archived: Yup.bool().required()
                    })
                )
                .defined()
        })
    )
