import React from 'react'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'

const HeaderGroup = (props: {
  selectedActivationIds: number[]
  activationsList: Array<ActivationItemType>
  allMomentsOnPageAreSelected: boolean
  handleSelectAll: (checked: boolean) => void
}) => {
  const getSelectedActivationsState = () => {
    return {
      selectAll: props.activationsList?.length === props.selectedActivationIds?.length,
      indeterminate:
        props.selectedActivationIds &&
        props.selectedActivationIds.length > 0 &&
        !(props.activationsList?.length === props.selectedActivationIds?.length),
      empty: props.selectedActivationIds.length === 0
    }
  }

  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="relative w-16 px-4"
        >
          <SightlyCheckbox
            dataTestId="activations-todo-select-all-checkbox"
            indeterminate={getSelectedActivationsState().indeterminate}
            handleChange={(checked: boolean) => props.handleSelectAll(checked)}
            id="selectAllTodo"
            checked={getSelectedActivationsState().selectAll}
          />
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600"
        >
          Insertion Order
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600 w-28"
        >
          Date Added
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600"
        >
          Personas
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600  w-28"
        >
          Assignees
        </th>

        <th
          scope="col"
          className="relative w-12 py-3 pl-3 pr-4 sm:pr-6"
        >
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  )
}

export default HeaderGroup
