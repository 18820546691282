import { z } from 'zod'

export interface INotImportedCampaign {
    id: number,
    campaignName: string
    operationStatus: string
}

export interface ICampaign {
    id: number,
    campaignName: string
    advertiserName: string
    operationStatus: string
    createTime: string
}

const ImportedCampaignSchema = z.object({
    id: z.number(),
    campaignName: z.string(),
    advertiserName: z.string(),
    operationStatus: z.string(),
    createTime: z.string()
})

export const GetImportedCampaignSchema = z.object({
    campaigns: z.array(ImportedCampaignSchema),
    total: z.string(),
    page: z.number(),
    pageSize: z.number(),
})

const NotImportedCampaignSchema = z.object({
    id: z.number(),
    campaignName: z.string(),
    operationStatus: z.string(),
})

export const GetNotImportedCampaignSchema = z.object({
    campaigns: z.array(NotImportedCampaignSchema),
    total: z.string(),
    page: z.number(),
    pageSize: z.number()
})

export const ImportedCampaignsStatusSchema = z.object({
    total: z.number(),
  });