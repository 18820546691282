import { userAccountAxios } from '@/axiosInstances';
import { AccountType } from '@/schemas/schemas_ts';

export const account = {
  update: async (account: AccountType) => {
    const url = `/account/${account.accountId}`

    const args = {
      accountId: account.accountId,
      accountName: account.accountName,
      contactName: account.contactName,
      contactEmail: account.contactEmail,
      accountTypeId: account.accountTypeId,
      accountMargin: account.accountMargin
    }

    return userAccountAxios.patch(url, args)
  },
  create: async (account: Partial<AccountType>) => {
    const url = `/account`
    return await userAccountAxios.post(url, account)
  },
  delete: async (accountId: number | undefined) => {
    if (typeof accountId === undefined) return
    const url = `/account/${accountId}`
    return await userAccountAxios.delete(url)
  }
};
