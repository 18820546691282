import dayjs, { Dayjs }  from "dayjs";
import utc from 'dayjs/plugin/utc'
import {AccountValues} from "@/schemas/schemas_ts";

dayjs.extend(utc)

type SubscriptionDate = Dayjs | string | null | undefined

// Only inclusive of one date
export function calculateDaysBetween(date1: SubscriptionDate, date2: SubscriptionDate) {
  if (!date1 || !date2) {
    return 0
  }

  return Math.abs(dayjs(date1).diff(date2, 'day'))
}

// Inclusive of both dates
export function calculateDaysBetweenInclusive(date1: SubscriptionDate, date2: SubscriptionDate) {
  if (!date1 || !date2) {
    return 0
  }

  return calculateDaysBetween(date1, date2) + 1
}

export function calculateCompletionPercentage(startDate: SubscriptionDate, endDate: SubscriptionDate) {
  const daysPassed = calculateDaysBetweenInclusive(startDate, dayjs.utc())
  const daysLeft = calculateDaysBetween(endDate, startDate)

  if (daysLeft === 0) {
    return 100
  }

  return Math.floor(( daysPassed / daysLeft ) * 100)
}


export const getSubscriptionValues = (currentAccount: AccountValues | undefined)=> {
  if (currentAccount === undefined || (currentAccount.trialStartDate === null && currentAccount.packageStartDate === null)) {
    return {
      percentage: 0,
      text: 'Unknown Subscription'
    }
  }

  if (currentAccount.accountStatus === 'Inactive' && currentAccount.packageStartDate) {
    return {
      percentage: 100,
      text: '0 days left on active period'
    }
  }

  if (currentAccount.accountStatus === 'Inactive' && currentAccount.trialStartDate) {
    return {
      percentage: 100,
      text: '0 days left on trial period'
    }
  }

  if (currentAccount.packageStartDate) {
    return {
      percentage: calculateCompletionPercentage(currentAccount.packageStartDate, currentAccount.packageEndDate),
      text: `${calculateDaysBetween(currentAccount.packageEndDate, dayjs.utc())} days left on active period`
    }
  }

  return {
    percentage: calculateCompletionPercentage(currentAccount.trialStartDate, currentAccount.trialEndDate),
    text: `${calculateDaysBetween(currentAccount.trialEndDate, dayjs.utc())} days left on trial period`
  }
}
