import React from 'react'
import { fetchChannelsService, patchListKeywordsService } from '../../../../services/lists_ts'
import { Channel } from '../../../../classes/channel'
import ChannelComponent from './components/ChannelComponent'
import { patchListSensitivityService, saveVersionFilterService, fetchVersionService } from '../../../../services/lists'
import config from '../../../../config'
import debounce from 'just-debounce-it'
import ListFrame from './components/ListFrame'
import { Version } from '../../../../classes/version'
import { ReactLocation, useMatch, useNavigate } from '@tanstack/react-location'
import { location } from '../../../../RouteContainer'

interface Sort {
    sortColumn: string
    sortType: string
}

interface FetchChannelParams {
    versionId: string
    pageNumber: number
    sort: Sort
    searchTerm: string
}

const ChannelListBuilder = () => {
    const navigate = useNavigate()
    const {
        params: { versionId }
    } = useMatch()
    const isFromViewClick = location.current.pathname.includes('view')
    const [version, setVersion] = React.useState<Version>({
        versionId: null,
        channels: [],
        videos: [],
        smartListId: 1,
        smartListName: '',
        brandProfileName: '',
        createdDate: '',
        updatedDate: '',
        uploaded: false,
        blockedKeywords: [],
        stats: {
            channelCount: 0,
            itemCount: 0,
            videoCount: 0
        },
        objectiveName: ''
    })
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const [currentSort, setCurrentSort] = React.useState<Sort>({
        sortColumn: 'views',
        sortType: 'desc'
    })
    const [hasNextPage, setHasNextPage] = React.useState<boolean>(true)
    const [currentPage, setCurrentPage] = React.useState<number>(1)
    const [contentIsLoading, setContentIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        if (!versionId) {
            return
        }

        async function handlePageLoad() {
            const _versionResult = await fetchVersionService(versionId)
            const _channels = await fetchInitialChannels()

            if (_channels.length < config.listbuilderIncrement) {
                setHasNextPage(false)
            } else {
                setHasNextPage(true)
            }

            setVersion(() => {
                const _version: Version = {
                    ..._versionResult,
                    channels: _channels
                }
                return _version
            })
            setContentIsLoading(false)
        }

        handlePageLoad()
    }, [])

    const handleBottomReached = async () => {
        setContentIsLoading(true)
        let params = {
            versionId: versionId,
            pageNumber: currentPage + 1,
            sort: currentSort,
            searchTerm
        }
        const channels = await fetchChannelsService(params)
        setVersion((prev) => {
            return {
                ...prev,
                channels: prev.channels ? prev.channels.concat(...channels) : channels
            }
        })

        setCurrentPage((prev) => prev + 1)

        if (channels.length < config.listbuilderIncrement) {
            setHasNextPage(false)
        } else {
            setHasNextPage(true)
        }

        setContentIsLoading(false)
    }

    const fetchInitialChannels = async () => {
        const params: FetchChannelParams = {
            versionId,
            pageNumber: currentPage,
            sort: currentSort,
            searchTerm
        }
        return await fetchChannelsService(params)
    }

    const handleSensitivityChange = async (val: 1 | -0.3 | -7 | -99999) => {
        setContentIsLoading(true)
        setHasNextPage(true)
        setCurrentPage(1)
        setVersion((prev) => {
            return {
                ...prev,
                channels: []
            }
        })

        const res = await patchListSensitivityService({
            sensitivity: val,
            smartListId: version.smartListId
        })

        if (res.status !== 200) {
            console.error('error patching keywords')
            return
        }

        const params: FetchChannelParams = {
            versionId,
            pageNumber: 1,
            sort: currentSort,
            searchTerm
        }
        const _channels: Channel[] = await fetchChannelsService(params)
        handleSetChannels(_channels)

        setContentIsLoading(false)
    }

    const handleSetChannels: Function = async (_channels: Channel[]) => {
        setVersion((prev) => {
            return {
                ...prev,
                channels: _channels
            }
        })

        if (_channels.length < config.listbuilderIncrement) {
            setHasNextPage(false)
        } else {
            setHasNextPage(true)
        }
    }

    const handleBlockedKeywordsExecute = async (arr: string[]) => {
        setContentIsLoading(true)

        setVersion((prev) => {
            return {
                ...prev,
                channels: []
            }
        })
        const res = await patchListKeywordsService({
            blockedKeywords: arr,
            smartListId: version.smartListId
        })

        if (res.status !== 200) {
            console.error('error patching keywords')
            return
        }

        const params: FetchChannelParams = {
            versionId,
            pageNumber: currentPage,
            sort: currentSort,
            searchTerm
        }
        const _channels: Channel[] = await fetchChannelsService(params)
        handleSetChannels(_channels)
        setContentIsLoading(false)
    }

    const handleBlockedKeywords = React.useCallback(debounce(handleBlockedKeywordsExecute, 1), [version])

    const handleFiltersSaved = async (args: { filters: object; versionId: number }) => {
        setContentIsLoading(true)
        setVersion((prev) => {
            return {
                ...prev,
                channels: []
            }
        })

        const res = await saveVersionFilterService(args)
        if (res.status === 200) {
            const params: FetchChannelParams = {
                versionId,
                pageNumber: currentPage,
                sort: currentSort,
                searchTerm
            }
            const _channels: Channel[] = await fetchChannelsService(params)
            handleSetChannels(_channels)
            setContentIsLoading(false)
        }
    }

    const handleChannelsToggle = async () => {
        navigate({
            to: `/app/engage/lists/videoListBuilder/${versionId}/${
                location.current.pathname.includes('view') ? 'view' : 'edit'
            }`
        })
    }

    const setCurrentChannelsSort = async (val: 'views' | 'subscribers' | 'videos') => {
        const sortObj = { sortColumn: val, sortType: 'desc' }
        setContentIsLoading(true)
        setCurrentSort(sortObj)
        setCurrentPage(1)
        setVersion((prev) => {
            return {
                ...prev,
                channels: []
            }
        })

        let params = {
            versionId,
            pageNumber: 1,
            sort: sortObj,
            searchTerm
        }

        const channels = await fetchChannelsService(params)

        handleSetChannels(channels)
        setContentIsLoading(false)
    }

    const handleChannelsSearch = debounce((value: string) => {
        handleChannelsSearchExecute(value)
    }, 500)

    const handleChannelsSearchExecute = async (_searchTerm: string) => {
        setContentIsLoading(true)
        setSearchTerm(_searchTerm)
        setCurrentPage(1)
        setHasNextPage(true)
        setVersion((prev) => {
            return {
                ...prev,
                channels: []
            }
        })

        let params = {
            versionId,
            pageNumber: 1,
            sort: currentSort,
            searchTerm: _searchTerm
        }

        const channels = await fetchChannelsService(params)

        handleSetChannels(channels)
        setContentIsLoading(false)
    }

    return (
        <ListFrame
            currentSort={currentSort}
            isVideo={false}
            isFromViewClick={isFromViewClick}
            version={version}
            handleBlockedKeywords={handleBlockedKeywords}
            handleSensitivityChange={handleSensitivityChange}
            handleFiltersSaved={handleFiltersSaved}
            handleChannelsToggle={handleChannelsToggle}
            contentIsLoading={contentIsLoading}
            children={version.channels.map((channel: Channel) => {
                return (
                    <div key={channel.id}>
                        <ChannelComponent
                            isFromViewClick={isFromViewClick}
                            channel={channel}
                            uploaded={version.uploaded}
                        />

                        <div
                            style={{
                                marginTop: 4,
                                marginBottom: 4,
                                height: 1,
                                backgroundColor: '#F5F5F5'
                            }}
                        />
                    </div>
                )
            })}
            handleSearch={handleChannelsSearch}
            setCurrentSort={setCurrentChannelsSort}
            handleBottomReached={handleBottomReached}
            hasNextPage={hasNextPage}
            sortOptions={[
                { id: 'views', label: 'Sort by View Count' },
                { id: 'subscribers', label: 'Sort by Subscriber Count' },
                { id: 'videos', label: 'Sort by Video Count' }
            ]}
        />
    )
}

export default ChannelListBuilder
