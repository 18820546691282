import React from 'react'
import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import CampaignStatus from '@/views/TikTok/Campaigns/components/CampaignStatus'
import { PaginationTailwind } from '@/components/PaginationTailwind'
import SightlyButton from '@/components/Sightly/SightlyButton'
import SearchBox from '@/components/SearchBox'
import { INotImportedCampaign } from '@/views/TikTok/Campaigns/types'
import { InfoPage } from '@/components/InfoPage'
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/20/solid'
import { LoadingPage } from '@/components/LoadingPage'

interface IPlatform {
    name: string
    logo: string
}

type IProps = {
    platform: IPlatform
    campaigns: INotImportedCampaign[]
    totalCampaigns: number
    page: number
    open: boolean
    handleClose: () => void
    onChangePage: (page: number) => void
    handleImportCampaigns: (campaigns: number[]) => void
    handleSearch: (searchKey: string) => void
    handleRefresh: () => void
    refreshingCampaigns: boolean
    selectedCampaigns: number[]
    setSelectedCampaigns: (campaigns: number[]) => void
}

const ImportCampaignsModal = ({
    platform, campaigns, totalCampaigns, page, open, handleClose, onChangePage,
    handleImportCampaigns, handleRefresh, refreshingCampaigns, selectedCampaigns, setSelectedCampaigns,
    handleSearch
}: IProps) => {
    const COUNT_PER_PAGE = 6

    const handleCheckboxChange = (campaignId: number) => {
        if (selectedCampaigns.includes(campaignId)) {
            setSelectedCampaigns(selectedCampaigns.filter((campaign) => campaign !== campaignId))
        } else {
            setSelectedCampaigns([...selectedCampaigns, campaignId])
        }
    }

    return (
        <PortalModal
            hasClose={true}
            handleClose={handleClose}
            open={open}
        >
            <div className='w-[80vh] h-[70vh] flex flex-col justify-between'>
                <div className='flex flex-col gap-2 mr-8'>
                    <p data-testid='tiktok-import-campaigns-modal-label' className='font-bold text-lg'>
                        Import from your accounts
                    </p>
                    <div className="flex items-center">
                        <img
                            src={platform.logo}
                            className="aspect-square h-6 rounded-full mr-2"
                            alt={platform.name}
                        />
                        <span data-testid={'tiktok-import-campaigns-modal-platform-name'} className='text-xs font-semibold'>{platform.name}</span>
                    </div>
                </div>
                <div className='py-4 flex justify-between items-center gap-2'>
                    <SearchBox
                        className="grow"
                        dataTestId="tiktok-import-campaigns-modal-campaigns-search-input"
                        handleSearch={handleSearch}
                        placeholder="Search"
                        disabled={false}
                    />
                    <ArrowPathRoundedSquareIcon
                        data-testid="refresh-not-imported-campaigns"
                        className="aspect-square h-5 cursor-pointer"
                        onClick={handleRefresh}
                    />
                </div>
                <div className='flex flex-col gap-2 overflow-y-auto grow'>
                    {refreshingCampaigns ? (
                        <LoadingPage message="Refreshing campaigns" />
                    ) : campaigns && campaigns.length > 0 ? (
                        campaigns.map((campaign) => (
                            <div
                                key={campaign.id}
                                data-testid={`tiktok-import-campaigns-modal-campaign-${campaign.id}`}
                                className='border rounded-md px-6 py-4 flex gap-0.5 items-center justify-between'
                            >
                                <div className='flex gap-4'>
                                    <SightlyCheckbox
                                        checked={selectedCampaigns.includes(campaign.id)}
                                        dataTestId={`tiktok-import-campaigns-modal-campaign-${campaign.id}-checkbox`}
                                        id={`tiktok-import-campaigns-modal-campaign-${campaign.id}-checkbox`}
                                        handleChange={() => handleCheckboxChange(campaign.id)}
                                    />
                                    <p
                                        data-testid={`tiktok-import-campaigns-modal-campaign-${campaign.id}-name`}
                                        className='font-semibold text-sm line-clamp-1 hover:line-clamp-2'
                                    >
                                        {campaign.campaignName}
                                    </p>
                                </div>
                                <CampaignStatus
                                    dataTestId={`tiktok-import-campaigns-modal-campaign-${campaign.id}-status`}
                                    active={campaign.operationStatus.toUpperCase() === 'ENABLE'}
                                />
                            </div>
                        ))
                    ) : (
                        <InfoPage message="No campaigns to be imported. Please refresh." />
                    )}
                </div>
                <div className='py-4'>
                    <PaginationTailwind
                        activePage={page}
                        countPerPage={COUNT_PER_PAGE}
                        total={totalCampaigns}
                        onChangePage={onChangePage}
                        showingStart={COUNT_PER_PAGE * (page - 1) + 1}
                        showingEnd={COUNT_PER_PAGE * page}
                    />
                </div>
                <div className='w-full flex justify-center'>
                    <SightlyButton
                        datatestid={'tiktok-import-campaigns-modal-import-button'}
                        id="tiktok-import-campaigns-modal-import-button"
                        type='purple'
                        handleClick={() => handleImportCampaigns(selectedCampaigns)}
                        text="Import campaigns"
                        disabled={selectedCampaigns.length < 1}
                    />
                </div>
            </div>
        </PortalModal>
    )
}

export default ImportCampaignsModal
