import React from 'react';
import { Section } from "@/views/OnboardingHub/components/Section";
import { InviteTeamMembers } from "./InviteTeamMembers";
import { ManageSubscription } from "./ManageSubscription";
import { UpdateSettings } from "./UpdateSettings";
import { ProductQuotaTally } from "@/hooks/subscription/useProductUsage/types";
import { AccountValues } from "@/schemas/schemas_ts";

type AccountManagementProps = {
  headerName: string
  userCanService: Function
  productQuotaTally: ProductQuotaTally
  currentAccount: AccountValues | undefined
}

export const AccountManagement = ({ headerName, userCanService, productQuotaTally, currentAccount }: AccountManagementProps) => {
  return (
    <Section dataTestId="account-management" headerName={headerName}>
      <div className="grid gap-6">
        <InviteTeamMembers
          userCanService={userCanService}
          productQuotaTally={productQuotaTally}
        />
        <ManageSubscription currentAccount={currentAccount} />
        <UpdateSettings />
      </div>
    </Section>
  )
}
