import React from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import SearchBox from '@/components/SearchBox';
import useUser from '@/hooks/useUser';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { Assignees } from '@/views/Discover/Activations/v2/ToDo/Assignees';
import { RocketLaunchIcon, TrashIcon } from '@heroicons/react/24/outline';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

type ToolBarProps = {
  handleSearchMoments: (searchKey: string) => void
  assigneesOptions: {
    email: string
    fullName: string
  }[]
  assignees: string[]
  setAssignees: React.Dispatch<React.SetStateAction<string[]>>
  brandProfileId: number | undefined
  setIsEditOpen: (value: React.SetStateAction<boolean>) => void
  handleBulkDelete: () => void
  handleDownload: () => void
  areBulkOpsDisabled: boolean
}

const ToolBar = ({
  handleSearchMoments,
  assigneesOptions,
  assignees,
  setAssignees,
  brandProfileId,
  setIsEditOpen,
  handleBulkDelete,
  handleDownload,
  areBulkOpsDisabled
}: ToolBarProps) => {
  const { perms, userPermissions } = useUser()

  return (
    <div className="flex justify-between">
      <div className="flex justify-around gap-2">
        <SearchBox
          dataTestId="activations-search-moments"
          handleSearch={handleSearchMoments}
          key={brandProfileId}
          placeholder="Search Moments"
        />
        <Assignees
          options={assigneesOptions}
          assignees={assignees}
          setAssignees={setAssignees}
        />
      </div>

      <div className="flex justify-end space-x-5">
        <SightlyButton
          datatestid="activations-implement-btn"
          id="activations-implement-btn"
          handleClick={() => setIsEditOpen(true)}
          text="Implement"
          disabled={areBulkOpsDisabled}
          icon={
            <RocketLaunchIcon
              className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
              aria-hidden="true"
            />
          }
        />
        <SightlyButton
          datatestid="activations-bulk-delete-btn"
          handleClick={handleBulkDelete}
          id="bulkDeleteActivations"
          text="Remove"
          disabled={areBulkOpsDisabled}
          icon={
            <TrashIcon
              className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
              aria-hidden="true"
            />
          }
        />
        {userCanPermissionProductQuota({
          requiredPermissionValue: perms.TRENDS_DOWNLOAD,
          userPermissions: userPermissions,
          checkType: PermissionCheckType.PERMISSION_CHECK
        }) && (
          <SightlyButton
            datatestid="activations-download-btn"
            handleClick={handleDownload}
            id="downloadActivation"
            text="Download"
            disabled={areBulkOpsDisabled}
            icon={
              <ArrowDownTrayIcon
                className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                aria-hidden="true"
              />
            }
          />
        )}
      </div>
    </div>
  )
}

export default ToolBar
