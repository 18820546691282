import React from 'react';
import { accountTypes } from '@/staticData/data';
import SightlyButton from '@/components/Sightly/SightlyButton';
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm';
import SightlySelect from '@/components/Sightly/SightlyFormElements/SightlySelect';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import SightlySelectForm from '@/components/Sightly/SightlyFormElements/SightlySelectForm';
import Input from 'rsuite/lib/Input';
import { permissionView } from '@/utils';
import PortalModal from '@/components/PortalModal/PortalModal';
import useUser from '@/hooks/useUser';
import useAccount from '@/hooks/useAccount';
import { AccountType } from '@/schemas/schemas_ts';
import { z } from 'zod';
import { LoadingPage } from '@/components/LoadingPage';
import styled from 'styled-components';
import { SettingsLayout } from '@/layouts/SettingsLayout';
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Header = styled.div`
    width: 100%;
    padding: 1rem 1.5rem 0 1.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #333D47;
`

const Label = styled.p`
    font-size: 1.125rem;
    font-weight: 500;
    color: #111827;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 48rem;
    padding: 1.5rem;
`

const AccountPartialSchema = z
    .object({
        accountName: z.string().min(2, 'Must be greater than 1 character').max(50, 'Must be less than 50 characters'),
        contactName: z.string().min(2, 'Must be greater than 1 character').max(50, 'Must be less than 50 characters'),
        contactEmail: z.string().email('Invalid email'),
        accountTypeId: z.number()
    })
    .passthrough()

export const AccountDetails: React.FC = () => {
    const { accounts, currentAccount, perms, userPermissions } = useUser();
    const { deleteAccount, createAccount, updateAccount } = useAccount();

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm<AccountType>({
        defaultValues: currentAccount,
        resolver: zodResolver(AccountPartialSchema)
    })

    React.useEffect(() => {
        reset(currentAccount)
    }, [currentAccount])

    const onSubmit = async (account: AccountType) => {
        updateAccount(account)
    }

    const [open, setOpen] = React.useState(false)
    const [name, setName] = React.useState('')
    const [accountType, setAccountType] = React.useState(accountTypes[0].accountTypeId)

    const handleCreateChild = async (current: AccountType) => {
        setOpen(false)

        let levelId = current.accountLevelId + 1
        if (levelId > 3) {
            levelId = 3
        }
        let childAccount = {
            accountName: name,
            accountTypeId: accountType,
            accountLevelId: levelId,
            accountMargin: 0,
            contactName: 'placeholder',
            contactEmail: 'placeholder@placeholder.com',
            parentAccountId: current.accountId
        }
        createAccount(childAccount)
    }

    const handleDeleteAccount = async (current: AccountType) => {
        if (current.accountName === 'Sightly') {
            alert('You cannot delete the top level Sightly account')
            return
        }
        if (confirm('Are you sure you want to delete this account?')) {
            deleteAccount(current.accountId)
        }
    }

    let current = currentAccount

    let accountEditDisabled = React.useMemo(() => {
        if (!accounts || accounts.length < 1 || !current) {
            return true
        }

        if (!userCanPermissionProductQuota({
            requiredPermissionValue: perms.ACCOUNT_UPDATE,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
        })) {
            return true
        }

        if (current.accountId === 1) {
            return true
        }
        let topLevelAccounts = accounts.map((account: AccountType) => account.accountId)


        if (topLevelAccounts.includes(current.accountId) && currentAccount?.children?.length && !userCanPermissionProductQuota({
            requiredPermissionValue: perms.ASSIGNED_ACCOUNT_UPDATE,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
        })) {
            return true
        }

        return false
    }, [current, accounts])

    if (current === undefined) {
        return <LoadingPage message="" />
    }
    return (
        <SettingsLayout>
            <ContainerDiv>
                <Header>Account Details</Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <SightlyInputForm
                        error={errors?.accountName?.message}
                        name="accountName"
                        register={register}
                        id="accountName"
                        label="Account Name"
                        disabled={accountEditDisabled}
                        control={control}
                    />

                    <SightlyInputForm
                        error={errors?.parentAccountName?.message}
                        name="parentAccountName"
                        register={register}
                        id="parentAccountName"
                        label="Parent Account Name"
                        disabled
                        placeholder="-"
                        control={control}
                    />

                    <SightlyInputForm
                        error={errors?.contactName?.message}
                        name="contactName"
                        register={register}
                        id="contactName"
                        label="Contact Name"
                        disabled={accountEditDisabled}
                        control={control}
                    />

                    <SightlyInputForm
                        error={errors?.contactEmail?.message}
                        name="contactEmail"
                        register={register}
                        id="contactEmail"
                        label="Contact Email"
                        disabled={accountEditDisabled}
                        control={control}
                    />

                    <SightlySelectForm
                        label="Account Type"
                        id="eventRelationship"
                        labelKey="accountTypeName"
                        valueKey="accountTypeId"
                        name={'accountTypeId'}
                        options={accountTypes}
                        control={control}
                        disabled={accountEditDisabled}
                    />
                    <div style={{ display: 'flex', gap: 8 }}>
                        {current.accountName === 'Sightly' ||
                            current.accountId === 1 ||
                            (current.children && current.children.length > 0) ? null : (
                            <>
                                {userCanPermissionProductQuota({
                                    requiredPermissionValue: perms.ACCOUNT_DELETE,
                                    userPermissions: userPermissions,
                                    checkType: PermissionCheckType.PERMISSION_CHECK
                                }) && (
                                    <>
                                        <SightlyModernButton
                                            preventDefaultOnClick
                                            id="deleteAccountButton"
                                            text="Delete"
                                            type="cancel"
                                            handleClick={() => current && handleDeleteAccount(current)}
                                        />
                                        {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_DELETE}</div>}
                                    </>
                                )}
                            </>
                        )}

                        {!accountEditDisabled && (
                            <>
                                <SightlyModernButton
                                    preventDefaultOnClick
                                    id="submitButton"
                                    handleClick={() => {
                                        handleSubmit(onSubmit)()
                                    }}
                                    text="Save"
                                />
                                {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_UPDATE}</div>}
                            </>
                        )}
                    </div>

                    {userCanPermissionProductQuota({
                        requiredPermissionValue: perms.ACCOUNT_CREATE,
                        userPermissions: userPermissions,
                        checkType: PermissionCheckType.PERMISSION_CHECK
                    }) && (
                        <div>
                            <SightlyModernButton
                                preventDefaultOnClick
                                id="createChildAccountShowModalButton"
                                handleClick={() => setOpen(true)}
                                text="Create Child Account"
                            />
                            {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_CREATE}</div>}
                        </div>
                    )}
                </Form>

                <PortalModal
                    open={open}
                    handleClose={() => setOpen(false)}
                    footerButtons={[
                        <SightlyButton
                            id="cancelCreateAccountSubmit"
                            text="Cancel"
                            type="secondary"
                            handleClick={() => setOpen(false)}
                        />,
                        <SightlyButton
                            id="createAccountSubmit"
                            text="Submit"
                            disabled={name.length < 2}
                            handleClick={() => (current ? handleCreateChild(current) : undefined)}
                        />
                    ]}
                >
                    <div>
                        <h4 className="mb-8">Create a new Account under {current.accountName}</h4>

                        <div className="input-label">Account Name</div>
                        <Input
                            id="accountNameInput"
                            onChange={(e: string) => setName(e)}
                            value={name}
                        />
                        <div style={{ height: 24 }} />
                        <SightlySelect
                            label="Account Type"
                            id="accountTypeInput"
                            labelKey="accountTypeName"
                            valueKey="accountTypeId"
                            placeholder="Account Type"
                            options={accountTypes}
                            onChange={(id: number) => setAccountType(id)}
                            value={accountType}
                        />
                    </div>
                </PortalModal>
            </ContainerDiv>
        </SettingsLayout>
    )
}
