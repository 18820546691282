import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { ISortBy } from '../../ConversationsTab/NarrativesPanel'

export function SortBy({
    selected,
    onChange,
    options,
}: {
    selected: ISortBy
    onChange: Function
    options: ISortBy[]
}) {
    return (
        <div data-testid="sort-by-button">
            <Menu
                as="div"
                className="relative inline-block text-left z-10">
                <div>
                    <Menu.Button className="inline-flex justify-between w-full px-4 py-2 text-sm font-semibold text-gray-700 outline-none hover:bg-opacity-30 ">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="5" width="16" height="3" fill="#395066"/>
                        <rect x="9" y="10" width="11" height="3" fill="#395066"/>
                        <rect x="13" y="15" width="7" height="3" fill="#395066"/>
                    </svg>

                        Sort By
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            {
                                options.map( option => 
                                    <Menu.Item key={option.value}>
                                        {({ active }) => (
                                            <button
                                                onClick={() => onChange(option)}
                                                className={`${
                                                    active ? 'bg-slate-100' : 'text-gray-900'
                                                } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                            >
                                                {option.name}
                                                {selected.value === option.value && (
                                                    <CheckIcon className="w-5 h-5 mr-2 text-sightly-blue" />
                                                )}
                                            </button>
                                        )}
                                    </Menu.Item>
                                )
                            }
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
