import { rqKeys } from '@/ReactQueryKeyFactory';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@/api/api';
import toast from 'react-hot-toast';
import { Dispatch, SetStateAction } from 'react';
import { BrandProfile } from '@/classes/brandProfile';
import useUser from '../useUser';

export type BasicInfoType = {
    brandProfileId: number
    brandProfileName: string
    brandInfoName: string
    wikiUrl: string
    aylienIndustryId: number
    websiteUrl: string
    twitterProfileUrl: string
    submitted?: boolean
    companySummary?: string
    companyMentality?: string
    keyTerms?: string
    missionStatement?: string
    valueStatement?: string 
    visionStatement?: string
    aiSuggestedKeyTerms?: string
    aiGenerateData?: aiGenerateType
}

export type aiGenerateType = {          
        brandName?: string
        websiteUrl?: string  
}

const save = async (data: BasicInfoType) => {
    const args: any = {
        ...data,
        brandName: data.brandProfileName
    }
    delete args.brandProfileName
    delete args.submitted
    return await api.brandProfile.basicInfo.patch(JSON.parse(JSON.stringify(args)))
}

const useBrandProfileBasic = (brandProfileId: number, setDisableKeyTerms?:Dispatch<SetStateAction<boolean>>, aiGenerateData?: aiGenerateType) => {
    const { currentAccount } = useUser()
    const queryClient = useQueryClient()
    const queryResult = useQuery(
        rqKeys.brandProfileBasic(Number(brandProfileId)),
        () => api.brandProfile.basicInfo.get(Number(brandProfileId)),
        { 
            enabled: !!brandProfileId, 
            refetchOnWindowFocus: false,
        }
    )

    const mutationResult = useMutation(save, {
        onMutate: async () => {
            setDisableKeyTerms && setDisableKeyTerms(prev => true)
        },
        onSuccess: async () => {
            toast.success('Changes saved')
            queryClient.invalidateQueries({queryKey: rqKeys.brandProfileBasic(Number(brandProfileId))})
        },
        onSettled: () => {
            setDisableKeyTerms && setDisableKeyTerms(prev => false)
        }
    })

    const submitMutation = useMutation(() => api.brandProfile.submit(brandProfileId), {
        onSuccess: () => {
            queryClient.invalidateQueries(rqKeys.brandProfileBasic(Number(brandProfileId)))
        }
    })

    const submitWithoutBoardMutation = useMutation(() => api.brandProfile.submitWithoutBoard(brandProfileId), {
        onSuccess: () => {
            queryClient.invalidateQueries(rqKeys.brandProfileBasic(Number(brandProfileId)))
        }
    })

    const aiGenerateMutation = useMutation(() => api.brandProfile.basicInfo.aiGenerate(brandProfileId, aiGenerateData || {}, currentAccount?.accountId), {
        mutationKey: rqKeys.brandProfileAiGenerateKey(currentAccount?.accountId),
        onSuccess: () => {
            queryClient.invalidateQueries(rqKeys.brandProfileBasic(Number(brandProfileId)))
        }
    })
    
    return {
        queryResult,
        mutationResult,
        submitMutation,
        submitWithoutBoardMutation,
        submit: submitMutation.mutate,
        submitWithoutBoard: submitWithoutBoardMutation.mutate,
        aiGenerate: aiGenerateMutation.mutate
    }
}

export default useBrandProfileBasic
