import { useQuery } from '@tanstack/react-query';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { api } from '@/api/api';
import { logError } from '@/utils';
import { useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';

export type BoardV1 = {
  id: number
  name: string
  brandProfileId?: number | null
  defaultType: string | null
  createdByName?: string
  createdDate?: string
  updatedDate?: string
  updatedByName?: string
  starred: boolean
  type: 'custom' | 'general' | 'naturalLanguage'
  selectedItems: string[]
  operator: 'and' | 'or'
  prompt: any
  competitors?: any
}

interface SearchParams {
  id?: string;
  aiPromptText?: string
  boardId?: number
  ideasToLeanInto?: string
  ideasToAvoid?: string
  brandProfileId?: number
}

const fetchDefaultResource = async (params: SearchParams): Promise<BoardV1> => {
  return {
    id: 0,
    name: '',
    brandProfileId: params.brandProfileId || null,
    defaultType: null,
    starred: false,
    type: 'naturalLanguage',
    selectedItems: [],
    operator: 'or',
    prompt: {
      aiPromptText: params.aiPromptText ?? '',
      ideasToLeanInto: params.ideasToLeanInto ?? '',
      ideasToAvoid: params.ideasToAvoid ?? ''
    }
  };
};

export default function useBoardsV1(editBoardId?: number, editingBoard: boolean = false) {
  const search = useSearch<MyLocationGenerics>();
  const boardId = Number(editBoardId);
  const QUERY_KEY = rqKeys.boardV1(boardId);
  const shouldRetrieve:boolean = !!boardId && editingBoard;
  const queryObj = useQuery(QUERY_KEY, shouldRetrieve ? () => api.boardsV1.get({ boardId }) : () => fetchDefaultResource(search), {
    enabled: !!boardId,
    retry: 1,
    staleTime: 50000,
    onError: (err) => {
    logError(err, { info: 'onError in board v1 query' })
    },
    refetchOnWindowFocus: false
  })

  return queryObj;
}
