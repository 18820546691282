import SlideOver from '@/components/SlideOver'
import React from 'react'
import { Footer } from '@/views/TikTok/ActivationManager/Footer'
import { SelectedHashtagType } from '@/views/TikTok/Hashtags/types'
import SearchBox from '@/components/SearchBox'
import { CategoryFilter } from './CategoryFilter'
import ActivationsTable from './ActivationsTable'
import { ListedCategoryType, TargetedActivationListType } from '@/views/TikTok/ActivationManager/types'
import { useTargetedActivationList } from '@/views/TikTok/ActivationManager/ActivationManagerContext'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import useUser from '@/hooks/useUser'
import { perms } from '@/staticData/permissions'
import { products } from '@/staticData/products'

type IProps = {
  open: boolean
  setOpen: (val: boolean) => void
  setSelectedHashtags: React.Dispatch<React.SetStateAction<SerializableMap<string, SelectedHashtagType>>>
}

const ActivationManager = ({ open, setOpen, setSelectedHashtags }: IProps) => {
  const targetActivationList = useTargetedActivationList()
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const [disabledCreateButtonReasons, setDisabledCreateButtonReason] = React.useState<Set<string>>()
  const [categoryFilters, setCategoryFilters] = React.useState<string[]>([])
  const { userCanService } = useUser()

  const disabledCreateTargetMoment = !userCanService(perms.TIKTOK_ACTIVATIONS_UPDATE, products.TIKTOK_ACTIVATIONS)

  const categoryOptions = React.useMemo(() => {
    const categories: Set<string> = new Set()
    targetActivationList.state.forEach(({ categoryName }) => {
      categories.add(categoryName)
    })
    const options: ListedCategoryType = []
    categories.forEach((category) =>
      options.push({
        categoryId: category,
        categoryName: category
      })
    )
    return options
  }, [])

  const filteredHashtagsByCategory = React.useMemo(() => {
    if (!categoryFilters || !categoryFilters.length) {
      return targetActivationList.state
    }
    const filteredHashtags = new SerializableMap<string, TargetedActivationListType>()

    targetActivationList.state.forEach((activation, hashtagId) => {
      if (categoryFilters.includes(activation.categoryName)) {
        filteredHashtags.set(hashtagId, activation)
      }
    })
    return filteredHashtags
  }, [targetActivationList.state, categoryFilters])

  const [searchedHashtagsIds, setSearchedHashtagsIds] = React.useState<string[]>([])

  React.useEffect(() => {
    setSearchedHashtagsIds((prev) => Array.from(filteredHashtagsByCategory.keys()))
  }, [categoryFilters])

  React.useEffect(() => {
    setSelectedHashtags(
      () =>
        new SerializableMap(
          Array.from(targetActivationList.state.entries()).map(([hashtagId, { hashtagName, categoryName }]) => [
            hashtagId,
            { hashtagId, hashtagName, categoryName }
          ])
        )
    )
    setSearchedHashtagsIds((prev) => prev.filter((id) => targetActivationList.state.has(id)))

    if (targetActivationList.state.size === 0) setOpen(false)
  }, [targetActivationList.state])

  const filteredActivations = React.useMemo(() => {
    const _filteredActivations = new SerializableMap<string, TargetedActivationListType>()
    targetActivationList.state.forEach((activation, hashtagId) => {
      if (!searchedHashtagsIds.includes(hashtagId)) return
      if (categoryFilters.length && !categoryFilters.includes(activation.categoryName)) return
      _filteredActivations.set(hashtagId, activation)
    })
    return _filteredActivations
  }, [searchedHashtagsIds, targetActivationList.state, categoryFilters])

  const handleSearchHashtags = React.useCallback(
    (searchKey: string) => {
      const filteredHashtagIds: string[] = []
      filteredHashtagsByCategory.forEach((activation, hashtagId) => {
        if (activation.hashtagName.toLowerCase().includes(searchKey.toLowerCase())) {
          filteredHashtagIds.push(hashtagId)
        }
      })
      setSearchedHashtagsIds(() => [...filteredHashtagIds])
    },
    [filteredHashtagsByCategory]
  )

  const handleCreateTargetHashtags = () => {}

  return (
    <SlideOver
      dataTestId="tiktok-act-mgr-target-moment-drawer"
      headerBackgroundColor="bg-sightlyBlue"
      headerTitle="New Target List"
      headerDescription="Get started by filling in the information below to create your new target list."
      show={open}
      onHide={() => setOpen(false)}
      fullScreen={isFullScreen}
      maxOffset="345px - 7em"
      minOffset="7em"
      footer={
        <Footer
          disabledCreateButton={disabledCreateTargetMoment}
          disabledCreateButtonReasons={disabledCreateButtonReasons}
          handleConfirmClick={handleCreateTargetHashtags}
          setOpen={setOpen}
        />
      }
    >
      <label className="px-4 pt-6 mb-2 font-medium sm:px-6 text-grey-900">Hashtags ({filteredActivations.size})</label>

      <div className="sticky flex justify-between px-6 py-2">
        <div className="w-[200px]">
          <CategoryFilter
            categoryOptions={categoryOptions}
            handleFilterCategory={(categoryFilters: string[]) => {
              setCategoryFilters(() => categoryFilters)
            }}
          />
        </div>
        <SearchBox
          handleSearch={handleSearchHashtags}
          placeholder="Search Hashtags"
          dataTestId="tiktok-act-mgr-search-hashtags"
        />
      </div>
      <ActivationsTable
        {...{
          setSearchedHashtagsIds,
          filteredActivations
        }}
      />
    </SlideOver>
  )
}

export default ActivationManager
