import React from 'react'
import Button from 'rsuite/lib/Button'
import ButtonToolbar from 'rsuite/lib/ButtonToolbar'
import Table from 'rsuite/lib/Table'
import { routes } from '@/routes'
import { useQuery } from '@tanstack/react-query'
import { brandOpinionObjValidation } from '@/schemas/schemas'
import Icon from 'rsuite/lib/Icon'
import IconButton from 'rsuite/lib/IconButton'
import { archivedStatuses } from '@/views/Engage/Lists/constants'
import { userAccountAxios } from '@/axiosInstances'
import { useNavigate } from '@tanstack/react-location'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { LoadingPage } from '@/components/LoadingPage'
import SightlySelect from '@/components/Sightly/SightlyFormElements/SightlySelect'
import Sidebar from '@/views/Admin/components/Sidebar';

const getAdminOpinions = async () => {
    const url = `/opinions`

    try {
        const result = await userAccountAxios.get(url)
        if (result.status === 200) {
            let opinions = result.data

            brandOpinionObjValidation.validate(opinions.slice(0, 5)).catch(function (err) {
                console.log(err.name, err.errors)
                console.error('We received different API data than expected, see the console log for more details.')
            })
            return opinions
        }
    } catch (error) {
        console.error(error)
    }
}

function Opinions() {
    const navigate = useNavigate();
    const [archiveFilter, setArchiveFilter] = React.useState(null);
    const { data: opinions, isLoading } = useQuery(rqKeys.adminOpinions(), () => getAdminOpinions());

    const handleCreateOpinionClick = () => {
        let url = routes.admin.opinions.create.path
        navigate({ to: url })
    }

    const handleConfigureTypesClick = () => {
        let url = routes.admin.opinions.types.path
        navigate({ to: url })
    }

    const filteredOpinions = React.useMemo(() => {
        if (archiveFilter === null) {
            return opinions
        }
        if (archiveFilter === 1) {
            return opinions.filter((s) => s.archived)
        }
        if (archiveFilter === 2) {
            return opinions.filter((s) => !s.archived)
        }

        return opinions
    }, [opinions, archiveFilter])

    return (
        <div style={{ display: "flex" }}>
            <Sidebar />
            {isLoading ? (
                <LoadingPage message="Fetching Opinions" />
            ) : (
                <div style={{ padding: 24, width: "100%" }}>
                    <h4 style={{ marginBottom: 24 }}>Opinions</h4>
                    <ButtonToolbar style={{ marginBottom: 24 }}>
                        <Button
                            appearance="primary"
                            onClick={handleCreateOpinionClick}
                        >
                            Create Opinion
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={handleConfigureTypesClick}
                        >
                            Configure Opinion Types
                        </Button>
                    </ButtonToolbar>

                    <SightlySelect
                        width={200}
                        id="archivedStatusId"
                        label="Archived"
                        placeholder="Filter archived status..."
                        labelKey="archivedStatusName"
                        valueKey="archivedStatusId"
                        options={archivedStatuses}
                        onChange={(val) => {
                            setArchiveFilter(val)
                        }}
                        value={archiveFilter}
                    />
                    <div className="h-4" />
                    <Table
                        virtualized
                        autoHeight
                        data={filteredOpinions || []}
                    >
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>Question</Table.HeaderCell>
                            <Table.Cell dataKey="question" />
                        </Table.Column>
                        <Table.Column>
                            <Table.HeaderCell>Archived</Table.HeaderCell>
                            <Table.Cell>
                                {(opinion) => {
                                    return <div>{opinion.archived ? 'True' : 'False'}</div>
                                }}
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column
                            width={200}
                            fixed
                        >
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.Cell>
                                {(opinion) => {
                                    return (
                                        <IconButton
                                            onClick={() =>
                                                navigate({
                                                    to: routes.admin.opinions.path + '/edit/' + opinion.opinionId
                                                })
                                            }
                                            icon={<Icon icon="edit" />}
                                            appearance="ghost"
                                            size="xs"
                                            id="editOpinionButton"
                                        >
                                            Click to Edit
                                        </IconButton>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
            )}
        </div>
    )
}

export default Opinions
