import React from 'react';
import { accentColor, brandPinkColor } from '@/assets/jss/colorConstants';
import { LoadingPage } from '@/components/LoadingPage';
import Sidebar from '@/views/Admin/components/Sidebar';
import useRoles from '@/hooks/useRoles';
import usePermissions from "@/hooks/usePermissions";

export default function Permissions() {
    const { data: roles, isLoading, updateRole } = useRoles();
    const { data: permissions } = usePermissions();

    const handlePermissionChange = async (role, permission, isSelected ) => {
        if (isSelected) {
            role.permissions.push(permission)
        } else {
            const index = role.permissions.findIndex(p => p.id === permission.id);

            if (index !== -1) {
                role.permissions.splice(index, 1);
            }
        }

        updateRole(role)
    }

    return (
      <div style={{ display: "flex" }}>
          <Sidebar />
          {isLoading ? (
              <LoadingPage message="Fetching permissions" />
          ) : (
              <div className="view">
                  <h3 className="mb-8">Permissions</h3>
                  <div className="permWrapper">
                      <table className="permTable">
                          <thead>
                              <tr className="permRow header-row">
                                  <th className="permHeader sticky-col first-col-header">Permission Name</th>
                                  <th className="permHeader sticky-col second-col">Permission Description</th>
                                  {roles?.map((role) => {
                                            return (
                                                <th
                                                    className="permHeader"
                                                    key={role.id}
                                                >
                                                    {role.name}
                                                    <div
                                                        style={{
                                                            color: role.userType === 'Internal' ? accentColor : brandPinkColor
                                                        }}
                                                    >
                                                        {role.userType}
                                                    </div>
                                                </th>
                                            )
                                        })}
                                </tr>
                            </thead>
                            <tbody>
                                {permissions?.map((permission) => {
                                    return (
                                        <tr
                                            className="permRow"
                                            key={permission.id}
                                        >
                                            <td className="permCell sticky-col first-col">{permission.name}</td>
                                            <td className="permCell sticky-col second-col">{permission.description}</td>
                                            {roles?.map((role) => {
                                                return (
                                                  <td
                                                    className="permCell"
                                                    key={role.id}
                                                  >
                                                      <input
                                                        onChange={async (e) => {
                                                            await handlePermissionChange(role, permission, e.target.checked)
                                                        }}
                                                        type="checkbox"
                                                        defaultChecked={role.permissions.some((p => p.name === permission.name))}
                                                      />
                                                  </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <style>
                        {`
                        td {
                            text-align: center !important;
                        }
                        .header-row {
                            position: sticky;
                            top: 0;
                            background-color: white;
                            z-index: 9999;
                        }
                        permTable {
                            border-collapse: collapse;
                            width: 100%;
                        }

                        .permCell, .permHeader {
                            border: 1px solid #dddddd;
                            text-align: left;
                            padding: 8px;
                            word-wrap: break-word;
                        }

                        .permRow:nth-child(even) {
                            background-color: #dddddd;
                        }
                        .view {
                            margin: auto;
                            width: 90vw;
                            padding: 24px;
                        }
                        .permWrapper {
                            overflow: scroll;
                            white-space: nowrap;
                            height: 80vh;
                        }
                        thead th {
                            position: -webkit-sticky; /* for Safari */
                            position: sticky;
                            top: 0;
                        }
                        tbody th {
                            position: -webkit-sticky; /* for Safari */
                            position: sticky;
                            left: 0;
                        }
                        thead th:first-child {
                            left: 0;
                            z-index: 1;
                        }
                        .sticky-col {
                            position: -webkit-sticky;
                            position: sticky;
                            background-color: white;
                        }
                        .first-col {
                            left: 0px;
                            z-index: 9998;
                        }
                        .first-col-header {
                            left: 0px;
                            z-index: 9999;
                        }
                        `}
                    </style>
                </div>
            )}
        </div>
    )
}
