import React, { useState, useMemo, useEffect } from 'react'
import SightlySelect from '@/components/Sightly/SightlyFormElements/SightlySelect'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { FaPlus } from 'react-icons/fa'
import { useCampaigns } from '@/views/TikTok/useTikTok'
import { ConfirmationDialog } from '@/views/Discover/Moments/v2/TargetMoment/ConfirmationDialog'
import { MessageTailwind } from '@/components/MessageTailwind'
import ImportCampaignsModal from '@/views/TikTok/Campaigns/components/ImportCampaignsModal'
import ConnectedAccountsModal from '@/views/TikTok/Campaigns/components/ConnectedAccountsModal'
import SearchBox from '@/components/SearchBox'
import { api } from '@/api/api'
import tikTokLogo from '@/assets/img/tiktoklogo.png'
import useUser from '@/hooks/useUser'
import { ICampaign, INotImportedCampaign } from '@/views/TikTok/Campaigns/types'
import ImportedCampaignsTable from '@/views/TikTok/Campaigns/components/ImportedCampaignsTable'
import NoImportedCampaigns from '@/views/TikTok/Campaigns/components/NoImportedCampaigns'
import { SelectPicker } from 'rsuite'
import activationsPlaceholder from '@/assets/img/activationsPlaceholder.svg'
import { useFlag } from '@unleash/proxy-client-react'

export interface IPlatform {
    name: string
    logo: string
    connectedAccountsCount: number
}

const timeRangeOptions = [
    { id: 1, label: 'Last 24 hrs' },
    { id: 7, label: 'Last 7 days' },
    { id: 30, label: 'Last 30 days' },
    { id: 60, label: 'Last 60 days' }
]

const statusOptions = [
    { id: 'enable', label: 'Active' },
    { id: 'disable', label: 'Inactive', value: 'disable' }
]

export function Campaigns() {
    const [searchInput, setSearchInput] = useState<string>()
    const [searchNotImportedInput, setSearchNotImportedInput] = useState<string>()
    const [selectedTimeRange, setSelectedTimeRange] = useState<number>(7)
    const [selectedStatus, setSelectedStatus] = useState<string>()
    const [importedCampaigns, setImportedCampaigns] = useState<ICampaign[]>([])
    const [importedCampaignsPage, setImportedCampaignsPage] = useState(1)
    const importedCampaignsPageSize = 10
    const [importedCampaignsTotal, setImportedCampaignsTotal] = useState(0)
    const [successfulDelete, setSuccessfulDelete] = useState(false)

    const [campaignToBeDeleted, setCampaignToBeDeleted] = useState<null | number>(null)
    const platform = { name: 'TikTok', logo: tikTokLogo }
    const [tikTokAdvertisersCount, setTikTokAdvertisersCount] = useState(0)

    // Import campaigns modal
    const [failedImport, setFailedImport] = useState(false)
    const [successfulImport, setSuccessfulImport] = useState(false)
    const [selectedPlatform, setSelectedPlatform] = useState<IPlatform>()
    const [notImportedCampaigns, setNotImportedCampaigns] = useState<INotImportedCampaign[]>([])

    const [notImportedCampaignsPage, setNotImportedCampaignsPage] = useState(1)
    const notImportedCampaignsPageSize = 6
    const [notImportedCampaignsTotal, setNotImportedCampaignsTotal] = useState(0)

    const [refreshingCampaigns, setRefreshingCampaigns] = useState(false)
    const [selectedCampaignsToImport, setSelectedCampaignsToImport] = useState<number[]>([])
    const [importingCampaigns, setImportingCampaigns] = useState(false)
    const [importingCampaignsCount, setImportingCampaignsCount] = useState(0)

    const handleImportCampaigns = async () => {
        if (selectedCampaignsToImport.length > 0) {
            resetImportCampaignsModal
            setImportingCampaigns(true)
            try {
                const response = await api.tikTok.importCampaigns(selectedCampaignsToImport, currentAccount?.accountId)
                setImportingCampaigns(false)
                if (response?.status == 200) {
                    setImportingCampaignsCount(selectedCampaignsToImport.length)
                    setSuccessfulImport(true)
                    importedCampaignsQuery.refetch()
                } else {
                    setFailedImport(true)
                }
            } catch (error) {
                setFailedImport(true)
            }
        }
    }

    // Connected accounts modal
    const [showConnectedAccountsModal, setShowConnectedAccountsModal] = useState(false)

    const minCreatedDate = useMemo(() => {
        const today = new Date()
        const date = new Date(today)
        date.setDate(today.getDate() - selectedTimeRange)
        return date
    }, [selectedTimeRange])

    const { currentAccount } = useUser()
    const { importedCampaignsQuery, notImportedCampaignsQuery, advertisersQuery } = useCampaigns(minCreatedDate,
        importedCampaignsPage, importedCampaignsPageSize, notImportedCampaignsPage, notImportedCampaignsPageSize,
        selectedStatus, searchInput, searchNotImportedInput, selectedPlatform, currentAccount?.accountId)

    const handleSearchNotImportedCampaigns = (searchKey: string) => {
        setSearchNotImportedInput(searchKey)
    }

    const resetImportCampaignsModal = () => {
        setSelectedPlatform(undefined)
        setSelectedCampaignsToImport([])
        setNotImportedCampaignsPage(1)
        setSearchNotImportedInput('')
    }

    useEffect(() => {
        if (importedCampaignsQuery.data) {
            setImportedCampaigns(importedCampaignsQuery.data.campaigns)
            setImportedCampaignsPage(importedCampaignsQuery.data.page)
            setImportedCampaignsTotal(Number(importedCampaignsQuery.data.total))
        }
    }, [importedCampaignsQuery])

    useEffect(() => {
        if (notImportedCampaignsQuery.data) {
            setNotImportedCampaigns(notImportedCampaignsQuery.data.campaigns)
            setNotImportedCampaignsPage(notImportedCampaignsQuery.data.page)
            setNotImportedCampaignsTotal(Number(notImportedCampaignsQuery.data.total))
        }
    }, [notImportedCampaignsQuery])

    useEffect(() => {
        if (advertisersQuery.data) {
            setTikTokAdvertisersCount(advertisersQuery.data.data.data.advertisersIds.length)
        }
    }, [advertisersQuery])

    // Delete campaign
    const handleDeleteCampaign = async () => {
        if (!campaignToBeDeleted) return

        const response = await api.tikTok.removeFromImportedCampaigns(campaignToBeDeleted, currentAccount?.accountId)
        setCampaignToBeDeleted(null)
        if (response?.status == 200) {
            setSuccessfulDelete(true)
            importedCampaignsQuery.refetch()
        }
    }

    const handleSearchImportedCampaigns = (searchKey: string) => {
        setSearchInput(searchKey)
    }

    async function handleRefresh(): Promise<void> {
        setRefreshingCampaigns(true)
        try {
            const response = await api.tikTok.refreshCampaignsFromTikTok(currentAccount?.accountId)
            if (response?.status == 200) {
                notImportedCampaignsQuery.refetch()
            } else {
                console.error('Failed to refresh campaigns:')
            }
        } catch (error) {
            console.error('Error refreshing campaigns:', error)
        }
        setRefreshingCampaigns(false)
    }
    const tikTokCampaignFeatureFlag = useFlag(`enable_tiktok_campaign`);
    
    if (tikTokCampaignFeatureFlag) {
    return (
        <div data-testid="activations-campaigns" className="px-14 py-10 bg-white rounded-md mx-auto flex flex-col justify-between">
            <div className="font-bold text-2xl mb-5">
                <div data-testid="activations-campaigns-title" className="flex flex-row font-bold main-title">Campaigns</div>
            </div>
            <div className="h-[60vh]">
                <div
                    data-testid="activations-campaigns-filters"
                    className="rounded-md px-6 py-4 flex justify-between bg-gray-100 w-full"
                >
                    <SearchBox
                        dataTestId="activations-campaigns-search-campaigns"
                        handleSearch={handleSearchImportedCampaigns}
                        placeholder="Search"
                        disabled={!importedCampaigns}
                    />
                    <div className="flex w-fit gap-2">
                        <div data-testid="activations-campaigns-date-dropdown">
                            <SightlySelect
                                placeholder='Date'
                                id="dateDropdown"
                                buttonClass='w-[10rem]'
                                options={timeRangeOptions}
                                labelKey="label"
                                valueKey="id"
                                onChange={setSelectedTimeRange}
                                value={selectedTimeRange}
                            />
                        </div>
                        <div data-testid="activations-campaigns-status-dropdown">
                            <SelectPicker
                                id="operationStatusDropdown"
                                className='w-[8rem]'
                                preventOverflow
                                labelKey={'label'}
                                valueKey={'id'}
                                placeholder={'Status'}
                                data={statusOptions}
                                onChange={setSelectedStatus}
                                searchable={false}
                            />
                        </div>
                        <SightlyButton
                            datatestid='activations-campaigns-zero-results-show-connected-accounts-modal'
                            id="activations-campaigns-zero-results-show-connected-accounts-modal"
                            handleClick={() => setShowConnectedAccountsModal(true)}
                            text=""
                            disabled={notImportedCampaigns && notImportedCampaigns.length < 0}
                            icon={<div className="flex gap-2 items-center">Import campaigns <FaPlus /></div>}
                        />
                    </div>
                </div>
                <div className="w-full h-full">
                    <div data-testid="activations-campaigns-import-campaigns-result-message" className='flex flex-col pt-2 gap-2'>
                        <MessageTailwind
                            handleClose={() => setSuccessfulDelete(false)}
                            show={successfulDelete}
                            message='Campaign successfully deleted'
                            type="success"
                        />
                        <MessageTailwind
                            handleClose={() => setSuccessfulImport(false)}
                            show={successfulImport}
                            message={`${importingCampaignsCount} campaigns successfully imported`}
                            type="success"
                        />
                        <MessageTailwind
                            handleClose={() => setFailedImport(false)}
                            show={failedImport}
                            message="Your import failed, please try again"
                            type="error"
                        />
                        <MessageTailwind
                            show={importingCampaigns}
                            message={`Importing ${importingCampaignsCount} campaigns`}
                            type="loading"
                        />
                    </div>
                    {importedCampaigns && importedCampaigns.length > 0 ? (
                        <ImportedCampaignsTable
                            campaigns={importedCampaigns}
                            setCampaignToBeDeleted={(campaignId: number) => setCampaignToBeDeleted(campaignId)}
                            page={importedCampaignsPage}
                            total={importedCampaignsTotal}
                            pageSize={importedCampaignsPageSize}
                            onChangePage={() => setImportedCampaignsPage(importedCampaignsPage + 1)} />
                    ) : (
                        <NoImportedCampaigns
                            showImportCampaignModal={() => { setShowConnectedAccountsModal(true) }}
                            disabled={notImportedCampaigns.length < 0} />
                    )}
                </div>
            </div>

                <ConfirmationDialog
                    show={campaignToBeDeleted !== null}
                    onHide={() => { setCampaignToBeDeleted(null) }}
                    handleConfirm={handleDeleteCampaign}
                    handleCancel={() => { setCampaignToBeDeleted(null) }}
                    body={'Are you sure you want to remove this campaign?'}
                    action="Delete"
                />

            <ConnectedAccountsModal
                platforms={[{ ...platform, connectedAccountsCount: tikTokAdvertisersCount }]}
                open={showConnectedAccountsModal}
                handleClose={() => setShowConnectedAccountsModal(false)}
                handleSelectPlatform={(platform: IPlatform) => {
                    setShowConnectedAccountsModal(false)
                    setSelectedPlatform(platform)
                }}
            />

            {selectedPlatform && (
                <ImportCampaignsModal
                    platform={selectedPlatform}
                    campaigns={notImportedCampaigns}
                    totalCampaigns={notImportedCampaignsTotal}
                    page={notImportedCampaignsPage}
                    open={selectedPlatform !== null}
                    handleClose={resetImportCampaignsModal}
                    onChangePage={(page: number) => setNotImportedCampaignsPage(page)}
                    handleImportCampaigns={handleImportCampaigns}
                    handleRefresh={handleRefresh}
                    handleSearch={handleSearchNotImportedCampaigns}
                    refreshingCampaigns={refreshingCampaigns}
                    selectedCampaigns={selectedCampaignsToImport}
                    setSelectedCampaigns={campaigns => setSelectedCampaignsToImport(campaigns)}
                />
            )}

            </div>
        )
    } else {
        return (
            <div data-testid="activations-campaigns" className="p-5 mx-5 rounded-md">
                <div data-testid="activations-campaigns-title" className="font-bold main-title">Campaigns</div>
                <img src={activationsPlaceholder} alt="Activations Placeholder" className="absolute bottom-0" />
            </div>
        ) 
    }
}
