import React, { useState } from 'react'
import MomentCard from '@/views/Discover/Moments/v2/MomentCard'
import { MomentSlideOver } from '@/views/Discover/Moments/v2/components/MomentSlideOver/MomentSlideOver'
import { useMoments } from '@/views/Discover/Moments/v2/useMoments'
import useBoards, { Board } from './useBoards'
import Sidebar from './Sidebar'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import AylienBoardPortalModal from '@/views/Discover/Boards/components/AylienBoardPortalModal';
import ActionMessages from '@/views/Discover/Boards/components/ActionMessages';
import toast from 'react-hot-toast'
import { Navigate, useNavigate, useSearch } from '@tanstack/react-location'
import NoBrandProfiles from '@/components/NoBrandProfiles'
import ConfirmationModals from '@/views/Discover/Boards/components/ConfirmationModals';
import { MyLocationGenerics } from '@/classes/utils'
import { location } from '@/RouteContainer'
import { logError } from '@/utils'
import useUser from '@/hooks/useUser'
import { LoadingPage } from '@/components/LoadingPage'
import { PATH_MOMENTS_V2_SETTINGS_REDESIGN } from '@/routes'
import { ClusterActivationType, ClusterType, MomentJustificationOrigin, MomentSortBy } from '@/views/Discover/Moments/v2/types'
import { api, TargetListProps, TargetListPropsV2 } from '@/views/Discover/Moments/v2/api'
import { InfoPage } from '@/components/InfoPage'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import useBrandProfileInsertionOrders from '@/hooks/brandProfile/useBrandProfileInsertionOrders'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { ActionsMenu } from '../Moments/v2/ActionsMenu'
import { ArrowDownTrayIcon, NoSymbolIcon } from '@heroicons/react/20/solid'
import { SortBy } from '../Moments/v2/SortBy'
import { BlockSlideOver } from '../Moments/v2/BlockSlideOver'
import { BoltIcon } from '@heroicons/react/24/outline'
import { TargetSlideOver } from '../Moments/v2/TargetSlideOver'
import { isAxiosError } from '@/utils_ts'
import Tooltip from '@/components/TailwindTooltip'
import { useQueryClient } from '@tanstack/react-query'
import { activationsRqKeys } from '../Activations/activationsRqKeys'
import { rqKeys as MomentViewRqKeys } from "@/views/Discover/Moments/v2/rqKeys";
import { perms } from '@/staticData/permissions';
import { products, userActions } from '@/staticData/products'
import config from '@/config'
import { useFlag } from '@unleash/proxy-client-react'
import BoardTabs from '@/views/Discover/Boards/BoardTabs'
import Conversations from '@/views/Discover/Boards/ConversationsTab/Conversations'

import { useUnleashContext } from '@unleash/proxy-client-react';
import { TargetMoment } from '../Moments/v2/TargetMoment'
import debounce from "just-debounce-it";
import CheckTreePicker from "rsuite/lib/CheckTreePicker";
import SightlySelect from "@/components/Sightly/SightlyFormElements/SightlySelect";
import { SightlyInput } from "@/components/Sightly/SightlyFormElements/SightlyInput";
import { GENERAL_BOARD_DEFAULT_TYPES } from '@/views/BrandProfiles/constants'

import sparklesAISvg from '@/assets/img/AI/SparklesAI.svg';
import clockSvg from '@/assets/img/AI/justification/clockIcon.svg';
import { JustificationSlidingOver } from './components/JustificationSlidingOver'

const timeRangeOptions = [
    { id: 1, label: 'Last 24 hrs' },
    { id: 2, label: 'Last 7 days' },
    { id: 3, label: 'Last 30 days' },
    { id: 4, label: 'Last 60 days' }
]

const actionOptions = [
    { id: 'Target', label: 'Target' },
    { id: 'Monitor', label: 'Monitor' },
    { id: 'Block', label: 'Block' },
    { id: 'No Action', label: 'No Action' }
]

const AylienBoard = () => {

    const updateContext = useUnleashContext();
    updateContext({ userId: localStorage.getItem('email')?.toString() });

    const { userCanService } = useUser()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const boardsObj = useBoards()

    const [actions, setActions] = React.useState(['Target', 'Monitor', 'Block','No Action'])
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

    const [downloadingTrends, setDownloadingTrends] = React.useState(false)
    const [showTooManyTrendsError, setShowTooManyTrendsError] = React.useState(false)

    const [downloadingNarratives, setDownloadingNarratives] = React.useState(false)

    const [isViewingTargetModal, setIsViewingTargetModal] = React.useState(false)
    const [isViewingBlockModal, setIsViewingBlockModal] = React.useState(false)
    const [isViewingMoment, setIsViewingMoment] = React.useState(false)

    const [tryingToBlockWithTarget, setTryingToBlockWithTarget] = React.useState(false)
    const [tryingToBlockWithMonitor, setTryingToBlockWithMonitor] = React.useState(false)
    const [tryingToTargetWithBlock, setTryingToTargetWithBlock] = React.useState(false)
    const [tryingToTargetWithMonitor, setTryingToTargetWithMonitor] = React.useState(false)

    const [isTargeting, setIsTargeting] = React.useState(false)
    const [showTargetSuccess, setShowTargetSuccess] = React.useState(false)
    const [targetError, setTargetError] = React.useState<string>('')

    const [blockingPlacements, setBlockingPlacements] = React.useState<boolean>(false)
    const [showBlockPlacementsSuccess, setShowBlockPlacementsSuccess] = React.useState<boolean>(false)
    const [blockPlacementsError, setBlockPlacementsError] = React.useState<string>('')

    const [blockingKeywords, setBlockingKeywords] = React.useState<boolean>(false)
    const [blockKeywordsError, setBlockKeywordsError] = React.useState<string>('')
    const [showBlockKeywordsSuccess, setShowBlockKeywordsSuccess] = React.useState<boolean>(false)
    const { boardsQuotaTally } = useBoards();
    const isNewActivationManagerEnabled = useFlag(`enable_new_activation_mgr_${config.environment}`)
    const justificationMomentsEnabled = useFlag(`enable_justification_moments_${config.environment}`);

    const {
        data: brandProfiles,
        currentBrandProfile,
        isLoading: brandProfilesIsLoading
    } = useBrandProfiles({
        submittedOnly: true
    })
    const {
        brandProfileId,
        boardIds,
        timeRange = 1,
        sortBy = 'ranking',
        searchTerm = '',
        selectedMomentId
    } = useSearch<MyLocationGenerics>()

    const iosQuery = useBrandProfileInsertionOrders(brandProfileId)

    const handleSearch = debounce((text: string) => {
        navigate({
            search: (prev) => {
                return {
                    ...prev,
                    searchTerm: text
                }
            }
        })
    }, 500)

    const _handleTimeRangeChange = (val: number) => {
        navigate({
            search: (old) => {
                return {
                    ...old,
                    timeRange: val
                }
            }
        })
    }

    const {
        moments,
        fetchNextPage,
        isFetchingNextPage,
        isMomentsError,
        handleSelectMoment,
        momentsIsLoading,
        setSortBy,
        handleSelectAllOnPage,
        selectedMomentIds,
        postTrendRecActionFeedback,
        postTrendKeywordsFeedback,
        aylienNewsOptions,
        aylienIndustriesOptions,
        setAylienNews,
        setAylienIndustries,
        aylienIndustries,
        aylienNews,
        totalResultCount,
        hasNextPage,
        selectedMoments,
        resetSelectedMoments,
        getDefaultSelectedMoments,
        setSelectedMoments,
        invalidateMomentsQuery,
        renderAlert,
    } = useMoments({
        showTargetSuccess,
        brandProfileId,
        boardIds: boardIds || [],
        timeRange,
        searchTerm: searchTerm.toString(),
        sortBy,
        actions
    })

    const selectedMoment = React.useMemo(() => {
        if (!moments) return undefined
        if (!selectedMomentId) return undefined
        const momentSelected = queryClient.getQueryData(MomentViewRqKeys.momentInViewSlideOver(brandProfileId, selectedMomentId))
        return moments?.filter((moment: ClusterType) => moment.clusterId === selectedMomentId)[0] || momentSelected
    }, [selectedMomentId, moments])

    const [boardsAreSorted, setBoardsAreSorted] = React.useState(false);
    const [sortedBoards, setSortedBoards] = React.useState<Board[] | undefined>(undefined);
    const [setshowSlidingModal, setShowSlidingModal] = useState(false);
    const [setFromSlidingModal, setFromShowSlidingModal] = useState('');

    const setDefaultSortByWithOnlyRelevance = () => {
      const selectedBoards = boardsObj.boards?.filter((board) => boardIds?.includes(board.id))
      const areAllBoardsRelevanceType = selectedBoards?.every((board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.RELEVANCE)
      setSortBy(areAllBoardsRelevanceType ? 'relevance' : 'ranking')
    }

    React.useEffect(setDefaultSortByWithOnlyRelevance, [boardsObj.boards, boardIds])

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: (search) => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
        if (boardsObj.boards && boardsObj.boardsFetched && !boardsObj.boardsIsLoading && !boardsObj.boardsIsFetching) {
            // Sort boards in specific order, return sorted boards object, set loading state to prevent FOUC
            const sortedBoards = [...boardsObj.boards]
                .sort((a, b) => {
                    const typeOrder:Record<string, number> = { trendingMoments: 0, general: 1, custom: 2, naturalLanguage: 3 };
                    const { TRENDING_MOMENTS } = GENERAL_BOARD_DEFAULT_TYPES
                    const typeA = a.defaultType === TRENDING_MOMENTS ? TRENDING_MOMENTS : a.type;
                    const typeB = b.defaultType === TRENDING_MOMENTS ? TRENDING_MOMENTS : b.type;
                    if (typeA === typeB) {
                        return a.name.localeCompare(b.name);
                    }
                    return typeOrder[typeA] - typeOrder[typeB];
                });

            setSortedBoards(sortedBoards);
            setBoardsAreSorted(true); // Set loading state to true after sorting

            navigate({
                search: (search) => {
                    const boardIdsForUrl =
                        !Array.isArray(search?.boardIds)
                            ? sortedBoards.map(board => board.id)
                            : Array.isArray(search?.boardIds)
                            ? search?.boardIds.filter((id: number) => sortedBoards.map(board => board.id).includes(id))
                            : []
                    return {
                        ...search,
                        timeRange: search?.timeRange ?? 1,
                        searchTerm: search?.searchTerm ?? '',
                        boardIds: boardIdsForUrl,
                        sortBy: search?.sortBy ?? 'ranking'
                    }
                }
            })
        }
    }, [currentBrandProfile, location.current.search, boardsObj.boards, boardsObj.boardsIsLoading, boardsObj.boardsFetched, boardsObj.boardsIsFetching ])

    const handleDownloadTrendsByFilter = async () => {
        if (brandProfileId === undefined || boardIds === undefined || actions === undefined) return
        setDownloadingTrends(true)
        if (totalResultCount > 1000) {
            setDownloadingTrends(false)
            setShowTooManyTrendsError(true)
            return
        }
        const args = {
            brandProfileId,
            boardIds,
            timeRange,
            actions,
            sortBy,
            searchTerm,
            aylienNews,
            aylienIndustries
        }
        try {
            await api.moments.downloadByFilter(args)
        } catch (err) {
            toast.error('There was an error downloading your moments.')
        } finally {
            setDownloadingTrends(false)
        }
    }

    const handleDownloadTrends = async () => {
        if (brandProfileId === undefined) return
        setDownloadingTrends(true)
        if (selectedMomentIds.length > 1000) {
            setDownloadingTrends(false)
            setShowTooManyTrendsError(true)
            return
        }
        const args = {
            brandProfileId,
            clusterIds: selectedMomentIds
        }
        try {
            await api.moments.download(args)
        } catch (err) {
            toast.error('There was an error downloading your moments.')
            setDownloadingTrends(false)
        }

        setDownloadingTrends(false)
    }

    const hasMonitor = React.useMemo(() => {
        if (!moments) return false
        for (const trend of moments) {
            if (trend.action === 'monitor' && selectedMomentIds.includes(trend.clusterId)) {
                return true
            }
        }
        return false
    }, [moments, selectedMomentIds])

    const hasTarget = React.useMemo(() => {
        if (!moments) return false
        for (const trend of moments) {
            if (trend.action === 'target' && selectedMomentIds.includes(trend.clusterId)) {
                return true
            }
        }
        return false
    }, [moments, selectedMomentIds])

    const hasBlock = React.useMemo(() => {
        if (!moments) return false
        for (const trend of moments) {
            if (trend.action === 'block' && selectedMomentIds.includes(trend.clusterId)) {
                return true
            }
        }
        return false
    }, [moments, selectedMomentIds])

    const handleTarget = async (args: TargetListProps) => {
        setShowTargetSuccess(false)
        setTargetError('')
        setIsTargeting(true)

        try {
            const res = await api.moments.createTargetList(args)

            if (res.status === 200) {
                setShowTargetSuccess(true)
                await queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
                resetSelectedMoments(brandProfileId)
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response?.data === 'No Cids found') {
                    setTargetError('Error: No Cids found')
                    setShowTargetSuccess(false)
                    return
                }
            }

            setTargetError('An error occurred targeting these moments.')
            logError(err, { info: 'error targeting on aylien boards' })
        } finally {
            setIsTargeting(false)
        }
    }

    const handleTargetV2 = async (payload: TargetListPropsV2) => {
        setShowTargetSuccess(false)
        setTargetError('')
        setIsTargeting(true)

        try {
            const res = await api.moments.createTargetListV2(payload)
            if (res.status === 200) {
                setShowTargetSuccess(true)
                await queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
                resetSelectedMoments(brandProfileId)
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response?.data === 'No Cids found') {
                    setTargetError('Error: No Cids found')
                    setShowTargetSuccess(false)
                    return
                }
            }

            setTargetError('An error occurred targeting these moments.')
            logError(err, { info: 'error targeting on aylien boards' })
        } finally {
            setIsTargeting(false)
        }
    }

    const handleBlock = async ({
        keywordClusters,
        placementClusters,
        ioIds
    }: {
        keywordClusters: ClusterActivationType[]
        placementClusters: ClusterActivationType[]
        ioIds: number[]
    }) => {
        if (brandProfileId === null || brandProfileId === undefined) {
            return
        }
        setBlockPlacementsError('')
        setBlockKeywordsError('')
        setShowBlockPlacementsSuccess(false)
        setShowBlockKeywordsSuccess(false)

        if (keywordClusters.length > 0) {
            setBlockingKeywords(true)
            try {
                const keywordResult = await api.moments.createKeywordsBlockList({
                    brandProfileId,
                    clusters: keywordClusters,
                    ioIds
                })
                if (keywordResult?.status === 200 && keywordResult.data === 'No Cids found') {
                    setBlockKeywordsError('Error: No Cids found')
                    setShowBlockKeywordsSuccess(false)
                    return
                }

                if (keywordResult?.status === 200) {
                    setBlockingKeywords(false)
                    setShowBlockKeywordsSuccess(true)
                    setBlockKeywordsError('')
                }
            } catch (err) {
                logError(err, { info: 'error blocking keywords moments list' })
                setBlockKeywordsError('An unknown error occurred blocking keywords.')
                setShowBlockKeywordsSuccess(false)
            } finally {
                resetSelectedMoments(brandProfileId)
                setBlockingKeywords(false)
            }
        }

        if (placementClusters.length > 0) {
            setBlockingPlacements(true)

            try {
                const placementResult = await api.moments.createPlacementsBlockList({
                    brandProfileId,
                    clusters: placementClusters,
                    ioIds
                })

                if (placementResult?.status === 200 && placementResult.data === 'No Cids found') {
                    setBlockPlacementsError('Error: No Cids found')
                    setShowBlockPlacementsSuccess(false)
                    return
                }

                if (placementResult?.status === 200) {
                    setShowBlockPlacementsSuccess(true)
                    setBlockPlacementsError('')
                }
            } catch (err) {
                logError(err, { info: 'error blocking keywords moments list' })
                setBlockPlacementsError('An unknown error occurred blocking videos.')
                setShowBlockPlacementsSuccess(false)
            } finally {
                resetSelectedMoments(brandProfileId)
                setBlockingPlacements(false)
            }
        }
    }

    const allMomentsOnPageAreSelected = React.useMemo(() => {
        if (selectedMomentIds.length === 0) {
            return false
        }

        const notIncludedInSelectedItems = moments
            ?.map((m) => m.clusterId)
            ?.filter((id) => !selectedMomentIds.includes(id))
        if (notIncludedInSelectedItems === undefined) {
            return true
        }
        if (notIncludedInSelectedItems.length > 0) {
            return false
        }
        return true
    }, [moments, selectedMomentIds])

    const resetBrandProfileIdMoments = (brandProfileId:number) => {
      setSelectedTabIndex(0)
      setSelectedMoments(getDefaultSelectedMoments(brandProfileId))
      setShowBlockPlacementsSuccess(false)
      setShowBlockKeywordsSuccess(false)
      setBlockPlacementsError('')
      setBlockKeywordsError('')
      setTargetError('')
      setShowTargetSuccess(false)
    }

    if (brandProfilesIsLoading) {
        return null
    }

    if (brandProfiles?.length === 0) {
        return <NoBrandProfiles />
    }

    if (boardsObj.boardsIsLoading) {
        return <LoadingPage message="Fetching boards" />
    }

    if (
      (
        !boardsObj.boards
        // only navigate if you're on the brand profile that has 0 boards
        // If the account is maxed out on their board quota,
        // and has multiple brand profiles, they should
        // still be able to see the boards of the brand profile that has them
        || (boardsObj.boards.length === 0 && boardsQuotaTally?.isMaxedOut)
      )
      && userCanService(perms.AYLIEN_BOARD_CREATE, products.BOARDS)
    ) {
      return <Navigate to={PATH_MOMENTS_V2_SETTINGS_REDESIGN} />
    }

    const getSelectedBoardNames = () => {
        if (!boardIds || !sortedBoards) return "";

        switch (boardIds.length) {
            case 0:
                return "No board selected";
                break;
            case 1:
                return sortedBoards.filter(b => boardIds && boardIds.includes(b.id)).map(b => b.name).join();
                break;

            default:
                return 'Boards';
                break;
        }
    }

    return (
        <div className="gridContainer animate-in fade-in">

            <JustificationSlidingOver
                open={setshowSlidingModal}
                handleClose={() => setShowSlidingModal(false)}
                header="Mentality AI"
                selectedMoments={selectedMoments}
                brandProfileId={brandProfileId}
                openFrom={setFromSlidingModal}
            />
            {boardsAreSorted && (
                <Sidebar
                    handleBrandProfileChange={(brandProfileId: number) => {
                        navigate({
                            search: (old) => {
                                return {
                                    ...old,
                                    brandProfileId
                                }
                            }
                        })
                        resetBrandProfileIdMoments(brandProfileId)
                    }}
                    resetBrandProfileIdMoments={resetBrandProfileIdMoments}
                    brandProfileId={brandProfileId}
                    handleSelectBoard={boardsObj.handleSelectBoard}
                    handleSelectAllBoards={boardsObj.handleSelectAllBoards}
                    clearSelectedBoards={boardsObj.clearSelectedBoards}
                    boards={sortedBoards || []}
                    showBackToDashboards={(boardsObj?.boards && boardsObj.boards.length > 0) || false}
                    selectedBoards={boardIds || []}
                    handleDeleteBoard={()=>{resetSelectedMoments(brandProfileId)}}
                    hideNewBoardButton={!userCanService(perms.AYLIEN_BOARD_CREATE, products.BOARDS, userActions.CREATE)}
                />
            )}

            {selectedTabIndex === 0 ? (
              <>
                  <div className="header-aylien">
                      <div className="w-full">
                          <BoardTabs
                              fixedHeadline={getSelectedBoardNames()}
                              selectedTabIndex={selectedTabIndex}
                              setSelectedTabIndex={setSelectedTabIndex}
                          />
                          {/*<Filters*/}
                          <div data-testid="anticipation-boards-filters-panel" className='flex items-center justify-between py-4 bg-gray-100 w-100'>
                              <div className="flex gap-4 px-6">
                                  <div data-testid="anticipation-boards-filters-panel-actionDropdown">
                                      <CheckTreePicker
                                          id="actionDropdown"
                                          style={{ width: 162 }}
                                          preventOverflow
                                          data={actionOptions}
                                          labelKey="label"
                                          valueKey="id"
                                          onChange={(_actions) => _actions.length > 0 && setActions(_actions)}
                                          value={actions}
                                          cleanable={false}
                                      />
                                  </div>
                                  <div data-testid="anticipation-boards-filters-panel-dateDropdown">
                                      <SightlySelect
                                          id="dateDropdown"
                                          width={162}
                                          options={timeRangeOptions}
                                          buttonClass="pendo-date-dropdown-select"
                                          labelKey="label"
                                          valueKey="id"
                                          onChange={_handleTimeRangeChange}
                                          value={timeRange} // as number}
                                      />
                                  </div>
                                  <div data-testid="anticipation-boards-filters-panel-news-filter">
                                      <CheckTreePicker
                                          id="pendo-aylien-news-filter"
                                          style={{ width: 162 }}
                                          menuStyle={{ maxWidth: '90vw' }}
                                          preventOverflow
                                          data={aylienNewsOptions}
                                          labelKey="taxonomyPath"
                                          valueKey="taxonomyCode"
                                          placeholder="News"
                                          onChange={setAylienNews}
                                          value={aylienNews}
                                      />
                                  </div>
                                  <div data-testid="anticipation-boards-filters-panel-industries-filter">
                                      <CheckTreePicker
                                          id="pendo-aylien-industry-filter"
                                          style={{ width: 162 }}
                                          menuStyle={{ maxWidth: '90vw' }}
                                          preventOverflow
                                          data={aylienIndustriesOptions}
                                          labelKey="taxonomyPath"
                                          valueKey="taxonomyCode"
                                          placeholder="Industries"
                                          onChange={setAylienIndustries}
                                          value={aylienIndustries}
                                      />
                                  </div>
                              </div>


                              <div
                                  data-testid="anticipation-boards-search-box"
                                  className="px-6"
                              >
                                  <SightlyInput
                                      id="searchMoments"
                                      defaultValue={searchTerm}
                                      placeholder="Search moments"
                                      onChange={(val) => handleSearch(val)}
                                      hasSearchIcon
                                      width={240}
                                  />
                              </div>
                          </div>
                          {/*<Actions*/}
                          <div
                              data-testid="anticipation-boards-action-buttons"
                              className="flex items-center justify-start w-full gap-4 px-6 my-4"
                          >
                              <div
                                  data-testid="anticipation-boards-action-buttons-selectAllCheckbox"
                                  className="mr-2"
                              >
                                  <SightlyCheckbox
                                      data-testid="select-all-moments-checkbox"
                                      id="selectAllCheckbox"
                                      indeterminate={selectedMomentIds.length > 0 && !allMomentsOnPageAreSelected}
                                      checked={allMomentsOnPageAreSelected}
                                      handleChange={(checked: boolean) => {
                                          handleSelectAllOnPage(checked)
                                      }}
                                      label={
                                          selectedMomentIds.length > 0 ? (
                                              <div className="text-xs text-sightly-slate">
                                                  {selectedMomentIds.length} item(s) selected
                                              </div>
                                          ) : (
                                              ''
                                          )
                                      }
                                  />
                              </div>

                              {userCanService(perms.TRENDS_DOWNLOAD, products.BOARDS) && (
                                  <div data-testid="anticipation-boards-action-buttons-download">
                                      <ActionsMenu
                                          buttonId="download"
                                          buttonText="Download"
                                          buttonIcon={
                                              <ArrowDownTrayIcon
                                                  className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                                  aria-hidden="true"
                                              />
                                          }
                                          options={[
                                              {
                                                  disabled:
                                                      selectedMomentIds.length < 1 || totalResultCount === 0 || momentsIsLoading,
                                                  name: 'Download selected moments',
                                                  description: 'Download all of the selected moments and their meta data',
                                                  onClick: handleDownloadTrends,
                                                  icon: (
                                                      <ArrowDownTrayIcon
                                                          className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                          aria-hidden="true"
                                                      />
                                                  )
                                              },
                                              {
                                                  disabled: totalResultCount < 1 || momentsIsLoading,
                                                  name: 'Download all moments',
                                                  description:
                                                      'Download all of the moments and their meta data, according to your filters',
                                                  onClick: handleDownloadTrendsByFilter,
                                                  icon: (
                                                      <ArrowDownTrayIcon
                                                          className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                          aria-hidden="true"
                                                      />
                                                  )
                                              }
                                          ]}
                                          selectorClass="pendo-download-button-selector"
                                      />
                                  </div>
                              )}

                              <Tooltip
                                  content="To block moments, please select a moment first"
                                  disabled={selectedMomentIds.length > 0}
                              >
                                  <div data-testid="anticipation-boards-action-buttons-block">
                                      <SightlyButton
                                          id="blockButton"
                                          disabled={
                                              selectedMomentIds.length < 1 ||
                                              totalResultCount === 0 ||
                                              momentsIsLoading ||
                                              (iosQuery.data && iosQuery.data.length < 1)
                                          }
                                          handleClick={() => {
                                            hasTarget
                                              ? setTryingToBlockWithTarget(true)
                                              : hasMonitor
                                                ? setTryingToBlockWithMonitor(true)
                                                : setIsViewingBlockModal(true);
                                          }}
                                          text="Block"
                                          type="secondaryblack"
                                          icon={
                                              <NoSymbolIcon
                                                  className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                                  aria-hidden="true"
                                              />
                                          }
                                      />
                                  </div>
                              </Tooltip>

                              {userCanService(perms.TRENDS_TARGET, products.BOARDS) &&
                                <Tooltip
                                  content="To target moments, please select a moment first"
                                  disabled={selectedMomentIds.length > 0}
                                >
                                  <div data-testid="anticipation-boards-action-buttons-target">
                                    <SightlyButton
                                      id="targetButton"
                                      disabled={
                                          (selectedMomentIds.length <= 0 ) &&
                                          ((boardIds && boardIds?.length > 0) || totalResultCount === 0  ||
                                              momentsIsLoading ||
                                              (iosQuery.data && iosQuery.data.length < 1))
                                      }
                                      handleClick={() => {
                                        hasMonitor
                                            ? setTryingToTargetWithMonitor(true)
                                            : hasBlock
                                                ? setTryingToTargetWithBlock(true)
                                                : setIsViewingTargetModal(true);
                                    }}
                                      text="Target"
                                      type="secondaryblack"
                                      icon={
                                          <BoltIcon
                                              className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                              aria-hidden="true"
                                          />
                                      }
                                    />
                                  </div>
                                </Tooltip>}

                                {  justificationMomentsEnabled && <Tooltip
                                    content="To analyze moments, please select a moment first"
                                    disabled={selectedMomentIds.length > 0}
                                >
                                    <div data-testid="anticipation-boards-action-buttons-ai-analyze">
                                    <SightlyButton
                                        id="aiAnalyzeButton"
                                        handleClick={() => {
                                            setFromShowSlidingModal(MomentJustificationOrigin.Analyze)
                                            setShowSlidingModal(true)
                                            }
                                        }
                                        text="Analyze"
                                        disabled={selectedMomentIds.length == 0}
                                        type="gradient-ai"
                                        icon={
                                            <img data-testid={'ai-analyze-image'} src={sparklesAISvg} className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"/>
                                        }
                                    />
                                    </div>
                                </Tooltip>}

                                <div className='w-[1px] h-9 bg-[#E5E7EB]' />

                                { justificationMomentsEnabled &&
                                    <div data-testid="anticipation-boards-action-buttons-ai-history">
                                        <SightlyButton
                                            id="aiHistoryButton"
                                            handleClick={() => {
                                                setFromShowSlidingModal(MomentJustificationOrigin.History)
                                                setShowSlidingModal(true)
                                                }
                                            }
                                            text=""
                                            type="gradient-ai"
                                            icon={
                                                <img data-testid={'ai-history-image'} src={clockSvg} className="h-5 w-5 text-grey-900"/>
                                            }
                                        />
                                    </div>
                                }

                    <div className="flex ml-auto">
                        {!momentsIsLoading && moments && moments.length > 0 && (
                            <div
                                data-testid="showing-moments-count"
                                className="flex flex-col justify-center mr-5 text-xs text-sightly-slate"
                            >
                                {`Showing ${moments.length} of ${totalResultCount}`}
                            </div>
                        )}
                        <div>
                            <SortBy
                                selected={sortBy}
                                onChange={(val: MomentSortBy) => setSortBy(val)}
                                buttonClass='pendo-moments-sort-by-button'
                            />
                        </div>
                    </div>
                          </div>

                      <ActionMessages
                        downloadingTrends={downloadingTrends}
                        iosQuery={iosQuery}
                        brandProfileId={brandProfileId}
                        showTargetSuccess={showTargetSuccess}
                        setShowTargetSuccess={setShowTargetSuccess}
                        isTargeting={isTargeting}
                        targetError={targetError}
                        setTargetError={setTargetError}
                        showBlockKeywordsSuccess={showBlockKeywordsSuccess}
                        setShowBlockKeywordsSuccess={setShowBlockKeywordsSuccess}
                        showBlockPlacementsSuccess={showBlockPlacementsSuccess}
                        setShowBlockPlacementsSuccess={setShowBlockPlacementsSuccess}
                        blockingKeywords={blockingKeywords}
                        blockingPlacements={blockingPlacements}
                        blockPlacementsError={blockPlacementsError}
                        setBlockPlacementsError={setBlockPlacementsError}
                        blockKeywordsError={blockKeywordsError}
                        setBlockKeywordsError={setBlockKeywordsError}
                      />
                    </div>
                  </div>

                  {/* Moments*/}
                  <div className="px-4 bg-gray-100 main">
                      {isMomentsError ? (
                          <InfoPage message="There was an unknown error fetching Moments. Please refresh the page to try again. If the problem persists, please contact support." />
                      ) : !boardsObj.boards || boardsObj.boards.length === 0 ? (
                          <InfoPage message="We currently don’t have any boards created and processed for this Brand Profile." />
                      ) : boardIds?.length === 0 && userCanService(perms.AYLIEN_BOARD_CREATE, products.BOARDS) ? (
                          <InfoPage message="Please select a board" />
                      ) : momentsIsLoading ? (
                          <LoadingPage message={'Fetching moments'} />
                      ) : !momentsIsLoading && moments && moments.length < 1 ? (
                          <InfoPage message="There are no moments for your filters" />
                      ) : (
                          <div className="w-full">
                              {moments?.map((moment: ClusterType) => {
                                  return (
                                      <MomentCard
                                          key={moment.clusterId}
                                          trendingMoments = {boardsObj.boards?.find((board:Board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS)}
                                          momentsIsLoading={momentsIsLoading}
                                          checked={selectedMomentIds.includes(moment.clusterId)}
                                          handleSelectMoment={handleSelectMoment}
                                          brandProfileId={brandProfileId}
                                          moment={moment}
                                          handleTrendNameClick={(moment: ClusterType) => {
                                              navigate({
                                                  search: (search) => ({
                                                      ...search,
                                                      selectedMomentId: moment.clusterId
                                                  })
                                              })
                                              setIsViewingMoment(true)
                                          }}
                                      />
                                  )
                              })}

                              {hasNextPage && (
                                  <div className="flex justify-center w-full py-4">
                                      <SightlyButton
                                          datatestid="load-more-moments-button"
                                          block
                                          id="loadmorebutton"
                                          text="Load More"
                                          handleClick={fetchNextPage}
                                          loading={isFetchingNextPage}
                                          disabled={isFetchingNextPage}
                                      />
                                  </div>
                              )}
                          </div>
                      )}
                  </div>
              </>
            ) : (
                <>
                    <div className="header-aylien">
                        <div className="w-full">
                            <BoardTabs
                                fixedHeadline={getSelectedBoardNames()}
                                selectedTabIndex={selectedTabIndex}
                                setSelectedTabIndex={setSelectedTabIndex}
                            />
                        </div>
                    </div>

                    <div className="px-4 main">
                        <Conversations/>
                    </div>
                </>
            )}
            {renderAlert()}

            <AylienBoardPortalModal showTooManyTrendsError={showTooManyTrendsError} setShowTooManyTrendsError={setShowTooManyTrendsError}/>

            <BlockSlideOver
                open={isViewingBlockModal}
                setOpen={setIsViewingBlockModal}
                selectedMoments={selectedMoments}
                handleBlock={handleBlock}
                handleSelectMoment={handleSelectMoment}
            />

            { isNewActivationManagerEnabled ?
            (isViewingTargetModal && <TargetMoment
                open={isViewingTargetModal}
                trendingMoments = {boardsObj.boards?.find((board:Board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS)}
                setOpen={setIsViewingTargetModal}
                selectedMoments={selectedMoments}
                handleTarget={handleTargetV2}
                handleSelectMoment={handleSelectMoment}
            />) :
            <TargetSlideOver
                open={isViewingTargetModal}
                setOpen={setIsViewingTargetModal}
                selectedMoments={selectedMoments}
                handleTarget={handleTarget}
                handleSelectMoment={handleSelectMoment}
            />}

            {brandProfileId && selectedMoment && (
                <MomentSlideOver
                    show={isViewingMoment}
                    close={setIsViewingMoment}
                    moment={selectedMoment}
                    brandProfileId={brandProfileId}
                    postTrendRecActionFeedback={postTrendRecActionFeedback}
                    postTrendKeywordsFeedback={postTrendKeywordsFeedback}
                    invalidateMomentsQuery={invalidateMomentsQuery}
                />
            )}

            <ConfirmationModals tryingToBlockWithTarget={tryingToBlockWithTarget}
                setTryingToBlockWithTarget={setTryingToBlockWithTarget}
                setIsViewingBlockModal={setIsViewingBlockModal}
                tryingToBlockWithMonitor={tryingToBlockWithMonitor}
                setTryingToBlockWithMonitor={setTryingToBlockWithMonitor}
                tryingToTargetWithBlock={tryingToTargetWithBlock}
                setTryingToTargetWithBlock={setTryingToTargetWithBlock}
                setIsViewingTargetModal={setIsViewingTargetModal}
                tryingToTargetWithMonitor={tryingToTargetWithMonitor}
                setTryingToTargetWithMonitor={setTryingToTargetWithMonitor}
            />

            <style>{`
            .gridContainer {
              display: grid;
              height: 100%;	
              grid-template-columns: 345px 1fr;	
              grid-template-rows:  0fr 0px 1fr 1fr;
              grid-template-areas:
                "sidebar header-aylien"
                "sidebar action-bar"
                "sidebar main"
                "sidebar main"	
                "sidebar footer-aylien"	
            }
           
            .header-aylien {
                grid-area: header-aylien;		
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-bottom: 2px solid #f8f8f8;
            }
            .main {
                grid-area: main;
                overflow-y: auto;
                justify-content: center;
                display: flex;
                height: 100%;          
            }  
                       
          `}</style>
        </div>
    )
}

export default AylienBoard;
