import React from 'react';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { ProgressBar} from "@/components/ProgressBar";
import { UsersIcon } from "@heroicons/react/20/solid";
import { AccountValues } from "@/schemas/schemas_ts";
import { getSubscriptionValues } from "./helpers";

type ManageSubscriptionProps = {
  currentAccount: AccountValues | undefined
}

export const ManageSubscription = ({ currentAccount }: ManageSubscriptionProps) => {
  const { percentage, text } = getSubscriptionValues(currentAccount)

  return (
    <div
      data-testid="manage-subscription"
      className="flex items-center justify-between border h-24 bg-white rounded-md"
    >
      <div className="mx-6 grid gap-2 flex-1 ">
        <div className="font-semibold text-base">Manage Your Subscription</div>
        <ProgressBar
          percentage={percentage}
          text={text}
        />
      </div>
      <div className="mx-4">
        <SightlyButton
          datatestid="button-contact-us"
          handleClick={() => {
            window.open('https://brandmentality.ai/contact/', '_blank', 'noopener,noreferrer');
          }}
          icon={<UsersIcon className="-ml-0.5 mr-2 h-5"/>}
          text={"Contact Us"}
        />
      </div>
    </div>
  )
}
