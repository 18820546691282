import { useMatch } from '@tanstack/react-location';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { MyLocationGenerics } from '@/classes/utils';
import { LoadingPage } from '@/components/LoadingPage';
import { logError } from '@/utils';
import { scenariosApi } from '@/views/BrandProfiles/BrandProfile/components/ScenariosV2/scenariosV2-api';
import { rqKeysScenarioV2 } from '@/views/BrandProfiles/BrandProfile/components/ScenariosV2/scenariosV2-rqKeys';
import { ScenarioThemeType } from '@/views/BrandProfiles/BrandProfile/components/ScenariosV2/scenariosV2-types';
import { ScenarioThemeCard } from '@/views/BrandProfiles/BrandProfile/components/ScenariosV2/ScenarioThemeCard';
import { SlideOver } from '@/views/BrandProfiles/BrandProfile/components/ScenariosV2/SlideOver/SlideOver';
import { SortBy } from '@/views/BrandProfiles/BrandProfile/components/ScenariosV2/SortBy';
import config from '@/config';
import { SCENARIOS_LABELS } from '@/views/BrandProfiles/constants';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';

export const ScenariosV2 = () => {
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()

    const [sortBy, setSortBy] = React.useState<'least' | 'most'>('least')

    const scenariosQuery = useQuery(
        rqKeysScenarioV2.scenarioThemes(Number(brandProfileId), sortBy),
        () => scenariosApi.getV2Scenarios({ brandProfileId: Number(brandProfileId), sortBy }),
        {
            onError: (err) => {
                logError(err)
            }
        }
    )
    const [selectedScenarioThemeId, setSelectedScenarioThemeId] = React.useState<Number | null>(null)
    const selectedScenarioTheme = React.useMemo(() => {
        if (!selectedScenarioThemeId || !scenariosQuery.data) return null
        const selectedItemArray = scenariosQuery.data.filter(
            (scenTheme: ScenarioThemeType) => scenTheme.scenarioThemeId === selectedScenarioThemeId
        )
        if (!selectedItemArray) return null
        return selectedItemArray[0]
    }, [selectedScenarioThemeId])

    const { steps,
        _steps,
        getCurrentStep,
        activeStep,
        handleStepsClick,
        handleContinueClick,
        getCurrentProgress,
        handlePreviousClick } = useSteps();

    if (scenariosQuery.isLoading) {
        return <LoadingPage message="Fetching Scenarios" />
    }

    if (config.featureFlag.aiProfiles) {
        return (
            <div className="flex justify-center w-full bg-gray-100 animate-in fade-in ">
            <SlideOver
                open={!!selectedScenarioThemeId}
                onClose={() => setSelectedScenarioThemeId(null)}
                scenarioTheme={selectedScenarioTheme}
                sortBy={sortBy}
            />
            <div className="m-2">
                <div className="w-full text-xl font-bold text-sightlySlate">
                    {SCENARIOS_LABELS.MAIN_TITLE}
                </div>

                <div className="font-semibold text-sm text-[#898989] mt-3">
                    {SCENARIOS_LABELS.MAIN_DESCRIPTION}
                </div>
                <div className='flex items-center justify-end w-full pr-3 mt-2 mb-5'>
                    <div className="flex items-center gap-3 p-1 rounded hover:cursor-pointer hover:bg-slate-100">
                        <SortBy
                            selected={sortBy}
                            onChange={(val: 'least' | 'most') => setSortBy(val)}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap gap-4">
                    {scenariosQuery?.data &&
                        scenariosQuery.data.map((scenarioTheme: ScenarioThemeType) => {
                            return (
                                <ScenarioThemeCard
                                    key={scenarioTheme.scenarioThemeId}
                                    handleContinueClick={() =>
                                        setSelectedScenarioThemeId(scenarioTheme.scenarioThemeId)
                                    }
                                    scenarioTheme={scenarioTheme}
                                />
                            )
                        })}
                </div>
            </div>
            <BrandProfileFooter activeStep={activeStep}
              disabled={false}
              handlePrevious={handlePreviousClick}
              handleContinue={handleContinueClick}
            />
        </div>
        )
    }
    return (
        <div
            className="animate-in fade-in"
            style={{ display: 'flex', justifyContent: 'center' }}
        >
            <SlideOver
                open={!!selectedScenarioThemeId}
                onClose={() => setSelectedScenarioThemeId(null)}
                scenarioTheme={selectedScenarioTheme}
                sortBy={sortBy}
            />
            <div style={{ maxWidth: 1014, marginLeft: 8 }}>
                <div style={{ fontWeight: 700, fontSize: 20, color: '#333D47', width: '100%', lineHeight: '32px' }}>
                    Create winning campaigns
                </div>

                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: '22px',
                        color: '#898989',
                        marginTop: 12
                    }}
                >
                    Tell us how your brand would like to approach the following emerging news and scenarios
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',

                        marginTop: 10,
                        marginBottom: 22,
                        width: '100%',
                        justifyContent: 'end',
                        paddingRight: 14
                    }}
                >
                    <div className="flex items-center gap-3 p-1 rounded hover:cursor-pointer hover:bg-slate-100">
                        <SortBy
                            selected={sortBy}
                            onChange={(val: 'least' | 'most') => setSortBy(val)}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap gap-6">
                    {scenariosQuery?.data &&
                        scenariosQuery.data.map((scenarioTheme: ScenarioThemeType) => {
                            return (
                                <ScenarioThemeCard
                                    key={scenarioTheme.scenarioThemeId}
                                    handleContinueClick={() =>
                                        setSelectedScenarioThemeId(scenarioTheme.scenarioThemeId)
                                    }
                                    scenarioTheme={scenarioTheme}
                                />
                            )
                        })}
                </div>
            </div>
            <BrandProfileFooter activeStep={activeStep}
              disabled={false}
              handlePrevious={handlePreviousClick}
              handleContinue={handleContinueClick}
            />
        </div>
    )
}
