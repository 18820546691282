import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Panel from 'rsuite/lib/Panel';
import { ReactComponent as DropdownArrowRight } from '@/assets/img/dropdownRight.svg';
import { routes } from '@/routes';
import CreatableTagPicker from '@/components/CreatableTagPicker';
import toast from 'react-hot-toast';
import TagPicker from 'rsuite/lib/TagPicker';
import useBrandProfileOpinionTypes from '@/hooks/brandProfile/useBrandProfileOpinionTypes';
import { aylienAdminVal, brandTopicObjValidation } from '@/schemas/schemas';
import { iabCategoriesFilter } from '@/staticData/iabCategories';
import { userAccountAxios } from '@/axiosInstances';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle';
import EditableField from '@/components/EditableField';
import { isAxiosError } from '@/utils_ts';

interface Opinion {
    opinionId: number
    question: string
    archived: boolean
    description: string
    linkedKeywords: string[]
    opinionTypeIds: number[]
    linkedTopics: number[]
    linkedIabCategories: number[]
    linkedAylienNews: number[]
    linkedAylienIndustries: number[]
}

const flatten = (arr: any, id: any, final: any = []) => {
    for (let item of arr) {
        if (item.children) {
            flatten(item.children, id, final)
        }
        if (!final.find((element: any) => element[id] === item[id])) {
            const { children, ...itemWithoutChildren } = item
            final.push(itemWithoutChildren)
        }
    }
    return final
}

const fetchAdminTopics = async () => {
    let url = `/brand-profile/topics`

    try {
        const result = await userAccountAxios.get(url)
        if (result.status === 200) {
            let topics = result.data

            brandTopicObjValidation.validate(topics).catch(function (err) {
                console.log(err.name, err.errors)
                console.error('We received different API data than expected, see the console log for more details.')
            })

            return flatten(result.data, 'topicId')
        }
    } catch (error) {
        console.error(error)
    }
}

const fetchIabCategories = async () => {
    return flatten(iabCategoriesFilter, 'id')
}

const getAdminOpinion = async (opinionId: number): Promise<Opinion> => {
    const url = `/opinions/${opinionId}`
    const res = await userAccountAxios.get(url)
    return res.data
}

export const fetchAdminAylienNews = async () => {
    const url = `/aylien-news`
    const result = await userAccountAxios.get(url)

    aylienAdminVal.validate(result.data).catch(function (err) {
        console.log(err.name, err.errors)
        console.error('We received different API data than expected, see the console log for more details.')
    })

    return result.data
}
const fetchAdminAylienIndustry = async () => {
    let url = `/aylien-industries`
    const result = await userAccountAxios.get(url)

    aylienAdminVal.validate(result.data).catch(function (err) {
        console.log(err.name, err.errors)
        console.error('We received different API data than expected, see the console log for more details.')
    })

    return result.data
}

const OpinionEdit = (props: any) => {
    const {
        params: { opinionId }
    } = useMatch()
    const opinionQueryKey = rqKeys.opinionQueryKey(opinionId)
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { data: opinion, isLoading: opinionIsLoading } = useQuery(
        opinionQueryKey,
        () => getAdminOpinion(Number(opinionId)),
        {
            enabled: !!opinionId
        }
    )
    const { data: adminTopics } = useQuery(rqKeys.adminTopics(), fetchAdminTopics)
    const { data: iabCategories } = useQuery(rqKeys.adminIabCatsStatic(), fetchIabCategories)
    const { data: aylienNews } = useQuery(rqKeys.adminAylienNews(), fetchAdminAylienNews)
    const { data: aylienIndustry } = useQuery(rqKeys.adminAylienInd(), fetchAdminAylienIndustry)
    const typesObj = useBrandProfileOpinionTypes({ excludeArchived: true })

    // Update opinion
    const opinionMutation = useMutation<string, unknown, string, unknown>(
        (newQuestion) => userAccountAxios.patch(`/opinions/${opinionId}/question`, { question: newQuestion }),
        {
        onSuccess: () => {
            queryClient.invalidateQueries(opinionQueryKey);
            toast.success('Changes saved');
        },
        onError: (err) => {
            if (isAxiosError<any>(err)) {
            // Access to config, request, and response
            const isDuplicateNameError = err?.response?.data.includes('already exists')
            const isEmptyString = err?.response?.data.includes('at least one character')
            if (isDuplicateNameError) {
                toast.error('That name is already used, please try another name')
            }
            else if (isEmptyString) {
                toast.error('Must be at least one character')
            }
            else {
                toast.error('Unknown error occurred')
                console.error(err) // Log the error
            }
            } else {
            toast.error('Unknown error occurred')
            console.error(err) // Log the error
            }
        }
        }
    );
    
    const updateQuestion = (newQuestion: string) => {
        opinionMutation.mutate(newQuestion);
    };

      // Update opinion description
      const opinionDescriptionMutation = useMutation<string, unknown, string, unknown>(
        (newQuestionDescription) => userAccountAxios.patch(`/opinions/${opinionId}/questionDescription`, { questionDescription: newQuestionDescription }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(opinionQueryKey);
                toast.success('Changes saved');
            },
            onError: (err) => {
                if (isAxiosError<any>(err)) {
                // Access to config, request, and response
                const exceedsMaximumLength = err?.response?.data.includes('Maximum length')
                if (exceedsMaximumLength) {
                    toast.error('Description exceeds maximum length')
                }
                else {
                    toast.error('Unknown error occurred')
                    console.error(err) // Log the error
                }
                } else {
                toast.error('Unknown error occurred')
                console.error(err) // Log the error
                }
            }
            }
        );
      
      const updateQuestionDescription = (newQuestionDescription: string) => {
        opinionDescriptionMutation.mutate(newQuestionDescription);
      };

    const handleArchivedChanged = async (archived: boolean) => {
        if (!opinion) return
        if (archived === true) {
            await archiveOpinion(opinion?.opinionId)
        } else {
            await unarchiveOpinion(opinion?.opinionId)
        }
        queryClient.invalidateQueries(opinionQueryKey)
    }

    const mapOpinionToKeywords = (opinionId: number, keywords: string[]) => {
        const url = `/opinions/${opinionId}/keywords`

        userAccountAxios
            .patch(url, keywords)
            .then((response) => {
                queryClient.invalidateQueries(opinionQueryKey)
                toast.success('Changes saved')
            })
            .catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
            })
    }

    const mapOpinionToOpinionTypes = (opinionId: number, opinionTypes: number[]) => {
        if (opinionTypes.length === 0) {
            alert('Unable to process. An opinion must belong to at least one opinion type.')
            window.location.reload()
            return
        }
        const url = `/opinions/${opinionId}/opinion-type`

        userAccountAxios
            .patch(url, opinionTypes)
            .then((response) => {
                queryClient.invalidateQueries(opinionQueryKey)
                toast.success('Changes saved')
            })
            .catch((error) => {
                alert(
                    'We were unable to perform this map due to: ' +
                        error?.response?.data?.error +
                        '. Click ok to reload the page.'
                )
                window.location.reload()
            })
    }

    const mapOpinionToTopics = (opinionId: number, topicIds: number[]) => {
        const url = `/opinions/${opinionId}/topics`

        userAccountAxios
            .patch(url, topicIds)
            .then((response) => {
                queryClient.invalidateQueries(opinionQueryKey)
                toast.success('Changes saved')
            })
            .catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
            })
    }

    const mapOpinionToIabCategories = (opinionId: number, iabCategoryIds: number[]) => {
        const url = `/opinions/${opinionId}/iab_categories`

        userAccountAxios
            .patch(url, iabCategoryIds)
            .then((response) => {
                queryClient.invalidateQueries(opinionQueryKey)
                toast.success('Changes saved')
            })
            .catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
            })
    }

    const mapOpinionToAylienNews = async (opinionId: number, ids: number[]) => {
        let url = `/opinions/${opinionId}/aylien-news`
        await userAccountAxios.patch(url, ids)
        queryClient.invalidateQueries(opinionQueryKey)
        toast.success('Changes saved')
    }

    const mapOpinionToAylienIndustries = async (opinionId: number, ids: number[]) => {
        let url = `/opinions/${opinionId}/aylien-industries`
        await userAccountAxios.patch(url, ids)
        queryClient.invalidateQueries(opinionQueryKey)
        toast.success('Changes saved')
    }

    const archiveOpinion = async (opinionId: number) => {
        let url = `/opinions/${opinionId}`

        await userAccountAxios
            .delete(url)
            .then((response) => {
                queryClient.invalidateQueries(opinionQueryKey)
                toast.success('Changes saved')
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const unarchiveOpinion = async (opinionId: number) => {
        const url = `/opinions/${opinionId}/unarchive`
        await userAccountAxios
            .patch(url)
            .then((response) => {
                queryClient.invalidateQueries(opinionQueryKey)
                toast.success('Changes saved')
            })
            .catch((error) => {
                console.error(error)
            })
    }    

    const noThing = ('');

    if (!opinion || opinionIsLoading) {
        return <LoadingPage message="Fetching Opinion" />
    }

    return (
        <div style={{ padding: 24 }}>
            <div
                onClick={() => navigate({ to: routes.admin.opinions.path })}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 21,
                    justifyContent: 'left',
                    paddingLeft: 18,
                    width: '100%',
                    marginBottom: 24
                }}
            >
                <DropdownArrowRight style={{ transform: 'rotate(180deg)' }} />
                <div style={{ fontWeight: 700, fontSize: 18, color: '#333D47' }}>Back to Opinions</div>
            </div>

            <Panel>
                <div className="mb-8">
                    <EditableField
                    label="Question:" 
                    initialValue={opinion?.question} 
                    type="text"
                    textSize='text-2xl'
                    width='w-1/2'
                    onUpdate={updateQuestion} 
                    />
                </div>
                <div className="mb-2 mt-7">
                    <EditableField
                    label="Description:" 
                    initialValue={opinion?.description} 
                    type="text"
                    textSize='text-2xl'
                    width='w-1/2'
                    maxLength={255}
                    onUpdate={updateQuestionDescription} 
                    />
                </div>
                <div className="mb-10 mt-7">
                <SightlyToggle
                    id="archived"
                    setEnabled={handleArchivedChanged}
                    enabled={opinion.archived}
                    enabledText="Archived"
                    disabledText="Active"
                    isDisabled={opinion.archived}
                />
                </div>

                <h4 className="mt-6">Keywords</h4>
                <CreatableTagPicker
                    id="opinionsAdminTagPicker"
                    backgroundColor="#F7F7FA"
                    disabled={false}
                    textColor="black"
                    items={opinion?.linkedKeywords ? opinion.linkedKeywords : []}
                    saveChanges={(keywords: string[]) => {
                        mapOpinionToKeywords(opinion?.opinionId, keywords)
                    }}
                />

                <h4 style={{ marginTop: 48 }}>Opinion Types</h4>
                <TagPicker
                    preventOverflow
                    id="opinionTypes"
                    cleanable={false}
                    data={typesObj.opinionTypes}
                    labelKey="typeName"
                    valueKey="typeId"
                    defaultValue={opinion?.opinionTypeIds}
                    onChange={(v, e) => {
                        mapOpinionToOpinionTypes(opinion.opinionId, v)
                    }}
                />

                <h4 style={{ marginTop: 48 }}>Topics</h4>
                <TagPicker
                    preventOverflow
                    id="topics"
                    cleanable={false}
                    data={adminTopics}
                    labelKey="topicName"
                    valueKey="topicId"
                    defaultValue={opinion?.linkedTopics}
                    onChange={(v, e) => {
                        mapOpinionToTopics(opinion.opinionId, v)
                    }}
                />

                <h4 style={{ marginTop: 48 }}>IAB Categories</h4>
                <TagPicker
                    preventOverflow
                    id="iabCategories"
                    cleanable={false}
                    data={iabCategories}
                    labelKey="name"
                    valueKey="id"
                    defaultValue={opinion.linkedIabCategories}
                    onChange={(v, e) => mapOpinionToIabCategories(opinion.opinionId, v)}
                />

                <h4 style={{ marginTop: 48 }}>Aylien News</h4>
                <TagPicker
                    preventOverflow
                    id="aylienNews"
                    cleanable={false}
                    data={aylienNews}
                    labelKey="taxonomyName"
                    valueKey="id"
                    defaultValue={opinion.linkedAylienNews}
                    onChange={(v, e) => {
                        mapOpinionToAylienNews(opinion.opinionId, v)
                    }}
                />

                <h4 style={{ marginTop: 48 }}>Aylien Industry</h4>
                <TagPicker
                    preventOverflow
                    id="aylienIndustry"
                    cleanable={false}
                    data={aylienIndustry}
                    labelKey="taxonomyName"
                    valueKey="id"
                    defaultValue={opinion.linkedAylienIndustries}
                    onChange={(v, e) => {
                        mapOpinionToAylienIndustries(opinion.opinionId, v)
                    }}
                />
            </Panel>
        </div>
    )
}

export default OpinionEdit
