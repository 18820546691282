import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'

import { MyLocationGenerics } from '@/classes/utils'
import { useSearch } from '@tanstack/react-location'
import { AdGroupsType, TargetedActivationListType } from '@/views/TikTok/ActivationManager/types'

export const useStoredTargetedActivations = () => {
  const { accountId } = useSearch<MyLocationGenerics>()

  const getStoredSelectedActivations = () => {
    const storedSelectedActivations = sessionStorage.getItem('selectedHashtags')
    return (
      storedSelectedActivations && storedSelectedActivations !== 'undefined' && JSON.parse(storedSelectedActivations)
    )
  }

  const getStoredTargetedActivationsList = () => {
    const storedSelectedActivations = sessionStorage.getItem('tikTokTargetedActivationsList')
    return (
      storedSelectedActivations && storedSelectedActivations !== 'undefined' && JSON.parse(storedSelectedActivations)
    )
  }

  /**
   * Loads the activation list from session storage based on the account ID.
   *
   * @returns {Map<number, TargetedActivationListType>} - Map of cluster IDs to TargetedActivationListType.
   */
  const loadActivationListFromStorage = (): SerializableMap<string, TargetedActivationListType> | undefined => {
    // Retrieve the locally stored value from session storage.
    const targetedActivationsListFromStorage = getStoredTargetedActivationsList()
    const storedSelectedHashtags = getStoredSelectedActivations()
    if (targetedActivationsListFromStorage && accountId) {
      const targetedActivationsList: Record<string, TargetedActivationListType> =
        targetedActivationsListFromStorage[accountId]
      if (targetedActivationsList) {
        const selectedHashtags: string[] = storedSelectedHashtags[accountId]
        const newTargetedActivationList = updateStoredActivationListWithSelectedHashtags(
          targetedActivationsList,
          selectedHashtags
        )
        return parseStoredActivationList(newTargetedActivationList)
      }
    }
    return
  }

  const parseStoredActivationList = (newTargetedActivationList: Record<string, TargetedActivationListType>) => {
    //Parse the JSON into the desired Type : SerializableMap<string, TargetedActivationListType>.
    const parsedList = new SerializableMap<string, TargetedActivationListType>()
    //prepare the default list
    for (const [hashtagId, activationsList] of Object.entries(newTargetedActivationList)) {
      const targetedActivation: TargetedActivationListType = {
        hashtagId: activationsList.hashtagId,
        hashtagName: activationsList.hashtagName,
        categoryName: activationsList.categoryName,
        campaignsToAdGroups: new SerializableMap<number, SerializableSet<number>>(
          Object.entries(activationsList.campaignsToAdGroups).map(([campaignId, adGroups]: [string, any]) => [
            parseInt(campaignId, 10),
            new SerializableSet(adGroups)
          ])
        )
      }
      parsedList.set(hashtagId, targetedActivation)
    }
    return parsedList
  }

  const updateStoredActivationListWithSelectedHashtags = (
    targetedActivationsList: Record<string, TargetedActivationListType>,
    selectedHashtags: string[]
  ) => {
    const newTargetedActivationList: Record<string, TargetedActivationListType> = {}
    //sync the activation list with selected hashtag list
    Object.entries(targetedActivationsList).forEach(([hashtagId, activations]) => {
      if (hashtagId in selectedHashtags) newTargetedActivationList[hashtagId] = activations
    })

    return newTargetedActivationList
  }
  /**
   * Sets the activation list to session storage based on the brand profile ID.
   *
   * @param {Map<number, TargetedActivationListType>} newTargetedActivationList - Map of cluster IDs to ActivationListType.
   * @returns {void}
   */
  const setActivationListToStorage = (
    newTargetedActivationList: SerializableMap<string, TargetedActivationListType>
  ): void => {
    const targetedActivationsListFromStorage = getStoredTargetedActivationsList() ?? {}
    if (!accountId) {
      return
    }
    targetedActivationsListFromStorage[accountId] = newTargetedActivationList
    sessionStorage.setItem('tikTokTargetedActivationsList', JSON.stringify(targetedActivationsListFromStorage))
  }

  const syncActivationListWithSelectedHashtags = () => {
    const targetedActivationList = loadActivationListFromStorage()
    targetedActivationList && setActivationListToStorage(targetedActivationList)
  }

  return {
    storedActivationList: loadActivationListFromStorage(),
    setActivationListToStorage,
    syncActivationListWithSelectedHashtags
  }
}
