import { GetActivationsPropsType } from '@/views/Discover/Activations/v2/activations-types'

export const activationsRqKeys = {
  brandProfilesActivationStatusCount: (brandProfileIds: number[] | undefined) =>
    ['activation_status_count', brandProfileIds] as const,
  activations: ({ brandProfileId, page, size, status }: GetActivationsPropsType) =>
    ['activations', brandProfileId, page, size, status] as const,
  todoActivations: ({ brandProfileId }: { brandProfileId: number | undefined }) =>
    ['todoActivations', brandProfileId] as const,
  inProcessActivations: ({ brandProfileId }: { brandProfileId: number | undefined }) =>
    ['inProcessActivations', brandProfileId] as const,
  activationInsertionOrderHierarchy: ({ ioIds }: { ioIds: string[] }) =>
  ['activationInsertionOrderHierarchy', ioIds.join(',')] as const,
}
