import React from 'react'
import './hashtags.css'
import { Divider } from 'rsuite'
import { IHashtag } from '@/views/TikTok/Hashtags/types'
import { BoltIcon as BoltIconOutline } from '@heroicons/react/24/outline'
import { BoltIcon as BoltIconSolid } from '@heroicons/react/24/solid'
import Tooltip from "@/components/TailwindTooltip"

interface IProps {
    tiktokHashtag: IHashtag
    isChecked: boolean
    onCheckboxChange: (hashtagId: string) => void
    index: number
    onOpenInsights: (hashtag: IHashtag) => void
    dateRange: string
}

export const TikTokHashtagCard: React.FC<IProps> = ({ tiktokHashtag, isChecked, onCheckboxChange, index, onOpenInsights, dateRange }) => {

    const formatNumber = (value: number) => {
        if (value < 1000) return value.toString()

        const suffixes = ['K', 'M', 'B', 'T']
        const suffixIndex = Math.floor(Math.log10(value) / 3)
        const shortValue = (value / Math.pow(1000, suffixIndex)).toFixed(1)

        return shortValue.replace(/\.0$/, '') + suffixes[suffixIndex - 1]
    }

    const getBadge = () => {
        const rankChange = tiktokHashtag.rankChange.toLowerCase()
        if (rankChange == 'new') {
            return <span className="px-3 py-1 text-xs font-bold text-blue-500 bg-blue-100 rounded-full">● New</span>
        } else {
            const numRankChange = Number(tiktokHashtag.rankChange)
            if (numRankChange > 0) {
                return <span className="px-3 py-1 text-xs font-bold text-green-500 bg-green-100 rounded-full">↑ {numRankChange}</span>
            } else if (numRankChange < 0) {
                return <span className="px-3 py-1 text-xs font-bold text-red-500 bg-red-100 rounded-full">↓ {Math.abs(numRankChange)}</span>
            } else {
                return null
            }
        }
    }

    const getStatus = () => {
        const status = tiktokHashtag.status.toLowerCase()
        if (status == 'activated') {
            return <span className="px-3 py-1 text-xs font-semibold text-green-500 bg-green-100 rounded-full">Hashtag activated</span>
        } else if (status == "pending") {
            return <span className="px-3 py-1 text-xs font-semibold text-yellow-500 bg-yellow-100 rounded-full">Activation pending</span>
        } else {
            return null
        }
    }

    return (
        <div className={`p-4 border rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300 w-72 aspect-square ${isChecked ? 'border-[#7B61FF] border-2' : ''}`}>
            {tiktokHashtag.status.toLowerCase() == 'online' && (
                <div className="flex items-center justify-between mb-2">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => onCheckboxChange(tiktokHashtag.hashtagId)}
                        className="w-4 h-4 border-gray-300 rounded form-checkbox text-sightly-blue focus:ring-sightly-blue disabled:opacity-40"
                    />
                </div>
            )}
            <div className='flex items-baseline justify-between mt-4'>
                <div>
                    {getStatus()}
                </div>
                <div>
                    {getBadge()}
                </div>
            </div>

            <h2 className="flex mb-2 mt-4 text-xl font-bold text-gray-800 justify-center">
                #{tiktokHashtag.hashtagName}
                {tiktokHashtag.status.toLowerCase() === 'online' && (
                    <Tooltip content={<div>Available for targeting</div>}>
                        <BoltIconSolid className='h-6 ml-2 text-violet-600' />
                    </Tooltip>
                )}
                {tiktokHashtag.status.toLowerCase() === 'offline' && (
                    <Tooltip content={<div>Unavailable for targeting</div>}>
                        <BoltIconOutline className='h-6 ml-2 text-neutral-400' />
                    </Tooltip>
                )}
            </h2>
            <Divider style={{ margin: '12px 0px' }} />
            <div className="flex justify-between pt-2 pb-2 mb-2">
                <div className='text-center ml-3'>
                    <p className="text-xs">Posts</p>
                    <p className="text-lg font-extrabold text-black">{formatNumber(tiktokHashtag.posts)}</p>
                    <p className="text-xs">{dateRange}</p>
                </div>
                <div className="text-center mr-3">
                    <p className="text-xs">Views</p>
                    <p className="text-lg font-extrabold text-black">{formatNumber(tiktokHashtag.views)}</p>
                    <p className="text-xs">{dateRange}</p>
                </div>
            </div>
            <Divider style={{ margin: '12px 0px' }} />
            <a href="#" className="text-sm font-bold text-sightly-blue" onClick={(e) => { e.preventDefault(); onOpenInsights(tiktokHashtag) }}>Insights →</a>
        </div>
    )
}