import React from 'react'
import TagPicker from 'rsuite/lib/TagPicker'
import Toggle from 'rsuite/lib/Toggle'
import debounce from 'just-debounce-it'
import Input from 'rsuite/lib/Input'
import Tree from 'rsuite/lib/Tree'
import ButtonGroup from 'rsuite/lib/ButtonGroup'
import Button from 'rsuite/lib/Button'
import InputGroup from 'rsuite/lib/InputGroup'
import Icon from 'rsuite/lib/Icon'
import Panel from 'rsuite/lib/Panel'
import { connect } from 'react-redux'
import {
    patchBrandProfileTopics,
    fetchBrandProfileTopics,
    setBrandProfileTopics
} from '../../../../redux/actions/brandProfiles'
import { accentColor } from '../../../../assets/jss/colorConstants'
import FiltersLabel from '../../../Engage/Lists/ListBuilder/components/FiltersLabel'
import { useMatch, useSearch } from '@tanstack/react-location'
import { LoadingPage } from '../../../../components/LoadingPage'
import SightlyToggle from '../../../../components/Sightly/SightlyFormElements/SightlyToggle'
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const actionIdOptions = [
    {
        label: 'Include',
        id: 'include'
    },
    {
        label: 'Exclude',
        id: 'exclude'
    },
    {
        label: 'No Action',
        id: 'noaction'
    }
]

const mapStateToProps = (state) => {
    return {
        brandProfile: state.brandProfileUnderEdit,
        brandProfileTopicsLoading: state.brandProfileTopicsLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        patchBrandProfileTopics: (data) => dispatch(patchBrandProfileTopics(data)),
        fetchBrandProfileTopics: (data) => dispatch(fetchBrandProfileTopics(data)),
        setBrandProfileTopics: (topics) => dispatch(setBrandProfileTopics(topics))
    }
}

const Node = (props) => {
    const nodeProps = props.nodeProps;
    const { perms, userPermissions } = useUser();
    const {
        params: { brandProfileId }
    } = useMatch();
    const handleClick = (e, topicId, oldVal, newValProposed) => {
        e.preventDefault();

        let newTopics = JSON.parse(JSON.stringify(props.componentTopics))
        setTopicAction(nodeProps.topicId, newValProposed, newTopics)
        props.setComponentTopics(newTopics)
        props.setBrandProfileTopics(newTopics)
        let newVal = getNewTopicsVal(newValProposed, nodeProps.topicResponseId)
        let params = {
            topics: [
                {
                    topicId: nodeProps.topicId,
                    topicResponseId: newVal
                }
            ],
            brandProfileId
        }
        props.patchBrandProfileTopics(params)
    }
    function getNewTopicsVal(newValProposed, oldVal) {
        if (newValProposed == oldVal) return null
        return newValProposed
    }

    function setTopicAction(topicId, value, topics) {
        for (const topic of topics) {
            markSelected(topicId, value, topic)
        }
    }

    function markSelected(topicId, value, topic) {
        if (topic.topicId == topicId) {
            if (topic.topicResponseId === value) {
                value = null
            }
            topic.topicResponseId = value
        } else {
            if (topic.children && topic.children.length > 0) {
                for (const child of topic.children) {
                    markSelected(topicId, value, child)
                }
            }
        }
    }

    let includeColor = React.useMemo(() => {
        return nodeProps.topicResponseId === 1 ? '#4CAF50' : nodeProps.topicResponseId === 2 ? 'lightgrey' : accentColor
    }, [nodeProps.topicResponseId])

    const excludeColor = React.useMemo(() => {
        return nodeProps.topicResponseId === 2 ? 'red' : nodeProps.topicResponseId === 1 ? 'lightgrey' : accentColor
    }, [nodeProps.topicResponseId])

    return (
        <div style={{ display: 'flex', width: '700px' }}>
            <div
                className="maxOneLine"
                style={{ flex: 3, color: 'black', fontSize: 12 }}
            >
                {nodeProps.topicName}
            </div>
            <div style={{ flex: 1 }}>
                <ButtonGroup size="xs">
                    <Button
                        disabled={!userCanPermissionProductQuota({
                            requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
                            userPermissions: userPermissions,
                            checkType: PermissionCheckType.PERMISSION_CHECK
                        }) || props.disabled}
                        key="0"
                        id="0"
                        onClick={(e) => handleClick(e, nodeProps.topicId, nodeProps.topicResponseId, 1)}
                        style={{ backgroundColor: includeColor }}
                    >
                        Include
                    </Button>
                    <Button
                        disabled={!userCanPermissionProductQuota({
                            requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
                            userPermissions: userPermissions,
                            checkType: PermissionCheckType.PERMISSION_CHECK
                        }) || props.disabled}
                        id="test"
                        key="1"
                        onClick={(e) => handleClick(e, nodeProps.topicId, nodeProps.topicResponseId, 2)}
                        style={{ backgroundColor: excludeColor }}
                    >
                        Exclude
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    )
}

class Topics extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetched: false,
            cascadeUp: false,
            sort: 'asc',
            actionFilter: [],
            search: '',
            componentTopics: []
        }
    }

    componentWillMount() {
        this.fetchTopicsFunction()
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.brandProfile.topics) {
            this.setState({
                componentTopics: newProps.brandProfile.topics
            })
        }
    }

    fetchTopicsFunction = () => {
        let params = {
            brandProfileId: this.props.brandProfileId,
            sort: this.state.sort,
            filter: this.state.actionFilter,
            searchTerm: this.state.search
        }
        this.props.fetchBrandProfileTopics(params)
    }

    handleSearch = debounce((text) => {
        this.setState({ search: text }, () => {
            this.fetchTopicsFunction()
        })
    }, 700)

    handleSort = (bool) => {
        let val = bool ? 'asc' : 'desc'
        this.setState({ sort: val }, () => {
            this.fetchTopicsFunction()
        })
    }

    render() {
        return (
            <Panel header={<div className="sightlyPanelHeader">Topics</div>}>
                <div className="flex flex-col gap-8">
                    <div className="descriptionText">
                        Topics available from Google Ads can be marked as “Include” or “Exclude” below. These settings
                        are available to internal admin users only.
                    </div>

                    <div>
                        <FiltersLabel text="Actions Taken" />
                        <TagPicker
                            block
                            preventOverflow
                            data={actionIdOptions}
                            labelKey={'label'}
                            valueKey={'id'}
                            value={this.state.actionFilter}
                            placeholder="Filter by action"
                            onChange={(val) => {
                                this.setState({ actionFilter: val }, () => {
                                    this.fetchTopicsFunction()
                                })
                            }}
                        />
                    </div>

                    {this.state.actionFilter?.length > 0 && (
                        <SightlyToggle
                            id="sort"
                            setEnabled={this.handleSort}
                            enabled={this.state.sort === 'asc'}
                            enabledText="sort a-z"
                            disabledText="sort z-a"
                        />
                    )}
                    <div>
                        <InputGroup>
                            <Input
                                placeholder="Search..."
                                onChange={(val) => this.handleSearch(val)}
                            />

                            <InputGroup.Button
                                onClick={() => {}}
                                style={{ backgroundColor: 'transparent' }}
                            >
                                <Icon
                                    style={{ color: '#0092d1' }}
                                    icon="search"
                                />
                            </InputGroup.Button>
                        </InputGroup>

                        {this.props.brandProfileTopicsLoading ? (
                            <div style={{ height: 200 }}>
                                <LoadingPage message="Fetching Topics" />
                            </div>
                        ) : (
                            <Tree
                                expandAll={false}
                                defaultExpandAll={false}
                                name="topics"
                                labelKey="topicName"
                                valueKey="topicId"
                                data={this.state.componentTopics}
                                virtualized={true}
                                searchable={false}
                                renderTreeNode={(nodeProps) => {
                                    return (
                                        <Node
                                            disabled={this.props.viewOnly}
                                            brandProfile={this.props.brandProfile}
                                            patchBrandProfileTopics={this.props.patchBrandProfileTopics}
                                            brandProfileIdUnderEdit={this.props.brandProfileIdUnderEdit}
                                            nodeProps={nodeProps}
                                            componentTopics={this.state.componentTopics}
                                            setComponentTopics={(topics) => this.setState({ componentTopics: topics })}
                                            setBrandProfileTopics={this.props.setBrandProfileTopics}
                                            searchTerm={this.state.search}
                                        />
                                    )
                                }}
                            />
                        )}
                    </div>
                </div>
                <BrandProfileFooter activeStep={this.props.stepProps.activeStep}
                  disabled={false}
                  handlePrevious={this.props.stepProps.handlePreviousClick}
                  handleContinue={this.props.stepProps.handleContinueClick}
                />
            </Panel>
        )
    }
}

const TopicsWrapper = ({ ...rest }) => {
    const stepProps = useSteps();
    const {
        params: { brandProfileId }
    } = useMatch()
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false
    return (
        <Topics
            viewOnly={viewOnly}
            brandProfileId={brandProfileId}
            stepProps={stepProps}
            {...rest}
        />
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(TopicsWrapper)
