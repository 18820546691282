import { useSearch } from '@tanstack/react-location';
import React from 'react';
import { MyLocationGenerics } from '@/classes/utils';
import SightlyAvatar from '@/components/SightlyAvatar';
import { SmallBadge } from '@/components/SmallClosableBadge';
import { ReactComponent as KeywordsIcon } from '@/assets/img/keywordsKey.svg';
import { ReactComponent as BlackYoutube } from '@/assets/img/blackYoutube.svg';
import { useImplementedActivations } from './hooks/activations-hooks';
import { InfoPage } from '@/components/InfoPage';
import { BoltIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { MessageTailwind } from '@/components/MessageTailwind';
import PortalModal from '@/components/PortalModal/PortalModal';
import SightlyButton from '@/components/Sightly/SightlyButton';
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox';
import { Loader } from 'rsuite';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

interface IProps {
  handleDownloadActivations: Function
}

export const Implemented = ({ handleDownloadActivations }: IProps) => {
  const { brandProfileId } = useSearch<MyLocationGenerics>()
  const [selectedActivationIds, setSelectedActivationIds] = React.useState<number[]>([])
  const activationsQuery = useImplementedActivations({
    brandProfileId: Number(brandProfileId)
  })

  const [downloadingTrends, setDownloadingTrends] = React.useState(false)
  const { perms, userPermissions } = useUser()
  const [showTooManyTrendsError, setShowTooManyTrendsError] = React.useState(false)

  const allMomentsOnPageAreSelected = React.useMemo(() => {
    if (selectedActivationIds.length === 0) {
      return false
    }

    const notIncludedInSelectedItems = activationsQuery.data
      ?.map((m) => m.clusterId)
      ?.filter((id) => !selectedActivationIds?.includes(id))
    if (notIncludedInSelectedItems === undefined) {
      return true
    }
    if (notIncludedInSelectedItems.length > 0) {
      return false
    }
    return true
  }, [activationsQuery.data, selectedActivationIds])

  if (activationsQuery.isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2">
        <Loader
          content="Fetching Activations..."
          className="z-10"
          speed="slow"
        />
      </div>
    )
  }
  if (activationsQuery?.data && activationsQuery?.data.length === 0 && activationsQuery.isFetched) {
    return (
      <div className="w-full h-80">
        <InfoPage
          message={
            <div>
              <span>No items found.</span>
            </div>
          }
        />
      </div>
    )
  }

  return (
    <div
      className="flex flex-col mt-8 fade-in animate-in"
      data-testid="activations-implemented"
      id="pendo-activations-implemented"
    >
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
          <div className="flex justify-between w-full h-10 mb-10">
            <div>
              <MessageTailwind
                show={downloadingTrends}
                message="Downloading moments"
                type="loading"
              />
            </div>
            {userCanPermissionProductQuota({
              requiredPermissionValue: perms.TRENDS_DOWNLOAD,
              userPermissions: userPermissions,
              checkType: PermissionCheckType.PERMISSION_CHECK
            }) && (
              <SightlyButton
                datatestid="activations-implemented-download-btn"
                handleClick={() =>
                  handleDownloadActivations({
                    brandProfileId,
                    setDownloadingTrends,
                    setShowTooManyTrendsError,
                    selectedMomentIds: selectedActivationIds,
                    activationState: 'complete'
                  })
                }
                id="downloadActivation"
                text="Download"
                disabled={
                  (selectedActivationIds.length < 1) ||
                  (activationsQuery?.data && activationsQuery?.data.length === 0) ||
                  downloadingTrends ||
                  activationsQuery.isLoading
                }
                icon={
                  <ArrowDownTrayIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                    aria-hidden="true"
                  />
                }
              />
            )}
          </div>
          <div className="relative my-3 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="relative w-12 px-6 sm:w-16 sm:px-8"
                  >
                    <SightlyCheckbox
                      indeterminate={
                        selectedActivationIds.length > 0 && !allMomentsOnPageAreSelected
                      }
                      handleChange={(checked: boolean) => {
                        if (checked) {
                          setSelectedActivationIds(activationsQuery?.data?.map((i) => i.clusterId) || [])
                        } else {
                          setSelectedActivationIds([])
                        }
                      }}
                      id="selectAllTodo"
                      dataTestId="activations-implemented-select-all-checkbox"
                      checked={activationsQuery?.data?.length === selectedActivationIds.length}
                    />
                  </th>
                  <th
                    scope="col"
                    className=" py-3.5 pr-3 text-left text-sm font-semibold text-gray-600"
                  ></th>
                  <th
                    scope="col"
                    className="min-w-[12rem] py-3.5 pr-3 pl-4 text-left text-sm font-semibold text-gray-600"
                  >
                    MOMENT
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                  >
                    PERSONAS
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                  >
                    ASSIGNEES
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left whitespace-nowrap text-sm font-semibold text-gray-600"
                  >
                    IMPLEMENTED AT
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {activationsQuery?.data?.map((activationItem) => (
                  <tr
                    key={activationItem.clusterId}
                    className={'bg-gray-50 fade-in animate-in'}
                    data-testid="activations-implemented-row"
                  >
                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                      <SightlyCheckbox
                        dataTestId="activations-implemented-checkbox"
                        handleChange={(checked: boolean) => {
                          setSelectedActivationIds((prevIds) => Array.from(new Set([...prevIds,activationItem.clusterId])))
                        }}
                        id="activations-implemented-checkbox"
                        checked={selectedActivationIds.includes(activationItem.clusterId)}
                      />
                    </td>
                    <td className={'whitespace-nowrap py-4 pr-3 pl-4 text-sm font-medium max-w-min'}>
                      <BoltIcon className="h-4 text-sightly-blue" />
                    </td>
                    <td className={'whitespace-nowrap py-4 pr-3 pl-4 text-sm font-medium truncate max-w-lg'}>
                      <div
                        data-testid="activations-implemented-cluster-name"
                        id="pendo-activations-implemented-cluster-name"
                        className="truncate"
                      >
                        {activationItem.clusterName}
                      </div>
                      <div className="mt-2 statsContainer">
                        <div
                          className="flex items-center"
                          data-testid="activations-implemented-keywords"
                          id="pendo-activations-implemented-keywords"
                        >
                          <KeywordsIcon />
                          <div className="pl-1 text-xs text-gray-500">
                            {activationItem.keywordCount === 1
                              ? '1 keyword'
                              : activationItem.keywordCount + ' keywords'}
                          </div>
                        </div>

                        <div
                          className="flex items-center"
                          data-testid="activations-implemented-videos"
                          id="pendo-activations-implemented-videos"
                        >
                          <BlackYoutube />
                          <div className="pl-1 text-xs text-gray-500">
                            {activationItem.videoCount === 1 ? '1 video' : activationItem.videoCount + ' videos'}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div
                        className="flex items-center gap-2"
                        data-testid="activations-implemented-personas"
                        id="pendo-activations-implemented-personas"
                      >
                        {activationItem.personas.slice(0, 2).map((persona, index) => {
                          return (
                            <SmallBadge
                              dataTestId="activations-implemented-persona-badge"
                              key={`${activationItem.activationItemId}_${persona}_${index}`}
                              text={persona}
                              onClose={() => undefined}
                              disabled={true}
                            />
                          )
                        })}
                        {activationItem.personas.length > 2 && (
                          <div
                            className="text-xs"
                            data-testid="activations-implemented-personas-others"
                            id="pendo-activations-implemented-personas-others"
                          >
                            + {activationItem.personas.length - 2} others
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="flex items-center flex-shrink-0 gap-2 mt-4 sm:mt-0">
                        <div
                          className="flex -space-x-1 "
                          data-testid="activations-implemented-assignees"
                          id="pendo-activations-implemented-assignees"
                        >
                          {activationItem.assignees.slice(0, 2).map((assignee) => {
                            return (
                              <div
                                key={assignee}
                                className="inline-block rounded-full ring-2 ring-white"
                              >
                                <SightlyAvatar
                                  text={assignee}
                                  size="xs"
                                  type={null}
                                />
                              </div>
                            )
                          })}
                        </div>
                        {activationItem.assignees.length > 2 && (
                          <div
                            data-testid="activations-implemented-assignees-others"
                            id="pendo-activations-implemented-assignees-others"
                            className="text-xs"
                          >
                            + {activationItem.assignees.length - 2} others
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap"
                      data-testid="activations-implemented-assignees-date"
                      id="pendo-activations-implemented-assignees-date"
                    >
                      {activationItem?.implementedDate || ' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <PortalModal
            hasClose
            open={showTooManyTrendsError}
            handleClose={() => setShowTooManyTrendsError(false)}
            footerButtons={[]}
          >
            <div className="w-[500px] p-6 flex flex-col gap-12">
              <h4>Unable to download</h4>
              <div>
                Our system can only handle downloading 1,000 moments maximum. Please add more filters and try again.
              </div>
            </div>
          </PortalModal>
        </div>
      </div>
      <style>
        {`
                  .statsContainer {
                      display: grid;
                      grid-template-columns: 130px 130px 130px;
                      grid-auto-flow: row;	
                  }
                `}
      </style>
    </div>
  )
}
