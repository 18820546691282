import { z } from 'zod'
import { Unarray } from '@/classes/utils'

// the point of zod is to not have to define the interface and the schema twice.
// But since this is a recursive type (children), this is the only way to do it here

const SubscriptionPackageSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  type: z.string().optional(),
  created_date: z.string().optional(),
  last_modified_date: z.string().optional(),
})

const SubscriptionProductSchema = z.array(z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  created_date: z.string(),
  last_modified_date: z.string(),
  quota: z.string().nullable()
}))

interface AccountSchema {
  accountId: number
  accountName: string
  accountLevelId: number
  accountTypeId: number
  parentAccountName: string
  contactName: string
  contactEmail: string
  children?: AccountSchema[]
  parentAccountId: number | null
  accountLevelName: string
  accountMargin?: number
  accountStatus: string
  stripeSubscriptionId?: string | null
  packageId?: number | null
  package?: z.infer<typeof SubscriptionPackageSchema>
  products?: z.infer<typeof SubscriptionProductSchema> | []
  packageStartDate?: string | null
  packageEndDate?: string | null
  trialStartDate?: string | null
  trialEndDate?: string | null
  brandProfiles?: []
}

const AccountSchema: z.ZodType<AccountSchema> = z.lazy(() =>
  z.object({
    accountId: z.number(),
    accountLevelId: z.number(),
    accountLevelName: z.string(),
    accountMargin: z.number(),
    accountName: z.string(),
    accountTypeId: z.number(),
    parentAccountId: z.number().nullable(),
    parentAccountName: z.string(),
    accountTypeName: z.string(),
    contactEmail: z.string(),
    contactName: z.string(),
    packageId: z.number().nullable().optional(),
    package: SubscriptionPackageSchema.optional(),
    products: SubscriptionProductSchema.min(0).optional(),
    children: z.array(AccountSchema).optional(),
    packageStartDate: z.string().nullable().optional(),
    packageEndDate: z.string().nullable().optional(),
    trialStartDate: z.string().nullable().optional(),
    trialEndDate: z.string().nullable().optional(),
    accountStatus: z.string(),
    stripeSubscriptionId: z.string().nullable().optional()
  })
)

export type AccountValues = z.infer<typeof AccountSchema>;

const PermissionSchema = z.object({
  permissionId: z.number(),
  permissionName: z.string(),
  permissionDescription: z.string(),
})
export const UserSchema = z.object({
  userId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  company: z.string(),
  roleId: z.number(),
  roleName: z.string(),
  userType: z.enum(['Internal', 'External']),
  accounts: z.array(AccountSchema),
  permissions: z.array(PermissionSchema),
  userName: z.string(), //not used
  phoneNumber: z.string() //not used
})

export type AccountType = Unarray<z.infer<typeof UserSchema>['accounts']>
export type UserType = z.infer<typeof UserSchema>

export const userCompletionStepObj = z.object({
  userId: z.number(),
  id: z.number(),
  videoId: z.string(),
  createdAt: z.string()
});
export type UserCompletionStep = z.infer<typeof userCompletionStepObj>;

export const userCompletionStepsArr = z.array(userCompletionStepObj);
