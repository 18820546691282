import { ZodType, z } from 'zod'
import { BASIC_INFO, NEW_BRAND_PROFILE } from './constants'
const urlRegex = require('url-regex')
export interface NewBrandProfileFormType {
  brandName: string
  websiteUrl: string
  wikiUrl?: string
  isAIGenerate?: boolean
}

export const NewBrandProfileFormSchema: ZodType<NewBrandProfileFormType> = z.object({
  brandName: z
    .string()
    .min(2, BASIC_INFO.VALIDATION_BRAND_PROFILE_NAME.MIN)
    .max(50, BASIC_INFO.VALIDATION_BRAND_PROFILE_NAME.MAX),
  websiteUrl: z
    .string()
    .regex(urlRegex({ exact: true, strict: false }), { message: 'Please enter a valid website' })
})
