import React from 'react'
import MomentArticle from './MomentArticle'
import { ClusterType, MomentArticleType } from './../types'
import { LoadingPage } from '@/components/LoadingPage'

interface TrendArticlesProps {
    moment: ClusterType
    articles: MomentArticleType[]
    isLoading: boolean
}

const MomentArticles = ({ moment, isLoading, articles }: TrendArticlesProps) => {
    return (
        <div
            data-testid="moment-modal-body-articles"
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            {articles?.length === 0 && !isLoading && (
                <div
                    data-testid="moment-modal-articles-noarticle-message"
                    style={{
                        height: 28,
                        width: 'fit-content',
                        paddingRight: 8,
                        borderRadius: 3,
                        backgroundColor: '#F2FAFD',
                        paddingTop: 3,
                        paddingLeft: 8,
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: '22px',
                        marginTop: 24
                    }}
                >
                    Currently we have no articles for this Moment.
                </div>
            )}
            {moment?.articleCount > 5 && !isLoading && (
                <div
                    data-testid="moment-modal-articles-count"
                    style={{
                        height: 28,
                        paddingRight: 8,
                        borderRadius: 3,
                        backgroundColor: '#F2FAFD',
                        paddingTop: 3,
                        paddingLeft: 8,
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: '22px',
                        marginTop: 24
                    }}
                >
                    Showing 5 of {moment?.articleCount} articles. To see all articles, please download the moment.
                </div>
            )}

            <div
                id="articlesTab"
                style={{
                    overflowY: 'auto',
                    marginTop: 24,
                    textAlign: 'left'
                }}
            >
                {isLoading ? (
                    <LoadingPage message="Fetching articles" />
                ) : (
                    <>
                        {articles &&
                            articles.slice(0, 5).map((article, index) => {
                                return (
                                    <MomentArticle
                                        key={index + article.url}
                                        article={article}
                                    />
                                )
                            })}
                    </>
                )}
            </div>
        </div>
    )
}

export default MomentArticles
