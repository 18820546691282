import React from 'react'
import SightlyButton from '../../../../components/Sightly/SightlyButton'
import SightlySelect from '../../../../components/Sightly/SightlyFormElements/SightlySelect'
import Loader from 'rsuite/lib/Loader'
import { fetchVersionService, downloadExcelListService } from '../../../../services/lists'
import Input from 'rsuite/lib/Input'
import { useQuery } from '@tanstack/react-query'
import PortalModal from '../../../../components/PortalModal/PortalModal'
import { userAccountAxios } from '../../../../axiosInstances'
import { rqKeys } from '../../../../ReactQueryKeyFactory'

const greaterThanVerbiage = `We currently support downloading SmartLists with up to 50,000
channels or videos. Since this SmartList is larger than that
amount, please either apply additional filters to reduce its size
or utilize one of the following techniques to download a sample of
the list:`

const downloadOptions = [
    { value: 'random', label: 'Simple random sample' },
    { value: 'uniform', label: 'Uniform sample' }
]

const lessThanDownloadOptions = [
    { value: 'entire', label: 'Entire SmartList' },
    { value: 'random', label: 'Simple random sample' },
    { value: 'uniform', label: 'Uniform sample' }
]

const getIsOrdered = async (brandProfileId) => {
    if (!brandProfileId) return false
    const url = `/brand-profile/${brandProfileId}/isOrdered`
    const res = await userAccountAxios.get(url)
    return res.data.orderedByViews
}

const DownloadModal = ({ show, setShow, versionId }) => {
    const [version, setVersion] = React.useState(null)
    const [greaterThan, setGreaterThan] = React.useState(false)

    const { data: isOrdered } = useQuery(
        rqKeys.isOrdered(version?.brandProfileId),
        () => getIsOrdered(version?.brandProfileId),
        { enabled: !!version && !!version.brandProfileId }
    )

    const handleClose = () => {
        setShow(false)
        setTimeout(() => {
            setGreaterThan(false)
            setVersion(null)
            setSampleType('random')
            setLessSampleType('entire')
            setSize(50000)
            setLessSize(50000)
        }, 500)
    }

    React.useEffect(() => {
        if (!version) return
        if (version.stats.itemCount > 50000) return
        if (greaterThan) {
            setSize(version.stats.itemCount)
        } else {
            setLessSize(version.stats.itemCount)
        }
    }, [version])

    React.useEffect(() => {
        if (!show) {
            return
        }
        if (!versionId) {
            return
        }

        ;(async () => {
            let v = await fetchVersionService(versionId)

            setVersion(v)

            if (v.stats.itemCount > 50000) {
                setGreaterThan(true)
            }
        })()
    }, [show, versionId])

    const [size, setSize] = React.useState(50000)
    const [lessSize, setLessSize] = React.useState(50000)
    const [sampleType, setSampleType] = React.useState('random')
    const [lessSampleType, setLessSampleType] = React.useState('entire')

    const handleSizeChange = (val) => {
        if (!val) {
            setSize('')
            return
        }

        if (val > 50000) {
            setSize(50000)
        } else {
            setSize(val)
        }
    }

    const handleLessSizeChange = (val) => {
        if (!val) {
            setLessSize('')
            return
        }
        if (val > version.stats.itemCount) {
            setLessSize(version.stats.itemCount)
        } else {
            setLessSize(val)
        }
    }

    const [isDownloading, setIsDownloading] = React.useState(false)
    const handleDownloadClick = async () => {
        setIsDownloading(true)
        let payload
        if (greaterThan) {
            payload = {
                versionId: version.versionId,
                smartListName: version.smartListName,
                sampleType: sampleType,
                itemCount: Number(size)
            }
        } else {
            payload = {
                versionId: version.versionId,
                smartListName: version.smartListName,
                sampleType: lessSampleType,
                itemCount: Number(lessSize)
            }
        }

        if (payload.sampleType === 'entire') {
            delete payload.sampleType
        }

        await downloadExcelListService(payload)
        setIsDownloading(false)
    }

    if (version?.stats?.itemCount < 1) {
        return (
            <PortalModal
                footerButtons={[
                    <SightlyButton
                        id="closeModalDownloadButton"
                        text="Ok"
                        handleClick={handleClose}
                    />
                ]}
                open={show}
                handleClose={handleClose}
            >
                <div>You have no items in your list. Please adjust your filters or add some more items.</div>
            </PortalModal>
        )
    }

    return (
        <PortalModal
            open={show}
            handleClose={handleClose}
            footerButtons={[
                <SightlyButton
                    id="downloadModalCancelButton"
                    type="cancel"
                    disabled={isDownloading}
                    text="Cancel"
                    handleClick={handleClose}
                />,
                <SightlyButton
                    id="downloadModalSubmitButton"
                    loading={isDownloading}
                    disabled={isDownloading || size < 1 || lessSize < 1}
                    text="Download"
                    handleClick={handleDownloadClick}
                />
            ]}
        >
            <div style={{ width: 500, padding: 30 }}>
                {version == null ? (
                    <Loader
                        center
                        vertical
                    />
                ) : greaterThan ? (
                    <>
                        <div style={{ marginBottom: 24 }}>{greaterThanVerbiage}</div>
                        {isOrdered && (
                            <div style={{ marginBottom: 24, fontWeight: 600 }}>
                                Based on the top Outcome selected in your Brand Profile, we will skew the sample of
                                videos downloaded towards having higher views
                            </div>
                        )}
                        <SightlySelect
                            label="Sample Type"
                            labelKey="label"
                            valueKey="value"
                            width={240}
                            options={downloadOptions}
                            onChange={(val) => setSampleType(val)}
                            value={sampleType}
                        />

                        <div style={{ width: 240, marginTop: 24 }}>
                            <div className="input-label">SmartList Size</div>
                            <Input
                                id="smartListSizeInput"
                                type="number"
                                value={size}
                                onChange={(e) => handleSizeChange(e)}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ marginBottom: 24 }}>
                            Please select one of the following ways to download your SmartList:
                        </div>
                        {isOrdered && (
                            <div style={{ marginBottom: 24, fontWeight: 600 }}>
                                Based on the top Outcome selected in your Brand Profile, we will skew the sample of
                                videos downloaded towards having higher views
                            </div>
                        )}
                        <SightlySelect
                            label="Sample Type"
                            labelKey="label"
                            valueKey="value"
                            width={240}
                            options={lessThanDownloadOptions}
                            onChange={(val) => {
                                setLessSampleType(val)
                                setSize(50000)
                                setLessSize(50000)
                            }}
                            value={lessSampleType}
                        />

                        <div style={{ width: 240, marginTop: 24 }}>
                            {lessSampleType !== 'entire' && (
                                <>
                                    <div className="input-label">SmartList Size</div>
                                    <Input
                                        id="smartListSizeInput2"
                                        value={lessSize}
                                        onChange={(e) => handleLessSizeChange(e)}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </PortalModal>
    )
}

export default DownloadModal
