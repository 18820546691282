import { z } from 'zod'

const ActivationSchema = z.object({
  activationId: z.number(),
  hashtagId: z.string(),
  createdAt: z.string(),
  hashtagName: z.string(),
  campaignName: z.string(),
  adGroupName: z.string()
})

const CampaignOptionSchema = z.object({
  campaignId: z.number(),
  campaignName: z.string()
})

const AdGroupOptionSchema = z.object({
  adGroupId: z.number(),
  adGroupName: z.string()
})

export const GetActivationsSchema = z.object({
  activations: z.array(ActivationSchema),
  counts: z.object({
    pending: z.number(),
    active: z.number(),
    removed: z.number(),
    failed: z.number()
  }),
  config: z.object({
    campaignOptions: z.array(CampaignOptionSchema),
    adGroupOptions: z.array(AdGroupOptionSchema)
  })
})

export const UpdateActivationStatusSchema = z.object({
  affectedRows: z.number(),
  error: z.string().optional()
})

export type IActivation = z.infer<typeof ActivationSchema>
export type ICampaignOption = z.infer<typeof CampaignOptionSchema>
export type IAdGroupOption = z.infer<typeof AdGroupOptionSchema>

interface ITab { name: string, id: string, count?: number }

export interface ITabs {
  pending: ITab,
  active: ITab,
  removed: ITab,
  failed: ITab
}

export const ActivationStatus: ITabs = {
  pending: { name: 'Pending', id: 'PENDING' },
  active: { name: 'Active', id: 'ACTIVE' },
  removed: { name: 'Removed', id: 'REMOVED' },
  failed: { name: 'Failed', id: 'FAILED' }
}