import { userAccountAxios } from '@/axiosInstances';
import { Board } from '@/views/Discover/Boards/useBoards';
import { NewBrandProfileFormType } from '@/views/BrandProfiles/types';
import { BrandProfile, TargetviewIo } from '@/classes/brandProfile';
import {
  basicInfoObjValidation,
  boardsObjValidation,
  questionsObjValidation,
  targetviewIos
} from '@/schemas/brandProfiles';
import { ScenariosSchema, ScenarioType } from '@/schemas/brandProfile_ts';
import { logError } from '@/utils';
import { aiGenerateType } from '@/hooks/brandProfile/useBrandProfileBasic';
import { GENERAL_BOARD_DEFAULT_TYPES, SUGGESTED_BOARD_NAMES } from '@/views/BrandProfiles/constants'

export const brandProfile = {
  submit: async (brandProfileId: number) => {
    const urlSubmit = `/brand-profile/${brandProfileId}/submit`
    await userAccountAxios.patch(urlSubmit)

    const board = {
      boardType: 'general',
      defaultType: GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS,
      name: SUGGESTED_BOARD_NAMES.TRENDING_MOMENTS,
      starred: false,
      aylienNewsIds: [],
      aylienIndustryIds: []
    }
    const urlCreateDefaultBoard = `/brand-profile/${brandProfileId}/board`
    return await userAccountAxios.post(urlCreateDefaultBoard, board)
  },
  submitWithoutBoard: async (brandProfileId: number) => {
    const urlSubmit = `/brand-profile/${brandProfileId}/submit`
    const data = {isTikTokUser: true}
    await userAccountAxios.patch(urlSubmit, data)
  },
  create: async (accountId: number | undefined, formData?: NewBrandProfileFormType) => {
    if (typeof accountId === undefined) {
      throw new Error('accountId must be defined')
    }
    const url = '/brand-profile/new';

    const brandProfile = {
      accountId,
      brandName: formData?.brandName || '',
      brandInfoName: formData?.brandName ?? '',
      websiteUrl:
        (formData?.websiteUrl &&
          (formData?.websiteUrl?.startsWith('https://')
            ? formData?.websiteUrl
            : `https://${formData?.websiteUrl}`)) ||
        '',
      wikiUrl: formData?.wikiUrl
    }

    const result = await userAccountAxios.post<BrandProfile>(url, brandProfile, {
      params: {
          accountId,
          isAIGenerate: !!formData?.isAIGenerate }
    })
    if (result.status === 200) {
      basicInfoObjValidation.validate(result.data).catch(function (err) {
        logError(err)
        console.error(
          ' we received different data from the api than expected after creating a  brand profile, see console log for more details'
        )
      })
    }
    return result.data
  },
  clone: async (brandProfileId: number | undefined) => {
    if (typeof brandProfileId === undefined) {
      throw new Error('brandProfileId must be defined')
    }
    const url = `/brand-profile/${brandProfileId}/clone`
    const result = await userAccountAxios.post(url)
    if (result.status === 200) {
      basicInfoObjValidation.validate(result.data[0]).catch(function (err) {
        logError(err)
        console.error(
          'we received different data from the api than expected after cloning a  brand profile, see console log for more details'
        )
      })
    }
    return JSON.parse(JSON.stringify(result.data[0]))
  },
  basicInfo: {
    get: async (brandProfileId: number) => {
      const url = `/brand-profile/${brandProfileId}/basic`
      const result = await userAccountAxios.get<BrandProfile & { brandName: string }>(url)
      basicInfoObjValidation.validate(result.data).catch(function (err) {
        logError(err.name, err.errors)
      })
      const {
        brandName,
        brandInfoName,
        wikiUrl,
        aylienIndustryId,
        twitterProfileUrl,
        websiteUrl,
        submitted,
        lastModifiedInterval,
        companySummary,
        companyMentality,
        keyTerms,
        missionStatement,
        valueStatement,
        visionStatement,
        aiSuggestedKeyTerms
      } = result.data
      return {
        brandProfileId,
        brandProfileName: brandName,
        brandInfoName: brandInfoName,
        wikiUrl: wikiUrl ?? '',
        aylienIndustryId,
        twitterProfileUrl,
        websiteUrl: websiteUrl ?? '',
        submitted,
        lastSaved: lastModifiedInterval,
        companySummary: companySummary ?? '' ,
        companyMentality: companyMentality ?? '' ,
        keyTerms: keyTerms ?? '' ,
        missionStatement: missionStatement ?? '' ,
        valueStatement: valueStatement ?? '' ,
        visionStatement: visionStatement ?? '' ,
        aiSuggestedKeyTerms: aiSuggestedKeyTerms ?? ''
      }
    },
    patch: async (brandProfile: Partial<BrandProfile>) => {
      const url = `/brand-profile/${brandProfile.brandProfileId}`
      delete brandProfile.brandProfileId
      return userAccountAxios.patch(url, brandProfile)
    },
    aiGenerate: async (brandProfileId: number, brandProfile: aiGenerateType, accountId?: number) => {
      const url = `/brand-profile/${brandProfileId}/aiGenerate`
      const brandProfileAiGenerate = {
        accountId,
        brandName: brandProfile.brandName,
        brandInfoName: brandProfile.brandName,
        websiteUrl:
        (brandProfile?.websiteUrl &&
          (brandProfile?.websiteUrl?.startsWith('https://')
            ? brandProfile?.websiteUrl
            : `https://${brandProfile?.websiteUrl}`)) ||
        '',
      }
      return userAccountAxios.patch(url, brandProfileAiGenerate)
    }    
  },
  ios: {
    get: async (brandProfileId: number) => {
      const url = `/brand-profile/${brandProfileId}/ios`

      const result = await userAccountAxios.get<TargetviewIo[]>(url)

      if (result.status === 200) {
        targetviewIos.validate(result.data).catch(function (err) {
          logError(err)
          console.error(
            'we received different data from the api than expected while fetching brand profile ios, see console log for more details'
          )
        })
      }
      return result.data || []
    },
    patch: async ({ brandProfileId, ios }: { brandProfileId: number; ios: number[] }) => {
      const url = `/brand-profile/${brandProfileId}/ios`
      return await userAccountAxios.patch(url, ios)
    }
  },
  questions: {
    get: async (brandProfileId: number) => {
      const url = `/brand-profile/${brandProfileId}/questions`

      const result = await userAccountAxios.get(url)

      questionsObjValidation.validate(result.data.slice(0, 3)).catch(function (err) {
        logError(err, {
          info: 'we received different data from the api than expected while fetching brand profile questions, see console log for more details'
        })
      })

      return result.data
    }
  },
  scenarios: {
    get: async (brandProfileId: number) => {
      const url = `/brand-profile/${brandProfileId}/scenarios`
      const { data } = await userAccountAxios.get(url)
      return ScenariosSchema.parse(data)
    },
    patch: async (data: { brandProfileId: number; scenarios: Partial<ScenarioType>[] }) => {
      const scenarios = data.scenarios
      const url = `/brand-profile/${data.brandProfileId}/scenarios`
      return await userAccountAxios.patch(url, scenarios)
    }
  },
  boards: {
    get: async (brandProfileId: number | null | undefined): Promise<Board[]> => {
      if (!brandProfileId) {
        return []
      }
      const url = `/brand-profile/${brandProfileId}/board`
      const result = await userAccountAxios.get<Board[]>(url)

      if (!result) {
        return []
      }

      boardsObjValidation.validate(result.data.slice(0, 3)).catch(function (err) {
        logError(err)
        console.log(err.name, err.errors)
        console.error(
          'we received different data from the api than expected while fetching brand profile boards, see console log for more details'
        )
      })
      return result.data
    }
  }
};
