import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { competitorsObjValidation, influencersObjValidation } from '@/schemas/brandProfiles'
import Table from 'rsuite/lib/Table'
import SightlyButton from '@/components/Sightly/SightlyButton'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import { accentColor } from '@/assets/jss/colorConstants'
import { CustomWhisper } from '@/components/CustomWhisper'
import Dropdown from 'rsuite/lib/Dropdown'
import toast from 'react-hot-toast'
import QueryTypeOneModal from './QueryTypeOneModal'
import Panel from 'rsuite/lib/Panel'
import { userAccountAxios } from '@/axiosInstances'
import { typeOneSections, QUERY_TYPE_SECTION, BRAND_PROFILE_RELATIONSHIP_LOOKUP } from './constants'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import useUser from '@/hooks/useUser'
import { TableColumnJs, logError } from '@/utils'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { products, userActions } from '@/staticData/products'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import config from '@/config'
import AccordionSection from './components/AccordionSection'
import QueryTypeOneAIGenerationForm, {
  QueryTypeOneAIGenerationFormType,
  QueryTypeOneAIGenerationType
} from './QueryTypeOneAIGenerationForm'
import { AIGeneratedCell, tableHeaderStyle } from './Brand/BrandSection'
import useQueryTypeOne from './useQueryTypeOne'
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic'
import { getRelationships } from './queries-api'

export type SectionTypeOne = {
  id: number
  name: string
  twitterProfileUrl: string
  websiteUrl?: string
  wikiUrl: string
  relationshipId: number
  relationshipName?: string
  isAIGenerated: boolean
  reason?: string | null
}

const getItems = async (brandProfileId: number, queryType: typeOneSections) => {
  let url = `/brand-profile/${brandProfileId}/${queryType}`

  const result = await userAccountAxios.get<SectionTypeOne[]>(url)

  if (result.status === 200) {
    if (result.data.length > 0) {
      const validation = queryType === typeOneSections.influencers ? influencersObjValidation : competitorsObjValidation
      validation.validate(result.data).catch(function (err) {
        logError(err)
      })
    }
  }
  return result.data
}

const QueryTypeOneSection = (props: {
  queryType: typeOneSections
  isActive?: boolean
  handleAccordionClick?: () => void
}) => {
  const {
    params: { brandProfileId }
  } = useMatch<MyLocationGenerics>()
  const { accounts, user, currentAccount, perms, userCanService } = useUser()
  const queryClient = useQueryClient()
  const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
  const viewOnly = viewOnlyParam || false
  const QUERY_KEY = rqKeys.brandProfileQuery(props.queryType, Number(brandProfileId))
  const {
    isMutatingQueryType,
    queryTypeOneMutation,
    handleError
  } = useQueryTypeOne(Number(brandProfileId), props.queryType)

  const { queryResult: BrandProfileInfo } = useBrandProfileBasic(Number(brandProfileId))
  const [isAIGenerate, setIsAIGenerate] = React.useState(false)
  const {
    data: items,
    isLoading,
    isFetched
  } = useQuery(QUERY_KEY, () => getItems(Number(brandProfileId), props.queryType), {
    enabled: !!brandProfileId,
    refetchOnWindowFocus: false
  })

  const { data: relationships, isLoading: isRelationShipLoading } = useQuery(
    ['brandProfileRelationshipsLookUp'],
    () => getRelationships(),
    {
      refetchOnWindowFocus: false
    }
  )

  const [editing, setEditing] = React.useState(false)
  const [editingId, setEditingId] = React.useState<number | null>(null)

  const [adding, setAdding] = React.useState(false)

  const editingInfluencer = React.useMemo(() => {
    if (!editingId || !isFetched || !items || !editing) return null
    const item = items.filter((c: SectionTypeOne) => c.id === editingId)[0]
    if (!item) return null
    return item
  }, [editingId, editing, items])

  const handleDelete = async (id: number) => {
    const url = `/brand-profile/${brandProfileId}/${props.queryType}/${id}`
    try {
      await userAccountAxios.delete(url)
      // to fetch the latest product quota
      await queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.QUERIES))
      queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileBasic(Number(brandProfileId)) })
      toast.success('Deleted')
    } catch (err) {
      toast.error('Error')
    }
    queryClient.invalidateQueries(QUERY_KEY)
  }

  const existingQueryTypes = React.useMemo(() => {
    return (items && items.map((item: any) => item.name)) || []
  }, [items])

  const getRelationshipId = (relationshipName: string) => {
    if (!relationships) return null
    const selectedRelationship = relationships.find(
      (relationship: { id: number; relationship: string }) => relationship.relationship === relationshipName
    )
    return selectedRelationship.id || null
  }

  const handleAIGenerationFormSubmit = async (data: QueryTypeOneAIGenerationFormType) => {
    try {
      const brandProfile = BrandProfileInfo.data
    if (!brandProfile) return
    let doNotSuggest = existingQueryTypes
    const payload: QueryTypeOneAIGenerationType = {
      queryType: props.queryType,
      brandName: brandProfile.brandProfileName,
      suggestionCount: 0,
      companySummary: brandProfile.companySummary,
      companyMentality: brandProfile.companyMentality,
      existingQueryTypes: [],
      relationshipId: 0
    }
    setIsAIGenerate(false)
    if (data.queryTypeOneTargetCount) {
      const payloadTarget = { ...payload }
      payloadTarget.suggestionCount = data.queryTypeOneTargetCount
      payloadTarget.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.TARGET)
      payloadTarget.existingQueryTypes = doNotSuggest
      const resp = await queryTypeOneMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload: payloadTarget })
      doNotSuggest = resp.data.doNotSuggest
    }
    if (data.queryTypeOneBlockCount) {
      const payloadBlock = { ...payload }
      payloadBlock.suggestionCount = data.queryTypeOneBlockCount
      payloadBlock.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.BLOCK)
      payloadBlock.existingQueryTypes = doNotSuggest
      const resp = await queryTypeOneMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload: payloadBlock})
      doNotSuggest = resp.data.doNotSuggest
    }
    if (data.queryTypeOneMonitorCount) {
      const payloadMonitor = { ...payload }
      payloadMonitor.suggestionCount = data.queryTypeOneMonitorCount
      payloadMonitor.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.MONITOR)
      payloadMonitor.existingQueryTypes = doNotSuggest
      const resp = await queryTypeOneMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload: payloadMonitor })
      doNotSuggest = resp.data.doNotSuggest
    }
    } catch (error) {
      handleError(error)
    }
  }

  const descriptionText = React.useMemo(() => {
    if (props.queryType === typeOneSections.philanthropic) {
      return QUERY_TYPE_SECTION.philanthropicDescriptionText
    }
    if (props.queryType === typeOneSections.influencers) {
      return QUERY_TYPE_SECTION.influencersDescriptionText
    }
  }, [props.queryType])

  const headerText = React.useMemo(() => {
    if (props.queryType === typeOneSections.philanthropic) {
      return QUERY_TYPE_SECTION.philanthropicHeaderText
    }
    if (props.queryType === typeOneSections.influencers) {
      return QUERY_TYPE_SECTION.influencersHeaderText
    }
    return ''
  }, [props.queryType])

  return (
    <>
      {config.featureFlag.aiProfiles ? (
        <div data-testid={`accordion-${props.queryType}-section-panel`}>
          <AccordionSection
            sectionCaption={headerText}
            isActive={props.isActive ?? false}
            description={descriptionText || ''}
            canCreate={!viewOnly}
            handleAddClick={() => setAdding(true)}
            handleAIGenerate={() => {
              setIsAIGenerate(true)
            }}
            isAIGenerating={!!isMutatingQueryType}
            handleAccordionClick={props.handleAccordionClick}
          >
            <QueryTypeOneAIGenerationForm
              queryType={props.queryType}
              isAIGenerate={isAIGenerate}
              setIsAIGenerate={setIsAIGenerate}
              handleSubmitForm={handleAIGenerationFormSubmit}
            />
            {!!editingInfluencer && (
              <QueryTypeOneModal
                key="edit"
                type="edit"
                open={editing}
                handleClose={() => {
                  setEditing(false)
                  setEditingId(null)
                }}
                item={editingInfluencer}
                queryType={props.queryType}
              />
            )}

            <QueryTypeOneModal
              key="add"
              type="add"
              open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
              handleClose={() => setAdding(false)}
              queryType={props.queryType}
              item={
                props.queryType === typeOneSections.influencers
                  ? {
                      id: 456456546456456564,
                      name: '',
                      wikiUrl: '',
                      twitterProfileUrl: '',
                      relationshipId: 2,
                      isAIGenerated: false
                    }
                  : {
                      id: 456456546456456564,
                      name: '',
                      wikiUrl: '',
                      twitterProfileUrl: '',
                      websiteUrl: '',
                      relationshipId: 2,
                      isAIGenerated: false
                    }
              }
            />

            {isFetched && items && items.length > 0 && (
              <Table
                loading={isLoading}
                autoHeight
                data={items}
                bordered
                style={{
                  borderRadius: '10px',
                  boxShadow: '0px 1px 2px 0px #0000000F'
                }}
              >
                <TableColumnJs width={30}>
                  <Table.HeaderCell style={tableHeaderStyle} />
                  <AIGeneratedCell dataKey="isAIGenerated" />
                </TableColumnJs>
                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>TYPE</Table.HeaderCell>
                  <Table.Cell
                    style={{ display: 'flex', alignItems: 'center' }}
                    dataKey="relationshipName"
                  />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>NAME</Table.HeaderCell>
                  <Table.Cell>
                    {(item: SectionTypeOne) => {
                      return <>{item.name}</>
                    }}
                  </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>TWITTER</Table.HeaderCell>
                  <Table.Cell dataKey="twitterProfileUrl" />
                </TableColumnJs>

                {props.queryType !== typeOneSections.influencers && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell style={tableHeaderStyle}>WEBSITE</Table.HeaderCell>
                    <Table.Cell dataKey="websiteUrl" />
                  </TableColumnJs>
                )}

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>WIKI</Table.HeaderCell>
                  <Table.Cell dataKey="wikiUrl" />
                </TableColumnJs>

                {!viewOnly && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell style={tableHeaderStyle}></Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                      {(item: SectionTypeOne) => {
                        return (
                          <CustomWhisper
                            dropdownItems={[
                              <Dropdown.Item
                                key={1}
                                onClick={() => {
                                  setEditingId(item.id)
                                  setEditing(true)
                                }}
                              >
                                Edit
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key={2}
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            ]}
                          >
                            <IconButton
                              size="lg"
                              appearance="subtle"
                              icon={
                                <Icon
                                  icon="more"
                                  color={accentColor}
                                />
                              }
                            />
                          </CustomWhisper>
                        )
                      }}
                    </Table.Cell>
                  </TableColumnJs>
                )}
              </Table>
            )}
          </AccordionSection>
        </div>
      ) : (
        <Panel header={<div className="sightlyPanelHeader">{headerText}</div>}>
          {!!editingInfluencer && (
            <QueryTypeOneModal
              key="edit"
              type="edit"
              open={editing}
              handleClose={() => {
                setEditing(false)
                setEditingId(null)
              }}
              item={editingInfluencer}
              queryType={props.queryType}
            />
          )}

          <QueryTypeOneModal
            key="add"
            type="add"
            open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
            handleClose={() => setAdding(false)}
            item={
              props.queryType === typeOneSections.influencers
                ? {
                    id: 456456546456456564,
                    name: '',
                    wikiUrl: '',
                    twitterProfileUrl: '',
                    relationshipId: 2,
                    isAIGenerated: false
                  }
                : {
                    id: 456456546456456564,
                    name: '',
                    wikiUrl: '',
                    twitterProfileUrl: '',
                    websiteUrl: '',
                    relationshipId: 2,
                    isAIGenerated: false
                  }
            }
            queryType={props.queryType}
          />

          <div className="descriptionText">{descriptionText}</div>
          {!viewOnly && items && items.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Whisper
                delayShow={300}
                enterable={true}
                placement="auto"
                trigger="hover"
                speaker={
                  !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
                    <Tooltip>
                      <p>
                        This account has reached it’s limit.{' '}
                        <a
                          className="font-semibold"
                          target="_blank"
                          rel="noopener"
                          href="https://www.sightly.com/contact-us/"
                        >
                          Upgrade for more queries
                        </a>
                      </p>
                    </Tooltip>
                  ) : (
                    <Tooltip>Adds a new Query</Tooltip>
                  )
                }
              >
                <div>
                  <SightlyButton
                    disabled={!userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
                    handleClick={() => setAdding(true)}
                    id="addMorebutton"
                    datatestid="queriesCreateNewbutton"
                    text="Create New"
                  />
                </div>
              </Whisper>
            </div>
          )}

          {isFetched && items && items.length < 1 ? (
            <Whisper
              delayShow={300}
              enterable={true}
              placement="autoHorizontalStart"
              trigger="hover"
              speaker={
                !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
                  <Tooltip>
                    <p>
                      This account has reached it’s limit.{' '}
                      <a
                        className="font-semibold"
                        target="_blank"
                        rel="noopener"
                        href="https://www.sightly.com/contact-us/"
                      >
                        Upgrade for more queries
                      </a>
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip>Adds a new Query</Tooltip>
                )
              }
            >
              <div>
                <SightlyButton
                  disabled={
                    !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) || viewOnly
                  }
                  handleClick={() => setAdding(true)}
                  id="addMorebutton"
                  datatestid="queriesAddMorebutton"
                  type="secondary"
                  text="Add +"
                />
              </div>
            </Whisper>
          ) : (
            isFetched && (
              <Table
                loading={isLoading}
                autoHeight
                data={items}
              >
                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.Cell
                    style={{ display: 'flex', alignItems: 'center' }}
                    dataKey="relationshipName"
                  />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.Cell>
                    {(item: SectionTypeOne) => {
                      return <>{item.name}</>
                    }}
                  </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Twitter</Table.HeaderCell>
                  <Table.Cell dataKey="twitterProfileUrl" />
                </TableColumnJs>

                {props.queryType !== typeOneSections.influencers && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell>Website</Table.HeaderCell>
                    <Table.Cell dataKey="websiteUrl" />
                  </TableColumnJs>
                )}

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Wiki</Table.HeaderCell>
                  <Table.Cell dataKey="wikiUrl" />
                </TableColumnJs>

                {!viewOnly && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                      {(item: SectionTypeOne) => {
                        return (
                          <CustomWhisper
                            dropdownItems={[
                              <Dropdown.Item
                                key={1}
                                onClick={() => {
                                  setEditingId(item.id)
                                  setEditing(true)
                                }}
                              >
                                Edit
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key={2}
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            ]}
                          >
                            <IconButton
                              size="lg"
                              appearance="subtle"
                              icon={
                                <Icon
                                  icon="more"
                                  color={accentColor}
                                />
                              }
                            />
                          </CustomWhisper>
                        )
                      }}
                    </Table.Cell>
                  </TableColumnJs>
                )}
              </Table>
            )
          )}
          <style>{`
				.descriptionText {
					color: #a3a3a3;
					font-size: 14px;
					margin-top: 24px;
					margin-bottom: 24px;
					font-weight: 500;									
				}		
			`}</style>
        </Panel>
      )}
    </>
  )
}

export default QueryTypeOneSection
