import { useQuery } from "@tanstack/react-query";
import { api } from "@/views/Discover/Moments/v2/api";
import { logError } from "@/utils";
import { ClusterType, MomentActionsEnum } from "@/views/Discover/Moments/v2/types";
import { rqKeys } from "@/views/Discover/Moments/v2/rqKeys";

interface IProps {
    brandProfileId: number | undefined
    moment: ClusterType
}

interface ScenariosIProps extends IProps {
    brandProfileId: number | undefined
    moment: ClusterType
    setHasUnsavedChanges: Function
}
export const useArticles = ({brandProfileId, moment}: IProps) => {
    return useQuery(
        ['momentArticles', brandProfileId, moment?.clusterId],
        () => api.moments.articles.get({ clusterId: moment?.clusterId, brandProfileId }),
        {
            enabled: !!moment?.clusterId && !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'articlesQuery' })
            }
        }
    )
}

export const useKeywords = ({brandProfileId, moment}: IProps) => {
    const getRecommendedActionText = (action:string):MomentActionsEnum | undefined => {
        const actionLowerCase = action.toLowerCase()
        if (['No Action', 'no action','noaction','no_action','dont monitor','dont_monitor'].includes(actionLowerCase)) return 'noaction'
        if(actionLowerCase === 'target') return 'target'
        if(actionLowerCase === 'block') return 'block'
        if(actionLowerCase === 'monitor') return 'monitor'
        return
    }

    return useQuery(
        rqKeys.momentKeywordsKey(brandProfileId, moment?.clusterId),
        () => api.moments.keywords.get({ clusterId: moment?.clusterId, brandProfileId, momentAction: getRecommendedActionText(moment.action)}),
        {
            enabled: !!moment?.clusterId && !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'keywordsQuery' })
            }
        }
    )
}

export const useVideos = ({brandProfileId, moment}: IProps) => {
    return useQuery(
        rqKeys.momentVideosKey(brandProfileId, moment?.clusterId),
        () => api.moments.videos.get({ clusterId: moment?.clusterId, brandProfileId }),
        {
            enabled: !!moment?.clusterId && !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'videosQuery' })
            }
        }
    )
}

export const useScenarios = ({brandProfileId, moment, setHasUnsavedChanges}: ScenariosIProps) => {
    return useQuery(
        rqKeys.momentScenariosKey(brandProfileId, moment?.clusterId),
        () => api.moments.scenarios.get({ clusterId: moment?.clusterId, brandProfileId }),
        {
            refetchOnWindowFocus: false,
            enabled: !!moment?.clusterId && !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'scenariosQuery' })
            },
            onSettled: () => {
                setHasUnsavedChanges(false)
            }

        }
    )
}

export const useScenarioOptions = ({brandProfileId, moment}: IProps) => {
    return useQuery(
        rqKeys.momentScenariosOptionsKey(brandProfileId, moment?.clusterId),
        () => api.moments.scenariosOptions.get({ clusterId: moment?.clusterId, brandProfileId }),
        {
            refetchOnWindowFocus: false,
            enabled: !!moment?.clusterId && !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'scenariosOptionsQuery' })
            }
        }
    )
}
export const useActionJustifications = ({brandProfileId, moment}: IProps) => {
    return useQuery(
        rqKeys.momentActionJustificationsKey(brandProfileId, moment?.clusterId),
        () => api.moments.actionJustification.get({ clusterId: moment?.clusterId, brandProfileId }),
        {
            refetchOnWindowFocus: false,
            enabled: !!moment?.clusterId && !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'actionJustificationsQuery' })
            }
        }
    )
}
