import {
    SET_BRAND_PROFILE_LOADING,
    SET_BRAND_PROFILE_SAVING,
    SET_BRAND_PROFILE_SAVED,
    SCENARIOS_IS_LOADING,
    SET_BRAND_PROFILE_UNDER_EDIT,
    SET_BRAND_PROFILE_IAB_CATEGORIES,
    SET_BRAND_PROFILE_TOPICS,
    SET_IS_DOWNLOADING_BRAND_PROFILE,
    SET_DOWNLOADING_BRAND_PROFILE_ID,
    SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING,
    SET_BRAND_PROFILE_TOPICS_LOADING,
    SET_BRAND_PROFILE_OUTCOMES,
    ADD_OUTCOME,
    SET_OUTCOME_METRIC_OPTIONS
} from '../action-types/brandProfiles'

export function brandProfileUnderEdit(
    state = {
        submitted: null,
        brandProfileName: '',
        websiteUrl: '',
        aylienIndustryId: null,
        twitterProfileUrl: '',
        brandProfileId: ''
    },
    action
) {
    switch (action.type) {
        case SET_BRAND_PROFILE_UNDER_EDIT:
            return action.brandProfileUnderEdit

        case SET_BRAND_PROFILE_OUTCOMES:
            return {
                ...state,
                outcomes: action.outcomes
            }

        case SET_BRAND_PROFILE_IAB_CATEGORIES:
            return {
                ...state,
                iabCategories: action.iabCategories
            }
        case SET_BRAND_PROFILE_TOPICS:
            return {
                ...state,
                topics: action.topics
            }

        default:
            return state
    }
}

export function brandProfileSaving(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_SAVING:
            return action.brandProfileSaving
        default:
            return state
    }
}
export function brandProfileSaved(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_SAVED:
            return action.brandProfileSaved
        default:
            return state
    }
}

export function outcomeMetricOptions(state = [], action) {
    switch (action.type) {
        case SET_OUTCOME_METRIC_OPTIONS:
            return action.outcomeMetricOptions
        default:
            return state
    }
}

export function brandProfileLoading(state = true, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_LOADING:
            return action.brandProfileLoading
        default:
            return state
    }
}

export function brandProfileIabCategoriesLoading(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING:
            return action.iabCategoriesLoading
        default:
            return state
    }
}

export function brandProfileTopicsLoading(state = false, action) {
    switch (action.type) {
        case SET_BRAND_PROFILE_TOPICS_LOADING:
            return action.topicsLoading
        default:
            return state
    }
}

export function isDownloadingBrandProfile(state = false, action) {
    switch (action.type) {
        case SET_IS_DOWNLOADING_BRAND_PROFILE:
            return action.isDownloadingBrandProfile
        default:
            return state
    }
}

export function downloadingBrandProfileId(state = null, action) {
    switch (action.type) {
        case SET_DOWNLOADING_BRAND_PROFILE_ID:
            return action.downloadingBrandProfileId
        default:
            return state
    }
}
