import {
    SET_BRAND_PROFILE_LOADING,
    SET_BRAND_PROFILE_SAVING,
    SET_BRAND_PROFILE_SAVED,
    SCENARIOS_IS_LOADING,
    SET_BRAND_PROFILE_UNDER_EDIT,
    SET_BRAND_PROFILE_IAB_CATEGORIES,
    SET_BRAND_PROFILE_OUTCOMES,
    SET_BRAND_PROFILE_TOPICS,
    SET_DOWNLOADING_BRAND_PROFILE_ID,
    SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING,
    SET_IS_DOWNLOADING_BRAND_PROFILE,
    SET_BRAND_PROFILE_TOPICS_LOADING,
    ADD_OUTCOME,
    SET_OUTCOME_METRIC_OPTIONS
} from '../action-types/brandProfiles'
import toast from 'react-hot-toast'
import {
    topicsObjValidation,
    iabCategoriesObjValidation,
    outcomesObjValidation,
    outcomeMetricOptionsObj
} from '../../schemas/brandProfiles'
import { userAccountAxios } from '../../axiosInstances'
import { api } from '../../api/api'
import { logError } from '../../utils'

var fileDownload = require('js-file-download')

export const patchBrandProfileKeywords = ({ keywords, brandProfileId }) => {
    return async (dispatch, getState) => {
        let url = `/brand-profile/${brandProfileId}/keywords`
        await userAccountAxios.patch(url, keywords)
        toast.success('Changes saved')
    }
}

export function fetchBrandProfileIabCategories(args) {
    let brandProfileId = args.brandProfileId
    let url = `/brand-profile/${brandProfileId}/iab-categories`
    return async (dispatch) => {
        dispatch(setBrandProfileIabCategoriesLoading(true))
        const result = await userAccountAxios.post(url, args)
        if (result.status === 200) {
            iabCategoriesObjValidation.validate(result.data.slice(0, 3)).catch(function (err) {
                console.log(err.name, err.errors)
                console.error(
                    ' we received different data from the api than expected while fetching brand profile  categories, see console log for more details'
                )
            })

            dispatch(setBrandProfileIabCategories(result.data))
            dispatch(setBrandProfileIabCategoriesLoading(false))
        } else {
            dispatch(setBrandProfileIabCategoriesLoading(false))
        }
    }
}

export function fetchBrandProfileTopics(args) {
    let url = `/brand-profile/${args.brandProfileId}/topics`
    return async (dispatch) => {
        dispatch(setBrandProfileTopicsLoading(true))
        try {
            const result = await userAccountAxios.post(url, args)
            if (result.status === 200) {
                topicsObjValidation.validate(result.data.slice(0, 3)).catch(function (err) {
                    console.log(err.name, err.errors)
                    console.error(
                        ' we received different data from the api than expected while fetching brand profile topics, see console log for more details'
                    )
                })
                dispatch(setBrandProfileTopics(result.data))
                dispatch(setBrandProfileTopicsLoading(false))
            }
        } catch (error) {
            console.error(error)
            dispatch(setBrandProfileTopicsLoading(false))
        }
    }
}

export function fetchOutcomeMetricOptions() {
    let url = `/brand-profile/metrics-options`
    return async (dispatch) => {
        try {
            const result = await userAccountAxios.get(url)
            if (result.status === 200) {
                outcomeMetricOptionsObj.validate(result.data).catch(function (err) {
                    console.log(err.name, err.errors)
                    console.error(
                        'we received different data from the api than expected while fetching brand profile outcome metrics, see console log for more details'
                    )
                })
                dispatch(setOutcomeMetricOptions(result.data))
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export function setOutcomeMetricOptions(outcomeMetricOptions) {
    return {
        type: SET_OUTCOME_METRIC_OPTIONS,
        outcomeMetricOptions
    }
}

export function setBrandProfileUnderEdit(brandProfileUnderEdit) {
    return {
        type: SET_BRAND_PROFILE_UNDER_EDIT,
        brandProfileUnderEdit
    }
}

export function addOutcome() {
    return {
        type: ADD_OUTCOME,
        action: 'adding a blank outcome'
    }
}

export function setBrandProfileIabCategories(iabCategories) {
    return {
        type: SET_BRAND_PROFILE_IAB_CATEGORIES,
        iabCategories
    }
}

export function setBrandProfileIabCategoriesLoading(iabCategoriesLoading) {
    return {
        type: SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING,
        iabCategoriesLoading
    }
}

export function setBrandProfileOutcomes(outcomes) {
    return {
        type: SET_BRAND_PROFILE_OUTCOMES,
        outcomes
    }
}

export function setBrandProfileTopics(topics) {
    return {
        type: SET_BRAND_PROFILE_TOPICS,
        topics
    }
}

export function setBrandProfileTopicsLoading(topicsLoading) {
    return {
        type: SET_BRAND_PROFILE_TOPICS_LOADING,
        topicsLoading
    }
}

export const patchBrandProfileOutcomes = (_outcome, brandProfileId) => {
    if (_outcome.primaryMetricValue === '') {
        _outcome.primaryMetricValue = null
    }
    if (_outcome.secondaryMetricValue === '') {
        _outcome.secondaryMetricValue = null
    }
    if (_outcome.tertiaryMetricValue === '') {
        _outcome.tertiaryMetricValue = null
    }

    return async (dispatch, getState) => {
        let bp = JSON.parse(JSON.stringify(getState().brandProfileUnderEdit))
        let newOutcomes = bp.outcomes.map((outcome) => {
            if (outcome.outcomeId === _outcome.outcomeId) {
                outcome = _outcome
            }
            return outcome
        })

        bp.outcomes = newOutcomes

        dispatch(setBrandProfileUnderEdit(bp))
        dispatch(setBrandProfileSaving(true))
        let url = `/brand-profile/${brandProfileId}/outcomes`
        const result = await userAccountAxios.patch(url, bp.outcomes)
        if (result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const deleteBrandProfileOutcome = (args) => {
    const { brandProfileId, outcomeId } = args
    return async (dispatch, getState) => {
        let bp = JSON.parse(JSON.stringify(getState().brandProfileUnderEdit))
        let newOutcomes = bp.outcomes.filter((o) => o.outcomeId !== outcomeId)
        bp.outcomes = newOutcomes
        dispatch(setBrandProfileUnderEdit(bp))
        dispatch(setBrandProfileSaving(true))
        let url = `/brand-profile/${brandProfileId}/outcomes`
        const result = await userAccountAxios.patch(url, bp.outcomes)
        if (result.status === 200) {
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const patchBrandProfileIabCategories = (data) => {
    let brandProfileId = data.brandProfileId
    let iabCategories = data.iabCategories

    let url = `/brand-profile/${brandProfileId}/iab-categories`
    return async (dispatch) => {
        dispatch(setBrandProfileSaving(true))

        const result = await userAccountAxios.patch(url, iabCategories)
        if (result.status === 201 || result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const patchBrandProfileTopics = (data) => {
    let brandProfileId = data.brandProfileId
    let topics = data.topics
    let url = `/brand-profile/${brandProfileId}/topics`
    return async (dispatch) => {
        dispatch(setBrandProfileSaving(true))
        const result = await userAccountAxios.patch(url, topics)
        if (result.status === 201 || result.status === 200) {
            toast.success('Changes saved')
            dispatch(setBrandProfileSaving(false))
            dispatch(setBrandProfileSaved(true))
        }
    }
}

export const deleteBrandProfile = (brandProfileId) => {
    let url = `/brand-profile/${brandProfileId}/archive`

    return (dispatch, getState) => {
        userAccountAxios
            .patch(url)
            .then((response) => {})
            .catch((error) => {
                console.error(error)
            })
    }
}

export function downloadBrandProfile(payload) {
    let brandProfileId = payload.brandProfileId
    let brandProfileName = payload.brandProfileName
    let url = `/brand-profile/${brandProfileId}/download`
    if (payload.scenarioDownloadSort === 'response' && payload.opinionDownloadSort != 'response') {
        url = `/brand-profile/${brandProfileId}/download?scenarioOrderBy=response`
    }

    if (payload.scenarioDownloadSort != 'response' && payload.opinionDownloadSort === 'response') {
        url = `/brand-profile/${brandProfileId}/download?opinionOrderBy=response`
    }

    if (payload.scenarioDownloadSort === 'response' && payload.opinionDownloadSort === 'response') {
        url = `/brand-profile/${brandProfileId}/download?opinionOrderBy=response&scenarioOrderBy=response`
    }

    return (dispatch) => {
        userAccountAxios
            .get(url, { responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    fileDownload(response.data, `${brandProfileName}.zip`)
                } else {
                    toast.error('We were unable to download this brand profile')
                }

                dispatch(setIsDownloadingBrandProfile(false))
                dispatch(setDownloadingBrandProfileId(null))
            })
            .catch((error) => {
                dispatch(setIsDownloadingBrandProfile(false))
                dispatch(setDownloadingBrandProfileId(null))
                console.error('download brand profile error', error)
            })
    }
}

export function setBrandProfileLoading(bool) {
    return {
        type: SET_BRAND_PROFILE_LOADING,
        brandProfileLoading: bool
    }
}

export function setBrandProfileSaving(bool) {
    return {
        type: SET_BRAND_PROFILE_SAVING,
        brandProfileSaving: bool
    }
}
export function setBrandProfileSaved(bool) {
    return {
        type: SET_BRAND_PROFILE_SAVED,
        brandProfileSaved: bool
    }
}

export function setIsDownloadingBrandProfile(isDownloadingBrandProfile) {
    return {
        type: SET_IS_DOWNLOADING_BRAND_PROFILE,
        isDownloadingBrandProfile
    }
}

export function setDownloadingBrandProfileId(downloadingBrandProfileId) {
    return {
        type: SET_DOWNLOADING_BRAND_PROFILE_ID,
        downloadingBrandProfileId
    }
}
