import { z } from 'zod'
import { listBuilderAxios, userAccountAxios } from '@/axiosInstances'
import {
  ActivationAdGroupsResultSchema,
  ActivationItemsSchema,
  GetActivationsPropsType,
  PatchActivationsItemType
} from '@/views/Discover/Activations/v2/activations-types'
import { logError } from '@/utils'
import { paramsSerializer } from '@/utils_ts'
var fileDownload = require('js-file-download')

const StatusCountsSchema = z.object({
  activeCount: z.number(),
  pendingCount: z.number(),
  completeCount: z.number()
})

const ActivationsStatusSchema = z.array(
  z.object({
    brandProfileId: z.number(),
    counts: z.object({
      activeCount: z.number(),
      pendingCount: z.number(),
      completeCount: z.number()
    }),
    lastCreatedActivation: z.string()
  })
)

interface DownloadActivationProps {
  brandProfileId: number
  clusterIds: number[]
  activationState: string
}

export const activationsApi = {
  getBrandProfilesActivationStatus: async ({ brandProfileIds }: { brandProfileIds: number[] | undefined }) => {
    try {
      if (!brandProfileIds?.length) return undefined
      const url = `/moment-clusters/activations-status`
      const res = await listBuilderAxios.get(url, {
        params: { brandProfileIds },
        paramsSerializer
      })
      const data = ActivationsStatusSchema.parse(res.data)
      return new Map(data.map((status) => [status.brandProfileId, status.counts]))
    } catch (error) {
      logError(error)
    }
  },
  getActivations: async ({ brandProfileId, page, size, status }: GetActivationsPropsType) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
      const res = await listBuilderAxios.get(url, {
        params: {
          page,
          size,
          status
        }
      })
      return ActivationItemsSchema.parse(res.data)
    } catch (error) {
      logError(error)
    }
  },
  getTodoActivations: async ({ brandProfileId }: { brandProfileId: number | undefined }) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
      const res = await listBuilderAxios.get(url, {
        params: {
          page: 1,
          size: 1000000,
          status: 'active'
        }
      })
      return ActivationItemsSchema.parse(res.data)
    } catch (error) {
      logError(error)
    }
  },
  getInProcessActivations: async ({ brandProfileId }: { brandProfileId: number | undefined }) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
      const res = await listBuilderAxios.get(url, {
        params: {
          page: 1,
          size: 1000000,
          status: 'pending'
        }
      })
      return ActivationItemsSchema.parse(res.data)
    } catch (error) {
      logError(error)
    }
  },
  getImplementedActivations: async ({ brandProfileId }: { brandProfileId: number | undefined }) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
      const res = await listBuilderAxios.get(url, {
        params: {
          page: 1,
          size: 1000000,
          status: 'complete'
        }
      })
      return ActivationItemsSchema.parse(res.data)
    } catch (error) {
      logError(error)
    }
  },
  getInsertionOrderHierarchy: async ({ ioIds }: { ioIds: Array<string> }) => {
    try {
      if (ioIds.length <= 0) return undefined
      const ioIdsParam = ioIds.map((curr) => `ioIds[]=${curr}`).join('&')
      const url = `/account/hierarchy?${ioIdsParam}`
      const res = await userAccountAxios.get(url)
      return ActivationAdGroupsResultSchema.parse(res.data)
    } catch (error) {
      logError(error)
    }
  },
  patchActivations: async ({
    brandProfileId,
    activations
  }: {
    brandProfileId: number | undefined
    activations: PatchActivationsItemType[]
  }) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list/v2`
      const res = await listBuilderAxios.patch(url, activations)
      return res
    } catch (error) {
      logError(error)
    }
  },
  changeStatusToPending: async ({
    brandProfileId,
    activations
  }: {
    brandProfileId: number | undefined
    activations: number[]
  }) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list/update-status-pending`
      const res = await listBuilderAxios.patch(url, { targetListIds: activations })
      return res
    } catch (error) {
      logError(error)
    }
  },
  removeItemFromTodo: async ({
    brandProfileId,
    activationListItemId
  }: {
    brandProfileId: number | undefined
    activationListItemId: number
  }) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list/${activationListItemId}`
      const res = await listBuilderAxios.delete(url)
      return res.data
    } catch (error) {
      logError(error)
    }
  },
  bulkDeleteFromTodo: async ({
    brandProfileId,
    activationListItemIds
  }: {
    brandProfileId: number | undefined
    activationListItemIds: Set<number>
  }) => {
    try {
      if (!brandProfileId) return undefined
      const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list`
      const res = await listBuilderAxios.delete(url, {
        params: { activationListItemIds: Array.from(activationListItemIds) },
        paramsSerializer
      })
      return res.data
    } catch (error) {
      logError(error)
    }
  },
  downloadActivations: async ({ brandProfileId, clusterIds, activationState }: DownloadActivationProps) => {
    try {
      const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-download`
      let data = { clusterIds, activationState }
      const result = await listBuilderAxios({
        method: 'POST',
        url,
        responseType: 'blob',
        data
      })
      if (!result) {
        return Promise.reject('Unknown download error')
      }
      fileDownload(result.data, `CurrentMomentsActivations.xlsx`)
    } catch (error) {
      logError(error)
    }
  }
}
