export const SET_BRAND_PROFILE_LOADING = 'SET_BRAND_PROFILE_LOADING'
export const SET_BRAND_PROFILE_SAVING = 'SET_BRAND_PROFILE_SAVING'
export const SET_BRAND_PROFILE_SAVED = 'SET_BRAND_PROFILE_SAVED'
export const SCENARIOS_IS_LOADING = 'SCENARIOS_IS_LOADING'
export const SET_BRAND_PROFILE_UNDER_EDIT = 'SET_BRAND_PROFILE_UNDER_EDIT'
export const SET_BRAND_PROFILE_IAB_CATEGORIES = 'SET_BRAND_PROFILE_IAB_CATEGORIES'
export const SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING = 'SET_BRAND_PROFILE_IAB_CATEGORIES_LOADING'
export const SET_BRAND_PROFILE_TOPICS = 'SET_BRAND_PROFILE_TOPICS'
export const SET_BRAND_PROFILE_TOPICS_LOADING = 'SET_BRAND_PROFILE_TOPICS_LOADING'
export const SET_BRAND_PROFILE_OUTCOMES = 'SET_BRAND_PROFILE_OUTCOMES'
export const SET_IS_DOWNLOADING_BRAND_PROFILE = 'SET_IS_DOWNLOADING_BRAND_PROFILE'
export const SET_DOWNLOADING_BRAND_PROFILE_ID = 'SET_DOWNLOADING_BRAND_PROFILE_ID'
export const ADD_OUTCOME = 'ADD_OUTCOME'
export const SET_OUTCOME_METRIC_OPTIONS = 'SET_OUTCOME_METRIC_OPTIONS'
