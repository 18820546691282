import { BoltIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate, useSearch } from '@tanstack/react-location'
import React, { Dispatch, SetStateAction } from 'react'
import { MyLocationGenerics } from '../../../classes/utils'
import { LoadingPage } from '../../../components/LoadingPage'
import SightlyCheckbox from '../../../components/Sightly/SightlyFormElements/SightlyCheckbox'
import SightlyAvatar from '../../../components/SightlyAvatar'
import SightlyButton from '../../../components/Sightly/SightlyButton'
import { SmallBadge } from '../../../components/SmallClosableBadge'
import { EditSlideOver } from './EditSlideover'
import { ReactComponent as KeywordsIcon } from '../../../assets/img/keywordsKey.svg'
import { ReactComponent as BlackYoutube } from '../../../assets/img/blackYoutube.svg'
import { useTodoActivations } from './activations-hooks'
import { joinClasses } from '../../../utils'
import { InfoPage } from '../../../components/InfoPage'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { MessageTailwind } from '../../../components/MessageTailwind'
import PortalModal from '../../../components/PortalModal/PortalModal'
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

interface IProps {
    defaultBrandProfileId:number | undefined
    handleDownloadActivations:Function
    selectedActivationIds: number[]
    setSelectedActivationIds: Dispatch<SetStateAction<number[]>>
}

export const Todo = ({
        defaultBrandProfileId,
        handleDownloadActivations,
        selectedActivationIds,
        setSelectedActivationIds}:IProps) => {

    const { brandProfileId } = useSearch<MyLocationGenerics>()
    const navigate = useNavigate()
    React.useEffect(()=>{

        if(!brandProfileId && defaultBrandProfileId) {
            navigate({
                search: (old) => {
                    return {
                        ...old,
                        brandProfileId: defaultBrandProfileId
                    }
                }
            })
        }
    },[])
    const [isEditOpen, setIsEditOpen] = React.useState(false)
    const { perms, userPermissions } = useUser()
    const { activationsQuery, removeItemFromTodo } = useTodoActivations({
        brandProfileId: Number(brandProfileId)
    })
    const [downloadingTrends, setDownloadingTrends] = React.useState(false)

    const [showTooManyTrendsError, setShowTooManyTrendsError] = React.useState(false)


    const allMomentsOnPageAreSelected = React.useMemo(() => {
        if (selectedActivationIds.length === 0) {
            return false
        }

        const notIncludedInSelectedItems = activationsQuery.data
            ?.map((m) => m.clusterId)
            ?.filter((id) => !selectedActivationIds.includes(id))
        if (notIncludedInSelectedItems === undefined) {
            return true
        }
        if (notIncludedInSelectedItems.length > 0) {
            return false
        }
        return true
    }, [activationsQuery.data, selectedActivationIds])

    if (activationsQuery.isLoading) {
        return (
            <div style={{ width: '100%', height: '80vh' }}>
                <LoadingPage message="Fetching Activations" />
            </div>
        )
    }
    if (activationsQuery?.data && activationsQuery?.data.length === 0 && activationsQuery.isFetched) {
        return (
            <div style={{ width: '100%', height: 300 }}>
                {' '}
                <InfoPage
                    message={
                        <div>
                            <span>
                                No items found.{' '}
                                <span>
                                    <Link to={'/app/discover/moments/v2'}>Click here</Link>
                                </span>{' '}
                                to add some
                            </span>
                            {/* <div>No items found. Click here to add some</div> */}
                        </div>
                    }
                />{' '}
            </div>
        )
    }
    return (
        <div className="mt-8 flex flex-col fade-in animate-in">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full  align-middle md:px-6 lg:px-8">
                    <div className="w-full flex justify-between mb-10 h-10">
                        <div >
                            <MessageTailwind
                                show={downloadingTrends}
                                message="Downloading moments"
                                type="loading"
                            />
                        </div>
                        <div className="flex justify-end space-x-5">
                            {userCanPermissionProductQuota({
                                requiredPermissionValue: perms.TRENDS_DOWNLOAD,
                                userPermissions: userPermissions,
                                checkType: PermissionCheckType.PERMISSION_CHECK
                            }) && (
                                <SightlyButton
                                    handleClick={() => handleDownloadActivations({
                                                            brandProfileId,
                                                            setDownloadingTrends,
                                                            setShowTooManyTrendsError,
                                                            selectedMomentIds: activationsQuery?.data
                                                                                    ?.filter(i => selectedActivationIds.includes(i.activationItemId))
                                                                                    .map( i => i.clusterId) || [],
                                                            activationState: 'active'})}
                                    id="downloadActivation"
                                    text="Download"
                                    disabled={selectedActivationIds.length < 1 || (activationsQuery?.data && activationsQuery?.data.length === 0) || activationsQuery.isLoading }
                                    icon = {
                                        <ArrowDownTrayIcon
                                            className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                            aria-hidden="true"
                                        />}
                                />
                            )}
                            <SightlyButton
                                handleClick={() => setIsEditOpen(true)}
                                id="implement"
                                text="Implement"
                                disabled={selectedActivationIds.length < 1}
                            />
                        </div>
                    </div>

                    <div className="my-3 relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                                    >
                                        <SightlyCheckbox
                                            indeterminate={selectedActivationIds.length > 0 && !allMomentsOnPageAreSelected}
                                            handleChange={(checked: boolean) => {
                                                if (checked) {
                                                    setSelectedActivationIds(
                                                        activationsQuery?.data?.map((i) => i.activationItemId) || []
                                                    )
                                                } else {
                                                    setSelectedActivationIds([])
                                                }
                                            }}
                                            id="selectAllTodo"
                                            checked={activationsQuery?.data?.length === selectedActivationIds.length}
                                        />
                                    </th>
                                    <th
                                        scope="col"
                                        className=" py-3.5 pr-3 text-left text-sm font-semibold text-gray-600"
                                    ></th>
                                    <th
                                        scope="col"
                                        className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-600"
                                    >
                                        MOMENT
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                                    >
                                        PERSONAS
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                                    >
                                        ASSIGNEES
                                    </th>

                                    <th
                                        scope="col"
                                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {activationsQuery?.data?.map((activationItem) => (
                                    <tr
                                        key={activationItem.activationItemId}
                                        className={'bg-gray-50 fade-in animate-in'}
                                    >
                                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                            <SightlyCheckbox
                                                handleChange={(checked: boolean) => {
                                                    const newItems = checked
                                                        ? selectedActivationIds.concat(activationItem.activationItemId)
                                                        : selectedActivationIds.filter(
                                                              (id) => id !== activationItem.activationItemId
                                                          )
                                                    setSelectedActivationIds(newItems)
                                                }}
                                                id="select"
                                                checked={selectedActivationIds.includes(activationItem.activationItemId)}
                                            />
                                        </td>
                                        <td className={'whitespace-nowrap py-4 pr-3 text-sm font-medium max-w-min'}>
                                            <BoltIcon className="h-4 text-sightly-blue" />
                                        </td>
                                        <td
                                            className={
                                                'whitespace-nowrap py-4 pr-3 text-sm font-medium truncate max-w-lg'
                                            }
                                        >
                                            <div className="truncate">{activationItem.clusterName}</div>
                                            <div className="statsContainer mt-2">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <KeywordsIcon />
                                                    <div className="text-xs text-gray-500 pl-1">
                                                        {activationItem.keywordCount === 1
                                                            ? '1 keyword'
                                                            : activationItem.keywordCount + ' keywords'}
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <BlackYoutube />
                                                    <div className="text-xs text-gray-500 pl-1">
                                                        {activationItem.videoCount === 1
                                                            ? '1 video'
                                                            : activationItem.videoCount + ' videos'}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <div className="flex gap-2 items-center">
                                                {activationItem.personas.slice(0, 2).map((persona, index) => {
                                                    return (
                                                        <SmallBadge
                                                            key={`${activationItem.activationItemId}_${persona}_${index}`}
                                                            text={persona}
                                                            onClose={() => undefined}
                                                            disabled={true}
                                                        />
                                                    )
                                                })}
                                                {activationItem.personas.length > 2 && (
                                                    <div className="text-xs">
                                                        + {activationItem.personas.length - 2} others
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <div className="mt-4 flex-shrink-0 sm:mt-0  flex items-center gap-2">
                                                <div className="flex -space-x-1 ">
                                                    {activationItem.assignees.slice(0, 2).map((assignee) => {
                                                        return (
                                                            <div
                                                                key={assignee}
                                                                className="inline-block  rounded-full ring-2 ring-white"
                                                            >
                                                                <SightlyAvatar
                                                                    text={assignee}
                                                                    size="xs"
                                                                    type={null}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {activationItem.assignees.length > 2 && (
                                                    <div className="text-xs">
                                                        + {activationItem.assignees.length - 2} others
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <div
                                                className={joinClasses(
                                                    'text-sightly-blue hover:cursor-pointer',
                                                    selectedActivationIds.includes(activationItem.activationItemId) &&
                                                        'hover:cursor-not-allowed'
                                                )}
                                                onClick={() => {
                                                    if (selectedActivationIds.includes(activationItem.activationItemId)) {
                                                        return
                                                    }
                                                    removeItemFromTodo.mutate({
                                                        brandProfileId,
                                                        activationListItemId: activationItem.activationItemId
                                                    })
                                                }}
                                            >
                                                Remove
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PortalModal
                        hasClose
                        open={showTooManyTrendsError}
                        handleClose={() => setShowTooManyTrendsError(false)}
                        footerButtons={[]}
                    >
                        <div
                            style={{
                                width: 500,
                                padding: 24,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 48
                            }}
                        >
                            <h4>Unable to download</h4>
                            <div>
                                Our system can only handle downloading 1,000 moments maximum. Please add more filters and
                                try again.
                            </div>
                        </div>
                    </PortalModal>
                </div>
            </div>
            <style>
                {`
                  .statsContainer {
                      display: grid;
                      grid-template-columns: 130px 130px 130px;
                      grid-auto-flow: row;	
                  }
                `}
            </style>
            <EditSlideOver
                open={isEditOpen}
                setOpen={setIsEditOpen}
                setSelectedItemIds={setSelectedActivationIds}
                items={activationsQuery?.data?.filter((item) => selectedActivationIds.includes(item.activationItemId)) || []}
            />
        </div>
    )
}
