import React from 'react'
import { LoadingPage } from '@/components/LoadingPage'
import MomentVideo from './MomentVideo'
import { ClusterType, MomentVideoType } from './../types'

interface MomentVideosProps {
    moment: ClusterType,
    videos: MomentVideoType[]
    isLoading: boolean
}
const MomentVideos = ({ videos, moment, isLoading }: MomentVideosProps) => {
    return (
        <div data-testid="moment-modal-body-videos" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div data-testid="moment-modal-videos-count">
                {moment.videoCount === 0 && !isLoading && (
                    <div
                        style={{
                            height: 28,
                            paddingRight: 8,
                            borderRadius: 3,
                            backgroundColor: '#F2FAFD',
                            paddingTop: 3,
                            paddingLeft: 8,
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '22px',
                            marginTop: 24
                        }}
                    >
                        Currently we have no Videos for this Moment.
                    </div>
                )}
                {moment.videoCount > 20 && !isLoading && (
                    <div
                        style={{
                            height: 28,
                            width: 'fit-content',
                            paddingRight: 8,
                            borderRadius: 3,
                            backgroundColor: '#F2FAFD',
                            paddingTop: 3,
                            paddingLeft: 8,
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '22px',
                        }}
                    >
                        Showing 20 of {moment.videoCount} videos. To see all videos, please download the moment.
                    </div>
                )}

                <div
                    id="videosTab"
                    data-testid="moment-modal-videos-tab"
                    style={{
                        overflowY: 'auto',
                        marginTop: 24,
                        textAlign: 'left'
                    }}
                >
                    {isLoading ? (
                        <LoadingPage message="Fetching videos" />
                    ) : (
                        <>
                            {videos &&
                                videos.slice(0, 20).map((video, index) => {
                                    return (
                                        <MomentVideo
                                            key={index + video.videoId}
                                            video={video}
                                            clusterId={moment.clusterId}
                                        />
                                    )
                                })}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MomentVideos
