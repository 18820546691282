import React, { useState } from 'react';
import NavigationItem, { NavigationItemProps } from '@/components/Navbars/NavigationItem';
import { useNavigate } from '@tanstack/react-location';
import { routeIsActive } from '@/utils_ts';
import useUser from '@/hooks/useUser';
import debounce from 'just-debounce-it';
import roundButtonOpen from '@/assets/img/navigationBar/round_button_right.svg'
import roundButtonClose from '@/assets/img/navigationBar/round_button_left.svg'
import { connect } from 'react-redux';
import { toggleMenu } from '@/redux/actions/layout';
import {setZendeskPosition} from "@/components/HelpWidgets/ZendeskWidget";

/**
 * The navigation bar component will receive arguments detailing layout orientation and list item details. There will be optional arguments including a bottom set of list items (for settings and logout).
 * Arguments
 * isMinimized: (boolean, optional, default false) when true, the navigation bar is in its minimized state. When false, the navigation bar is in its full size state.
 * orientation: (string, enum vertical, horizontal, optional, default vertical) this governs the direction the navigation bar is displayed.
 * navigationItems: (navigationItem[]) the list of navigation items to display.
 * secondaryNavigationItems: (navigationItem[], optional) the bottom list of navigation items to display.
 * headerImage: (svg) logo image to use as the header
 **/

interface NavigationBarProps {
  orientation: string
  navigationItems: NavigationItemProps[]
  secondaryNavigationItems?: NavigationItemProps[]
  headerImage?: React.ReactNode
  minimizedHeaderImage?: React.ReactNode
  isMinimized: boolean
  toggleMenu: Function
}

const mapStateToProps = (state: any) => {
  return {
    isMinimized: state.layoutReducer.isMinimized
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleMenu: () => dispatch(toggleMenu())
  }
}

function NavigationBar({ orientation, navigationItems, secondaryNavigationItems, headerImage, minimizedHeaderImage, isMinimized, toggleMenu }: NavigationBarProps) {
  const { userPermissions, userProducts } = useUser();
  const navigate = useNavigate();

  // Add a light debounce to help reduce the navigation strain from users who
  // click many navigation items in very quick succession
  const handleNavigate = React.useCallback(
    debounce((navigationTarget: string, searchCallback: (search: any) => any) => {
      navigate({
        to: navigationTarget,
        search: searchCallback
      });
    }, 5),
    []
  );

  const widthClass = isMinimized
    ? 'w-[94px]'
    : 'w-[187px]';

  return <div className={`${widthClass} transition-all sightlyNavBar flex flex-col max-h-screen`}>
    <div className="p-2 mb-2 mt-4 justify-center flex-[0_0_auto] h-[46px]">
      {isMinimized ? minimizedHeaderImage : headerImage}
    </div>
    <div className={`navItemScrollHide flex-auto ${!isMinimized ? 'overflow-y-scroll overflow-x-hidden' : ''}`}>
      {navigationItems && navigationItems.length && navigationItems.map((navItem, index) => {
        const isActiveTargets: string[] = generateActiveTargetsList(navItem.activeTargets, navItem.navigationTarget);
        return <NavigationItem
          displayText={navItem.displayText}
          itemKey={index.toString()}
          key={index.toString()}
          icon={navItem.icon}
          navigationTarget={navItem.navigationTarget}
          isActive={routeIsActive(isActiveTargets)}
          isMinimized={isMinimized}
          requiredPermissions={navItem.requiredPermissions}
          requiredProducts={navItem.requiredProducts}
          hideIfUnauthorized={navItem.hideIfUnauthorized}
          dataTestId={navItem.dataTestId}
          navigationItems={navItem.navigationItems}
          searchCallback={navItem.searchCallback}
          permissionCheckOperator={navItem.permissionCheckOperator}
          productCheckOperator={navItem.productCheckOperator}
          userPermissions={userPermissions}
          userProducts={userProducts}
          navigate={handleNavigate}
          activeParams={navItem.activeParams}
          onClick={navItem.onClick}
          authorizedIfAnyChild={navItem.authorizedIfAnyChild}
          displayIfInactiveAccount={navItem.displayIfInactiveAccount}
        />
      })}
    </div>
    <div className="border-t border-slate-700 mt-auto flex-[0_0_auto] h-40">

      {secondaryNavigationItems && secondaryNavigationItems.length && secondaryNavigationItems.map((navItem, index) => {
        const isActiveTargets: string[] = generateActiveTargetsList(navItem.activeTargets, navItem.navigationTarget);
        return <NavigationItem
          displayText={navItem.displayText}
          itemKey={index.toString()}
          key={index.toString()}
          icon={navItem.icon}
          navigationTarget={navItem.navigationTarget}
          isActive={routeIsActive(isActiveTargets)}
          isMinimized={isMinimized}
          requiredPermissions={navItem.requiredPermissions}
          requiredProducts={navItem.requiredProducts}
          hideIfUnauthorized={navItem.hideIfUnauthorized}
          dataTestId={navItem.dataTestId}
          navigationItems={navItem.navigationItems}
          searchCallback={navItem.searchCallback}
          permissionCheckOperator={navItem.permissionCheckOperator}
          productCheckOperator={navItem.productCheckOperator}
          userPermissions={userPermissions}
          userProducts={userProducts}
          navigate={handleNavigate}
          activeParams={navItem.activeParams}
          onClick={navItem.onClick}
          authorizedIfAnyChild={navItem.authorizedIfAnyChild}
          displayIfInactiveAccount={navItem.displayIfInactiveAccount}
        />
      })}
    </div>
    <div className={`transition-all absolute top-[92vh] ${isMinimized? 'left-[73px]': 'left-[166px]'} flex justify-end  z-10`}>
          <button onClick={
            () => {
              setZendeskPosition(isMinimized)
              toggleMenu()
            }}
            data-testid={"expand-collapse-button"}
          >
            {isMinimized
              ? <img src={roundButtonOpen} alt="roundButtonOpen" className="mr-2" />
              : <img src={roundButtonClose} alt="roundButtonClose" className="mr-2" />
            }
          </button>
        </div>
  </div>
}

interface GenerateActiveTargetsListProps {
  (
    activeTargets?: string[],
    navigationTarget?: string
  ): string[]
}

const generateActiveTargetsList: GenerateActiveTargetsListProps = (activeTargets = [], navigationTarget = '') => {
  const isActiveTargets: string[] = activeTargets && activeTargets.slice() || [];
  navigationTarget && isActiveTargets.push(navigationTarget);
  return isActiveTargets;
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
