import React from 'react'
import { useIsMutating, useQuery, useQueryClient } from '@tanstack/react-query'
import { competitorsObjValidation } from '../../../../../schemas/brandProfiles'
import Table from 'rsuite/lib/Table'
import SightlyButton from '../../../../../components/Sightly/SightlyButton'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import { accentColor } from '../../../../../assets/jss/colorConstants'
import { CustomWhisper } from '../../../../../components/CustomWhisper'
import Dropdown from 'rsuite/lib/Dropdown'
import toast from 'react-hot-toast'
import Panel from 'rsuite/lib/Panel'
import EventModal from './EventModal'
import { userAccountAxios } from '../../../../../axiosInstances'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils'
import useUser from '../../../../../hooks/useUser'
import { TableColumnJs } from '../../../../../utils'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import { products, userActions } from '@/staticData/products'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'
import config from '@/config'
import AccordionSection from './components/AccordionSection'
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic'
import { getRelationships } from './queries-api'
import { BRAND_PROFILE_RELATIONSHIP_LOOKUP, EVENT_SECTION } from './constants'
import EventAIGenerationForm, { EventAIGenerationFormType, EventAIGenerationType } from './EventAIGenerationForm'
import { AIGeneratedCell, tableHeaderStyle } from './Brand/BrandSection'
import useEvents from './useEvents'

export type Event = {
  id: number
  name: string
  twitterProfileUrl: string
  websiteUrl?: string
  wikiUrl: string
  relationshipId: number
  relationshipName?: string
  isAIGenerated?: boolean
  date?: Date | null
}

const getItems = async (brandProfileId: number) => {
  let url = `/brand-profile/${brandProfileId}/events`

  const result = await userAccountAxios.get<Event[]>(url)

  if (result.status === 200) {
    if (result.data.length > 0) {
      competitorsObjValidation.validate(result.data).catch(function (err) {
        console.error(err)
      })
    }
  }

  let _res = result.data

  _res = result.data.map((i) => {
    const _i: any = i
    if (!_i.day || !_i.month || !_i.year) {
      return i
    }
    const d = new Date(_i.year, _i.month - 1, _i.day, 0, 0, 0, 0)

    const newItem: any = {
      ...i,
      date: d
    }

    delete newItem.day
    delete newItem.month
    delete newItem.year
    return newItem
  })

  return _res
}

const EventSection = (props: { isActive?: boolean; handleAccordionClick?: () => void }) => {
  const {
    params: { brandProfileId }
  } = useMatch<MyLocationGenerics>()
  const { accounts, user, currentAccount, perms, userCanService } = useUser()

  const queryClient = useQueryClient()

  const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
  const viewOnly = viewOnlyParam || false

  const {
    data: items,
    isLoading,
    isFetched
  } = useQuery(mainRqKeys.brandProfileEvents(Number(brandProfileId)), () => getItems(Number(brandProfileId)), {
    enabled: !!brandProfileId,
    refetchOnWindowFocus: false
  })

  const [editing, setEditing] = React.useState(false)
  const [editingId, setEditingId] = React.useState<number | null>(null)

  const [adding, setAdding] = React.useState(false)
  const [isAIGenerate, setIsAIGenerate] = React.useState(false)
  const { queryResult: BrandProfileInfo } = useBrandProfileBasic(Number(brandProfileId))
  const { isMutatingBrandQuery, brandQueryMutation, handleError } = useEvents(
    Number(brandProfileId)
  )

  const { data: relationships } = useQuery(['brandProfileRelationshipsLookUp'], () => getRelationships(), {
    refetchOnWindowFocus: false
  })

  const editingEvent = React.useMemo(() => {
    if (!editingId || !isFetched || !items || !editing) return null

    const item = items.filter((c: Event) => c.id === editingId)[0]

    if (!item) return null
    return item
  }, [editingId, editing, items])

  const existingEvents = React.useMemo(() => {
    return (items && items.map((item: any) => item.name)) || []
  }, [items])

  const getRelationshipId = (relationshipName: string) => {
    if (!relationships) return null
    const selectedRelationship = relationships.find(
      (relationship: { id: number; relationship: string }) => relationship.relationship === relationshipName
    )
    return selectedRelationship.id || null
  }

  const handleAIGenerationFormSubmit = async (data: EventAIGenerationFormType) => {
    try {
    const brandProfile = BrandProfileInfo.data
    if (!brandProfile) return
    let doNotSuggest = existingEvents
    const payload: EventAIGenerationType = {
      brandName: brandProfile.brandProfileName,
      suggestionCount: 0,
      companySummary: brandProfile.companySummary,
      companyMentality: brandProfile.companyMentality,
      existingQueryTypes: [],
      relationshipId: 0
    }
    setIsAIGenerate(false)
    if (data.monitorSuggestionCount) {
      payload.suggestionCount = data.monitorSuggestionCount
      payload.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.MONITOR)
      payload.existingQueryTypes = doNotSuggest
      const resp = await brandQueryMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload })
      doNotSuggest = resp.data.doNotSuggest
    }
    if (data.targetSuggestionCount) {
      payload.suggestionCount = data.targetSuggestionCount
      payload.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.TARGET)
      payload.existingQueryTypes = doNotSuggest
      const resp = await brandQueryMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload })
      doNotSuggest = resp.data.doNotSuggest
    }
    } catch (error) {
      handleError(error)
    }
  }

  const handleDelete = async (id: number) => {
    const url = `/brand-profile/${brandProfileId}/events/${id}`
    try {
      await userAccountAxios.delete(url)
      // to fetch the latest product quota
      await queryClient.fetchQuery(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.QUERIES))
      queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileBasic(Number(brandProfileId)) })
      toast.success('Deleted')
    } catch (err) {
      toast.error('Error')
    }
    queryClient.invalidateQueries(mainRqKeys.brandProfileEvents(Number(brandProfileId)))
  }

  return (
    <>
      {config.featureFlag.aiProfiles ? (
        <div data-testid="accordion-event-section-panel">
          <AccordionSection
            sectionCaption={EVENT_SECTION.headerText}
            isActive={props.isActive ?? false}
            description={EVENT_SECTION.descriptionText}
            canCreate={!viewOnly}
            handleAddClick={() => setAdding(true)}
            handleAIGenerate={() => {
              setIsAIGenerate(true)
            }}
            handleAccordionClick={props.handleAccordionClick}
            isAIGenerating={!!isMutatingBrandQuery}
          >
            <EventAIGenerationForm
              isAIGenerate={isAIGenerate}
              setIsAIGenerate={setIsAIGenerate}
              handleSubmitForm={handleAIGenerationFormSubmit}
            />
            {!!editingEvent && (
              <EventModal
                key="edit"
                type="edit"
                open={editing}
                handleClose={() => {
                  setEditing(false)
                  setEditingId(null)
                }}
                item={editingEvent}
              />
            )}

            <EventModal
              key="add"
              type="add"
              open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
              handleClose={() => setAdding(false)}
              item={{
                id: 456456546456456564,
                name: '',
                wikiUrl: '',
                twitterProfileUrl: '',
                websiteUrl: '',
                relationshipId: 2,
                date: null
              }}
            />

            {isFetched && items && items.length > 0 && (
              <Table
                loading={isLoading}
                autoHeight
                data={items}
                bordered
                style={{
                  borderRadius: '10px',
                  boxShadow: '0px 1px 2px 0px #0000000F'
                }}
              >
                <TableColumnJs width={30}>
                  <Table.HeaderCell style={tableHeaderStyle} />
                  <AIGeneratedCell dataKey="isAIGenerated" />
                </TableColumnJs>
                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>TYPE</Table.HeaderCell>
                  <Table.Cell
                    style={{ display: 'flex', alignItems: 'center' }}
                    dataKey="relationshipName"
                  />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>NAME</Table.HeaderCell>
                  <Table.Cell>
                    {(item: Event) => {
                      return <>{item.name}</>
                    }}
                  </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>TWITTER</Table.HeaderCell>
                  <Table.Cell dataKey="twitterProfileUrl" />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>WEBSITE</Table.HeaderCell>
                  <Table.Cell dataKey="websiteUrl" />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>WIKI</Table.HeaderCell>
                  <Table.Cell dataKey="wikiUrl" />
                </TableColumnJs>

                {!viewOnly && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell style={tableHeaderStyle}></Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                      {(item: Event) => {
                        return (
                          <CustomWhisper
                            dropdownItems={[
                              <Dropdown.Item
                                key={1}
                                onClick={() => {
                                  setEditingId(item.id)
                                  setEditing(true)
                                }}
                              >
                                Edit
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key={2}
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            ]}
                          >
                            <IconButton
                              size="lg"
                              appearance="subtle"
                              icon={
                                <Icon
                                  icon="more"
                                  color={accentColor}
                                />
                              }
                            />
                          </CustomWhisper>
                        )
                      }}
                    </Table.Cell>
                  </TableColumnJs>
                )}
              </Table>
            )}
          </AccordionSection>
        </div>
      ) : (
        <Panel header={<div className="sightlyPanelHeader">Events</div>}>
          {!!editingEvent && (
            <EventModal
              key="edit"
              type="edit"
              open={editing}
              handleClose={() => {
                setEditing(false)
                setEditingId(null)
              }}
              item={editingEvent}
            />
          )}

          <EventModal
            key="add"
            type="add"
            open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
            handleClose={() => setAdding(false)}
            item={{
              id: 456456546456456564,
              name: '',
              wikiUrl: '',
              twitterProfileUrl: '',
              websiteUrl: '',
              relationshipId: 2,
              date: null
            }}
          />

          <div className="descriptionText">{`Please enter any events which are relevant to your brand. Ex: NBA
			All-star Game, New York Fashion Week, etc.`}</div>
          {!viewOnly && items && items.length > 0 && (
            <Whisper
              delayShow={300}
              enterable={true}
              placement="auto"
              trigger="hover"
              speaker={
                !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
                  <Tooltip>
                    <p>
                      This account has reached it’s limit.{' '}
                      <a
                        className="font-semibold"
                        target="_blank"
                        rel="noopener"
                        href="https://www.sightly.com/contact-us/"
                      >
                        Upgrade for more queries
                      </a>
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip>Adds a new Query</Tooltip>
                )
              }
            >
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <SightlyButton
                  disabled={!userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
                  handleClick={() => setAdding(true)}
                  id="addMorebutton"
                  datatestid="queriesCreateNewbutton"
                  text="Create New"
                />
              </div>
            </Whisper>
          )}

          {isFetched && items && items.length < 1 ? (
            <Whisper
              delayShow={300}
              enterable={true}
              placement="autoHorizontalStart"
              trigger="hover"
              speaker={
                !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
                  <Tooltip>
                    <p>
                      This account has reached it’s limit.{' '}
                      <a
                        className="font-semibold"
                        target="_blank"
                        rel="noopener"
                        href="https://www.sightly.com/contact-us/"
                      >
                        Upgrade for more queries
                      </a>
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip>Adds a new Query</Tooltip>
                )
              }
            >
              <div>
                <SightlyButton
                  disabled={
                    !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) || viewOnly
                  }
                  handleClick={() => setAdding(true)}
                  id="addMorebutton"
                  datatestid="queriesAddMorebutton"
                  type="secondary"
                  text="Add +"
                />
              </div>
            </Whisper>
          ) : (
            isFetched && (
              <Table
                loading={isLoading}
                autoHeight
                data={items}
              >
                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.Cell
                    style={{ display: 'flex', alignItems: 'center' }}
                    dataKey="relationshipName"
                  />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.Cell>
                    {(item: Event) => {
                      return <>{item.name}</>
                    }}
                  </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Twitter</Table.HeaderCell>
                  <Table.Cell dataKey="twitterProfileUrl" />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Website</Table.HeaderCell>
                  <Table.Cell dataKey="websiteUrl" />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Wiki</Table.HeaderCell>
                  <Table.Cell dataKey="wikiUrl" />
                </TableColumnJs>

                {!viewOnly && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                      {(item: Event) => {
                        return (
                          <CustomWhisper
                            dropdownItems={[
                              <Dropdown.Item
                                key={1}
                                onClick={() => {
                                  setEditingId(item.id)
                                  setEditing(true)
                                }}
                              >
                                Edit
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key={2}
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            ]}
                          >
                            <IconButton
                              size="lg"
                              appearance="subtle"
                              icon={
                                <Icon
                                  icon="more"
                                  color={accentColor}
                                />
                              }
                            />
                          </CustomWhisper>
                        )
                      }}
                    </Table.Cell>
                  </TableColumnJs>
                )}
              </Table>
            )
          )}
          <style>{`
				.descriptionText {
					color: #a3a3a3;
					font-size: 14px;
					margin-top: 24px;
					margin-bottom: 24px;
					font-weight: 500;									
				}		
			`}</style>
        </Panel>
      )}
    </>
  )
}

export default EventSection
