import React from 'react'
import DateRangePicker from 'rsuite/lib/DateRangePicker'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'
import { iabCategoriesFilter } from '../../../../../staticData/iabCategories'
import TagPicker from 'rsuite/lib/TagPicker'
import { listActions } from '../../constants'
import './listbuilder.css'
import debounce from 'just-debounce-it'
import { youtubeCategories, countriesOptions, lidLanguagesOptions } from '../../../../../staticData/data'
import { fetchVersionService } from '../../../../../services/lists'
import { ReactComponent as DevelopedWithYoutube } from '../../../../../assets/img/developedWithYoutube.svg'
import { ReactComponent as DropdownArrowRight } from '../../../../../assets/img/dropdownRight.svg'
import { ReactComponent as DropdownArrowDown } from '../../../../../assets/img/dropdownDown.svg'
import { ReactComponent as Info } from '../../../../../assets/img/info_12x12.svg'
import SightlyCheckbox from '../../../../../components/Sightly/SightlyFormElements/SightlyCheckbox'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'
import Input from 'rsuite/lib/Input'
import SightlyButton from '../../../../../components/Sightly/SightlyButton'
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

var dayjs = require('dayjs')

const FilterDropdown = ({ label, content }) => {
    const [open, setOpen] = React.useState(true)
    return (
        <div>
            <div
                onClick={() => setOpen((prev) => !prev)}
                style={{
                    cursor: 'pointer',
                    height: 54,
                    lineHeight: '22px',
                    fontWeight: 600,
                    fontSize: 14,
                    color: '#333D47',
                    paddingTop: 16,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div className="unselectable flex items-center">
                        {label}
                        {(label === 'Categories' || label === 'Block Sensitivity') && (
                            <Whisper
                                delayHide={0}
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        {label === 'Categories'
                                            ? `These categories are applied and defined by Sightly.
										Selecting categories will return videos that are tagged in
										the platform.`
                                            : `Block sensitivity filter allows you to control the level of impact a blocked content category has on the recommended action provided by the platform.`}
                                    </Tooltip>
                                }
                            >
                                <Info style={{ marginLeft: 12 }} />
                            </Whisper>
                        )}
                    </div>
                </div>
                <div>{open ? <DropdownArrowDown /> : <DropdownArrowRight />}</div>
            </div>
            <div
                style={{
                    display: open ? 'block' : 'none',
                    paddingLeft: 16,
                    paddingRight: 15
                }}
            >
                {content}
            </div>
        </div>
    )
}

const filters = {
    enrichedKids: 'enrichedKids',
    youtubeKids: 'youtubeKids',
    categories: 'categories',
    iabCategories: 'iabCategories',
    languages: 'languages',
    smartListLanguages: 'smartListLanguages',
    countries: 'countries',
    actionIds: {
        target: 'actionIdsTarget',
        monitor: 'actionIdsMonitor',
        block: 'actionIdsBlock'
    },
    views: {
        max: 'viewsMax',
        min: 'viewsMin'
    },
    videoDurationSeconds: {
        max: 'videoDurationSecondsMax',
        min: 'videoDurationSecondsMin'
    },
    uploadDate: 'uploadDate'
}

const FiltersSideBar = (props) => {
    const { perms, userPermissions } = useUser()
    const [compFilterState, setCompFilterState] = React.useState({
        enrichedKids: false,
        youtubeKids: false,
        iabCategories: [],
        countries: [],
        actionIds: [1, 2, 3],
        uploadDate: null,
        categories: [],
        languages: [],
        smartListLanguages: [],
        views: {
            min: 1000,
            max: null
        },
        videoDurationSeconds: {
            min: null,
            max: null
        }
    })

    const { version, isVideo } = props

    const handleApplyFiltersClick = () => {
        const args = {
            filters: compFilterState,
            versionId: version.versionId
        }

        props.handleFiltersSaved(args)
    }

    React.useEffect(() => {
        ;(async () => {
            let v = await fetchVersionService(version.versionId)
            v.filter.updated = false
            setCompFilterState(v.filter)
        })()
    }, [])

    const handleFilterChange = debounce((filter, value) => {
        executeFilterChange(filter, value)
    }, 500)

    const handleRecommendedActionChange = (filter, value) => {
        if (!value && compFilterState.actionIds.length === 1) {
            return
        }

        if (['actionIdsTarget', 'actionIdsBlock', 'actionIdsMonitor'].indexOf(filter) === -1) {
            console.error('rec action must be one of actionIdsTarget, actionIdsBlock, actionIdsMonitor')
            return
        }
        if (value === undefined) {
            console.error('rec action value must be defined')
        }

        executeFilterChange(filter, value)
    }

    const executeFilterChange = (filter, value) => {
        switch (filter) {
            case filters.youtubeKids:
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        youtubeKids: !value,
                        updated: true
                    }
                    return newState
                })

                break

            case filters.enrichedKids:
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        enrichedKids: !value,
                        updated: true
                    }

                    return newState
                })

                break
            case filters.countries:
                let countries = []
                if (!value) {
                    value = []
                }
                for (const country of value) {
                    countries.push({ countryCode: country })
                }
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        countries,
                        updated: true
                    }
                    return newState
                })
                break
            case filters.languages:
                let languages = []
                if (!value) {
                    value = []
                }
                for (const language of value) {
                    languages.push({ languageCode: language })
                }
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        languages,
                        updated: true
                    }
                    return newState
                })
                break

            case filters.smartListLanguages:
                let smartListLanguages = []
                if (!value) {
                    value = []
                }
                for (const language of value) {
                    smartListLanguages.push({ languageCode: language })
                }
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        smartListLanguages,
                        updated: true
                    }
                    return newState
                })
                break

            case filters.categories:
                let categories = []
                if (!value) {
                    value = []
                }
                for (const category of value) {
                    categories.push({ categoryId: Number(category) })
                }
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        categories,
                        updated: true
                    }
                    return newState
                })
                break

            case filters.iabCategories:
                let iabCategories = []
                if (!value) {
                    value = []
                }
                for (const iabCategory of value) {
                    iabCategories.push(iabCategory)
                }

                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        iabCategories: iabCategories,
                        updated: true
                    }

                    return newState
                })
                break

            case filters.actionIds.target:
                let ids = JSON.parse(JSON.stringify(compFilterState.actionIds))
                if (!value) {
                    ids = ids.filter((el) => el != listActions.target.actionId)
                } else {
                    ids.push(listActions.target.actionId)
                }

                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        actionIds: ids,
                        updated: true
                    }
                    return newState
                })
                break

            case filters.actionIds.block:
                let idsBlock = JSON.parse(JSON.stringify(compFilterState.actionIds))
                if (!value) {
                    idsBlock = idsBlock.filter((el) => el != listActions.block.actionId)
                } else {
                    idsBlock.push(listActions.block.actionId)
                }

                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        actionIds: idsBlock,
                        updated: true
                    }
                    return newState
                })
                break

            case filters.actionIds.monitor:
                let idsMonitor = JSON.parse(JSON.stringify(compFilterState.actionIds))
                if (!value) {
                    idsMonitor = idsMonitor.filter((el) => el != listActions.monitor.actionId)
                } else {
                    idsMonitor.push(listActions.monitor.actionId)
                }

                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        actionIds: idsMonitor,
                        updated: true
                    }
                    return newState
                })
                break

            case filters.views.max:
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState
                    }
                    newState.updated = true
                    newState.views.max = value
                    return newState
                })
                break

            case filters.views.min:
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState
                    }
                    newState.updated = true
                    newState.views.min = value
                    return newState
                })
                break

            case filters.videoDurationSeconds.max:
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState
                    }
                    newState.updated = true
                    newState.videoDurationSeconds.max = value
                    return newState
                })
                break

            case filters.videoDurationSeconds.min:
                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState
                    }
                    newState.updated = true
                    newState.videoDurationSeconds.min = value
                    return newState
                })
                break

            case filters.uploadDate:
                if (!value) {
                    value = {}
                }

                setCompFilterState((prevState) => {
                    let newState = {
                        ...prevState,
                        uploadDate: value,
                        updated: true
                    }
                    return newState
                })

                break

            default:
                break
        }
    }

    const uploaded = version?.uploaded
    const _filters = version?.filter

    const canSmartlistEdit = userCanPermissionProductQuota({
        requiredPermissionValue: perms.SMARTLIST_EDIT,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    });

    return (
        <div
            style={{
                width: 288,
                backgroundColor: '#F5F5F5'
            }}
        >
            <div
                style={{
                    height: 24 + 24 + 45,
                    width: '100%'
                }}
            >
                <div
                    style={{
                        lineHeight: '24px',
                        fontWeight: 600,
                        fontSize: 24,
                        color: '#333D47',
                        paddingTop: 24,
                        marginLeft: 24
                    }}
                >
                    SmartList Filters
                </div>
                <DevelopedWithYoutube style={{ marginLeft: 89 + 24 }} />
            </div>
            <div>
                <div style={{ width: '100%', height: 1, backgroundColor: '#CCCCCC' }} />

                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <FilterDropdown
                        label="Recommended Action"
                        content={
                            <div style={{ marginLeft: 8 }}>
                                <div style={{ display: 'flex', marginBottom: 10 }}>
                                    <div style={{ marginTop: 3 }}>
                                        <SightlyCheckbox
                                            id="target"
                                            disabled={!canSmartlistEdit || props.isFromViewClick}
                                            checked={compFilterState?.actionIds?.includes(listActions.target.actionId)}
                                            handleChange={(checked) => {
                                                handleRecommendedActionChange(filters.actionIds.target, checked)
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 8,
                                            fontWeight: 600,
                                            fontSize: 14,
                                            lineHeight: '22px',
                                            color: '#333D47'
                                        }}
                                    >
                                        Target
                                    </div>
                                </div>

                                <div style={{ display: 'flex', marginBottom: 10 }}>
                                    <div style={{ marginTop: 3 }}>
                                        <SightlyCheckbox
                                            id="block"
                                            disabled={!canSmartlistEdit || props.isFromViewClick}
                                            checked={compFilterState?.actionIds?.includes(listActions.block.actionId)}
                                            handleChange={(checked) => {
                                                handleRecommendedActionChange(filters.actionIds.block, checked)
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 8,
                                            fontWeight: 600,
                                            fontSize: 14,
                                            lineHeight: '22px',
                                            color: '#333D47'
                                        }}
                                    >
                                        Block
                                    </div>
                                </div>

                                <div style={{ display: 'flex', marginBottom: 16 }}>
                                    <div style={{ marginTop: 3 }}>
                                        <SightlyCheckbox
                                            id="monitor"
                                            disabled={!canSmartlistEdit || props.isFromViewClick}
                                            checked={compFilterState?.actionIds?.includes(listActions.monitor.actionId)}
                                            handleChange={(checked) => {
                                                handleRecommendedActionChange(filters.actionIds.monitor, checked)
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 8,
                                            fontWeight: 600,
                                            fontSize: 14,
                                            lineHeight: '22px',
                                            color: '#333D47'
                                        }}
                                    >
                                        Monitor
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#CCCCCC'
                    }}
                />

                {isVideo && (
                    <>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            <FilterDropdown
                                label="Video Filters"
                                content={
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <div className="input-label">Views</div>
                                                <Input
                                                    id="minViewInput"
                                                    type="number"
                                                    defaultValue={_filters?.views.min}
                                                    disabled={!canSmartlistEdit || props.isFromViewClick}
                                                    onChange={(nextValue) => {
                                                        if (!nextValue) {
                                                            nextValue = 1000
                                                        }
                                                        handleFilterChange(filters.views.min, nextValue)
                                                    }}
                                                    style={{ marginBottom: 8, width: 94 }}
                                                    placeholder="Min"
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    paddingTop: 33,
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                    lineHeight: '22px',
                                                    color: '#000',
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}
                                            >
                                                to
                                            </div>
                                            <div style={{ marginRight: 6, marginTop: 24 }}>
                                                <Input
                                                    id="maxViewInput"
                                                    type="number"
                                                    disabled={!canSmartlistEdit || props.isFromViewClick}
                                                    defaultValue={_filters?.views.max}
                                                    onChange={(nextValue) => {
                                                        if (!nextValue) {
                                                            nextValue = null
                                                        }
                                                        handleFilterChange(filters.views.max, nextValue)
                                                    }}
                                                    style={{ width: 94 }}
                                                    placeholder="Max"
                                                />
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', marginTop: 8 }}>
                                            <div>
                                                <div className="input-label">Duration (Mins)</div>
                                                <Input
                                                    id="durationMinInput"
                                                    disabled={!canSmartlistEdit || props.isFromViewClick}
                                                    type="number"
                                                    defaultValue={_filters?.videoDurationSeconds.min}
                                                    onChange={(nextValue) => {
                                                        if (!nextValue) {
                                                            nextValue = null
                                                        }
                                                        handleFilterChange(
                                                            filters.videoDurationSeconds.min,
                                                            Number(nextValue)
                                                        )
                                                    }}
                                                    style={{ width: 94 }}
                                                    placeholder="Min"
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    paddingTop: 33,
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                    lineHeight: '22px',
                                                    color: '#000',
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}
                                            >
                                                to
                                            </div>
                                            <div style={{ marginRight: 6, marginTop: 24 }}>
                                                <Input
                                                    id="durationMaxInput"
                                                    type="number"
                                                    disabled={!canSmartlistEdit || props.isFromViewClick}
                                                    defaultValue={_filters?.videoDurationSeconds.max}
                                                    onChange={(nextValue) => {
                                                        if (!nextValue) {
                                                            nextValue = null
                                                        }
                                                        handleFilterChange(filters.videoDurationSeconds.max, nextValue)
                                                    }}
                                                    style={{ width: 94 }}
                                                    placeholder="Max"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                className="unselectable input-label"
                                                style={{
                                                    marginTop: 8
                                                }}
                                            >
                                                Upload Date
                                            </div>
                                            <DateRangePicker
                                                style={{ borderRadius: 3, marginBottom: 22 }}
                                                disabled={!canSmartlistEdit || props.isFromViewClick}
                                                block
                                                placeholder="Select Date(s)"
                                                defaultValue={
                                                    _filters?.uploadDate?.max && _filters?.uploadDate?.min
                                                        ? [
                                                              dayjs(_filters?.uploadDate?.min).toDate(),
                                                              dayjs(_filters?.uploadDate?.max).toDate()
                                                          ]
                                                        : []
                                                }
                                                showOneCalendar
                                                placement="topStart"
                                                onChange={(val) => {
                                                    let value = {}
                                                    if (val.length > 0) {
                                                        value = {
                                                            min: dayjs(val[0]).format('YYYY-MM-DD'),
                                                            max: dayjs(val[1]).format('YYYY-MM-DD')
                                                        }
                                                    }
                                                    handleFilterChange(filters.uploadDate, value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>

                        <div
                            style={{
                                width: '100%',
                                height: 1,
                                backgroundColor: '#CCCCCC'
                            }}
                        />
                    </>
                )}

                {!uploaded && (
                    <>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            <FilterDropdown
                                label="Categories"
                                content={
                                    <div>
                                        <CheckTreePicker
                                            id="categories"
                                            virtualized
                                            style={{ marginBottom: 16 }}
                                            placeholder="Any"
                                            preventOverflow
                                            defaultExpandAll={false}
                                            defaultValue={_filters?.iabCategories}
                                            data={iabCategoriesFilter}
                                            labelKey={'name'}
                                            valueKey={'id'}
                                            onChange={(val) => {
                                                handleFilterChange(filters.iabCategories, val)
                                            }}
                                            cascade={true}
                                            block
                                            disabled={!canSmartlistEdit || props.isFromViewClick}
                                        />
                                    </div>
                                }
                            />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                height: 1,
                                backgroundColor: '#CCCCCC'
                            }}
                        />
                    </>
                )}

                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <FilterDropdown
                        label="Language"
                        content={
                            <TagPicker
                                id="tagPicker"
                                style={{ marginBottom: 16 }}
                                preventOverflow
                                block
                                disabled={!canSmartlistEdit || props.isFromViewClick}
                                data={lidLanguagesOptions}
                                labelKey={'languageName'}
                                valueKey={'languageCode'}
                                defaultValue={_filters?.smartListLanguages?.map((lang) => lang.languageCode)}
                                virtualized={true}
                                placeholder="Languages"
                                onChange={(val) => {
                                    handleFilterChange(filters.smartListLanguages, val)
                                }}
                            />
                        }
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#CCCCCC'
                    }}
                />

                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <FilterDropdown
                        label="Kids Content"
                        content={
                            <div style={{ display: 'flex', marginBottom: 33 }}>
                                <div style={{ marginTop: 3 }}>
                                    <SightlyCheckbox
                                        id="kidsContent"
                                        disabled={!canSmartlistEdit || props.isFromViewClick}
                                        defaultChecked={!_filters?.enrichedKids}
                                        handleChange={(checked) => {
                                            handleFilterChange(filters.enrichedKids, checked)
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: 8,
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '22px',
                                        color: '#333D47'
                                    }}
                                >
                                    Exclude Kids Content
                                </div>
                            </div>
                        }
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#CCCCCC'
                    }}
                />

                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <FilterDropdown
                        label="Youtube Filters"
                        content={
                            <>
                                <div className="unselectable input-label flex">
                                    Categories{' '}
                                    <Whisper
                                        delayHide={0}
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                These categories are applied and defined by YouTube, not Sightly.
                                                Selecting YouTube categories will return videos that were defined in
                                                YouTube and tagged by the creator who uploaded the video.
                                            </Tooltip>
                                        }
                                    >
                                        <Info style={{ marginLeft: 10 }} />
                                    </Whisper>
                                </div>
                                <TagPicker
                                    id="ytCategories"
                                    preventOverflow
                                    block
                                    disabled={!canSmartlistEdit || props.isFromViewClick}
                                    data={youtubeCategories}
                                    defaultValue={_filters?.categories?.map((c) => String(c.categoryId))}
                                    labelKey={'categoryName'}
                                    valueKey={'categoryId'}
                                    virtualized={true}
                                    placeholder="Youtube Categories"
                                    onChange={(val) => {
                                        handleFilterChange(filters.categories, val)
                                    }}
                                />

                                <div
                                    className="unselectable input-label"
                                    style={{
                                        marginTop: 8
                                    }}
                                >
                                    Country
                                </div>
                                <TagPicker
                                    id="countries"
                                    style={{ marginBottom: 16 }}
                                    block
                                    preventOverflow
                                    disabled={!canSmartlistEdit || props.isFromViewClick}
                                    data={countriesOptions}
                                    labelKey={'countryName'}
                                    valueKey={'countryCode'}
                                    defaultValue={_filters?.countries?.map((c) => c.countryCode)}
                                    placeholder="Countries"
                                    onChange={(val) => {
                                        handleFilterChange(filters.countries, val)
                                    }}
                                />
                            </>
                        }
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#CCCCCC'
                    }}
                />

                <div
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        width: 288,
                        padding: 12,
                        backgroundColor: '#F5F5F5',
                        zIndex: 6,
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 8
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <SightlyButton
                            isRectangle
                            block
                            id="applyFilter"
                            text="Apply"
                            handleClick={handleApplyFiltersClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiltersSideBar
