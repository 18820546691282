import React, { useState } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { useQuery } from "@tanstack/react-query";

import { MyLocationGenerics } from "@/classes/utils";
import { reportGeneratorApi } from './reportGenerator-api'
import { routes} from '../../routes'
import { ReactComponent as ReportingImage } from '../../assets/img/reporting-tool.svg'

import SightlyButton from '../../components/Sightly/SightlyButton'
import ReportTable from './components/ReportTable'
import DownloadingModal from './components/DownloadingModal'

function ReportGeneratorComponent() {
    const navigate = useNavigate()
    const [isDownloading, setIsDownloading] = useState(false)
    const { accountId } = useSearch<MyLocationGenerics>()

    const queryKey = ['getReportConfigurations', accountId]
    const {
        data: tableData
    } = useQuery(['getReportConfigurations', accountId], reportGeneratorApi.getReportConfigurations)

    const hasTableData = tableData?.length

    return (
      <>
          <div className="flex justify-between p-6 pb-0">

              <div className="sightlyPanelHeader">
                  <div className="flex flex-row pl-8">
                      Reports
                  </div>
              </div>

              { hasTableData ? (
                <div className="mr-10 pt-2">
                    <SightlyButton
                    datatestid={'button-report-generator-create-new-report'}
                    id="createReportButton"
                    text="Create New Report"
                    disabled={false}
                    handleClick={() => {
                        navigate({
                            to: routes.reporting.newReport.path,
                            search: (search) => ({ accountId: search?.accountId })
                        })
                    }}
                    />
                </div>
                ) : null}

          </div>

          { hasTableData
              ?
                 <ReportTable tableData={tableData} queryKey={queryKey} setIsDownloading={setIsDownloading}/>
              :
                 <div className="w-full flex items-center flex-col">
                    <div className="flex items-center flex-col pt-32">
                       <div className="p-4">
                           <ReportingImage />
                       </div>
                       <p>
                           <b>No reports</b>
                       </p>
                       <p>Get started by creating a new report</p>
                       <div className="p-4">
                           <SightlyButton
                               datatestid={'button-report-generator-new-report'}
                               id="createReportGenerator"
                               text="+ New Report"
                               disabled={false}
                               handleClick={() => {
                                   navigate({
                                       to: routes.reporting.newReport.path,
                                       search: (search) => ({ accountId: search?.accountId })
                                   })
                               }}
                           />
                       </div>
                    </div>
                 </div>

          }

          <DownloadingModal isDownloading={isDownloading} />
      </>
    )
}

export default ReportGeneratorComponent
