import { userAccountAxios } from '@/axiosInstances';
import { aylienIndustriesOptions } from '@/schemas/schemas';
import { logError } from '@/utils';

export const aylienIndustries = {
  get: async () => {
    const url = `/brand-profile/aylien-industries`
    const { data } = await userAccountAxios.get(url)
    aylienIndustriesOptions.validate(data.slice(0, 3)).catch(function (err) {
      logError(err)
      console.error(
        ' we received different data from the api than expected while fetching aylien Industries options, see console log for more details'
      )
    })
    return data
  }
};
